import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ModalImage from "react-modal-image";
import { BsChevronDown, BsDot } from 'react-icons/bs';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { deleteSuperContestChild, fetchSuperContestChilds, updateSuperContestChild } from '../../../services/api'
import { BsFillCameraVideoFill } from "react-icons/bs";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Modal, Switch } from "antd";
import DataTableBase from "../../../components/common/dataTable";
import {
	BsCheck, BsX
} from "react-icons/bs";


import Message from '../../../components/common/Message';
import DeleteModal from '../../../components/common/DeleteModal';

function SuperContestChild() {
	let { id } = useParams()
	const history = useHistory()
	const [superContests, setSuperContests] = useState([])
	const [visibleDelete, setVisibleDelete] = useState(false)
	const [deletedRecordId, setDeletedRecordId] = useState(null)
	const [videoURL, setVideoURL] = useState("");
	const [totalRows, setTotalRows] = useState(0);
	const [limit, setLimit] = useState(10);
	const [currPage, setCurrPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [isEnabled, setIsEnabled] = useState(false);


	useEffect(() => {
		const getSuperContestChilds = async () => {
			try {
				const { data: { data, status } } = await fetchSuperContestChilds(id)
				status && setSuperContests(data)
			} catch (error) {
				console.log(error)
			}
		}

		getSuperContestChilds()
	}, [visibleDelete, id])

	const confirmDelete = async () => {
		try {
			const { status } = await deleteSuperContestChild(deletedRecordId)
			status ? Message('Deleted successfully', true) : Message('Unable to delete', false)
			setVisibleDelete(false)
		} catch (error) {
			Message('Unable to delete', false)
			console.log(error)
		}
	}

	const handleEnable = async (id, deleted) => {
		try {
			const { status } = await updateSuperContestChild({ isEnabled: !deleted }, id);
			if (status) {
				setIsEnabled(!isEnabled);
				Message("Contest updated successfully", true)
			} else Message("Unable to update Contest", false);
		} catch (error) {
			Message("Unable to update Contest", false);
			console.log(error);
		}
	};

	const deleteRecord = (id) => {
		setDeletedRecordId(id)
		setVisibleDelete(true)
	}
	const get_url_extension = (url) => {
		if (url !== undefined) {
			return url.split(/[#?]/)[0].split(".").pop().trim();
		}
		return;
	};

	const normalContestsColumns = [
		{
			name: "Contest Name",
			selector: (row) => row.tagName,
			sortable: true,

			cell: (row) => (
				<a onClick={() => history.push("/user/" + row?._id)}>{row.tagName}</a>
			),
		},
		{
			name: "Created Date",
			selector: (row) => new Date(row.createdAt),
			sortable: true,
			cell: (row) => (
				new Date(row.createdAt).toDateString()
			)
		},
		{
			name: "Created Time",
			selector: (row) => new Date(row.createdAt).toLocaleTimeString(),
			sortable: true,
		},
		{
			name: "Order Number",
			selector: (row) => row.orderNumber,
			sortable: true,

		},
		{
			name: "Media",
			cell: (row) =>
				get_url_extension(row.image) === "mp4" ||
					get_url_extension(row.image) === "MP4" ||
					get_url_extension(row.image) === "mov" ||
					get_url_extension(row.image) === "MOV" ? (
					<div>
						<BsFillCameraVideoFill
							style={{ height: "25px", width: "25px", margin: "auto" }}
							onClick={() => {
								setVideoURL(row.image);
							}}
						/>
					</div>
				) : (
					<ModalImage
						small={row.image}
						large={row.image}
						className="w-8 h-8 p-1 rounded-md bg-white/10"
						alt=""
					/>
				),
		},
		{
			name: "Active",
			selector: (row) => row.isEnabled,
			cell: (row) => (
				<Switch
					style={{ border: "1px solid gray" }} // tem
					onChange={() => handleEnable(row?._id, row?.isEnabled)}
					defaultChecked={row?.isEnabled}
					checkedChildren={<BsCheck className="-ml-1 text-xl" />}
					unCheckedChildren={<BsX className="-mr-1 text-xl" />}
				/>
			),
			sortable: true,
			center: true,
		},
		{
			name: "Actions",
			center: true,
			cell: (row) => (
				<div className="flex ">
					<a className=" mx-1" onClick={() => history.push("/child/edit/" + row?._id)}>
						<div className="flex items-center underline">
							<BiEdit /> &nbsp;Edit
						</div>
					</a>
					<a className="mx-1" onClick={() => deleteRecord(row?._id)}>
						<div className="flex items-center underline">
							<BiTrash /> &nbsp;Delete
						</div>
					</a>
				</div>
			),
		},
	];

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			<DeleteModal
				title='SuperContestChild'
				setVisible={setVisibleDelete}
				confirmDelete={confirmDelete}
				visible={visibleDelete} />
			<div className="flex items-center justify-between pr-20">
				<div>
					<p className="pb-1 text-2xl text-white">Super Contest Childs</p>
					<p className="text-muted">{superContests?.length} Contests</p>
				</div>
				<div className='flex items-center space-x-12'>
					<button onClick={() => history.push('/child/new/' + id)} className="btn-border">Add child contest</button>
				</div>
			</div>
			<div className='flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted'>
				{
					<DataTableBase
						columns={normalContestsColumns}
						data={superContests}
						progressPending={loading}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={(newLimit, page) => {
							setLimit(newLimit);
							setCurrPage(page);
						}}
						onChangePage={(page) => setCurrPage(page)}
						paginationComponentOptions={{
							rowsPerPageText: "Items per Page : ",
							rangeSeparatorText: "-",
							selectAllRowsItem: true,
							selectAllRowsItemText: "All",
						}}
					/>
				}
			</div>
		</div>
	)
}

export default SuperContestChild
