import { Modal, Button } from 'antd'

function DeleteAlert({ title, visible, loading, setVisible, confirmDelete, image, data }) {

  return (
    <Modal
      title={`Delete ${title}`}
      centered
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={500}>
      <div>
        {
          image &&
          <div className='flex items-center justify-center w-full pb-6'>
            <img src={image}
              onError={e => { e.target.onerror = null; e.target.src = "/assets/sidebar/user.svg" }}
              className='p-1 rounded-md w-96 h-96 bg-white/10' alt="img" />
          </div>
        }
        {
          data &&
          <div className='w-full pb-6 space-y-2 text-lg'>
            <div className='flex space-x-2'>
              <p className='w-28'>Name :</p>
              <p className='capitalize '>{data.firstName}</p>
              <p className='capitalize '>{data.lastName}</p>
            </div>
            <div className='flex space-x-2'>
              <p className='w-28'>Email :</p>
              <p className=''>{data.email}</p>
            </div>
            <div className='flex space-x-2'>
              <p className='w-28'>Phone :</p>
              <p className=''>{data.phoneNumber}</p>
            </div>
            <div className='flex space-x-2'>
              <p className='w-28'>User Name :</p>
              <p className=''>{data.userName}</p>
            </div>
            <img src={data?.image}
              onError={e => { e.target.onerror = null; e.target.src = "/assets/sidebar/user.svg" }}
              className='p-1 rounded-md w-80 h-80 bg-white/10' alt="img" />
          </div>
        }
        <p className='mb-0 text-lg font-semibold'>
          Are you sure you want to delete?
        </p>
      </div>
      <div className='flex justify-between pt-8'>
        <Button
          type='dashed'
          onClick={() => setVisible(false)}
          style={{ borderColor: '#004EA9' }}
          className='w-2/5 text-gray-900 hover:bg-opacity-80 hover:text-muted'>Cancel</Button>
        <Button
          type='danger'
          loading={loading}
          className='w-2/5 text-gray-100 bg-danger bg-opacity-90 hover:text-gray-100 hover:bg-opacity-80'
          onClick={confirmDelete}>Delete</Button>
      </div>
    </Modal>
  )
}

export default DeleteAlert
