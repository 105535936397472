/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import ModalImage from "react-modal-image";
import { useHistory } from "react-router-dom";
import { RiSearch2Line } from "react-icons/ri";
import { BsCheck, BsX } from "react-icons/bs";
import DataTableBase from "../../../components/common/dataTable";
import { BsFillCameraVideoFill } from "react-icons/bs";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Modal, Switch } from "antd";

import {
	fetchContests,
	deleteContest,
	fetchSuperContests,
	fetchCategories,
	fetchContestByCategoryId,
	updateContest,
	fetchTalentCategories,
	getContestsByCategoryId,
} from "../../../services/api";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import Message from "../../../components/common/Message";
import DeleteModal from "../../../components/common/DeleteModal";

function Contest() {
	const history = useHistory();
	const [categories, setCategories] = useState([]);
	const [current, setCurrent] = useState([]);
	const [visibleDelete, setVisibleDelete] = useState(false);
	const [deletedRecordId, setDeletedRecordId] = useState(null);
	const [tabIndex, setTabIndex] = useState(0);
	const [loading, setLoading] = useState(false);
	const [isEnabled, setIsEnabled] = useState(false);
	const [videoURL, setVideoURL] = useState("");
	const [totalRowsCategory, setTotalRowsCategory] = useState(0);
	const [limitCategory, setLimitCategory] = useState(1000);
	const [currPageCategory, setCurrPageCategory] = useState(1);
	const [categoryId, setCategoryId] = useState("")

	const confirmDelete = async () => {
		try {
			const { status } = await deleteContest(deletedRecordId);
			status
				? Message("Deleted successfully", true)
				: Message("Unable to delete", false);
			setVisibleDelete(false);
		} catch (error) {
			Message("Unable to delete", false);
			console.log(error);
		}
	};

	const deleteRecord = (id) => {
		setDeletedRecordId(id);
		setVisibleDelete(true);
	};

	const get_url_extension = (url) => {
		if (url !== undefined) {
			return url.split(/[#?]/)[0].split(".").pop().trim();
		}
		return;
	};

	useEffect(() => {
		const getContests = async () => {
			try {
				setLoading(true);
				const { data: { data: categoryData, status: categoryStatus } } = await fetchTalentCategories();
				categoryStatus && setCategories(categoryData);
				const { data: { data, status } } = await getContestsByCategoryId(categoryData[0]._id);
				status && setCurrent(data)
				setTabIndex(parseInt(localStorage.getItem("indexForCategory")) || 0);
				setLoading(false);
			} catch (error) {
				console.log(error);
			}
		};
		getContests();
	}, [isEnabled, deletedRecordId]);

	useEffect(() => {
		const getContestCatId = async () => {
			try {
				let categoryUpdated =
					parseInt(localStorage.getItem("indexForCategory")) || 0;
				if (categories.length) {
					setLoading(true);
					const {
						data: { data, status },
					} = await fetchContestByCategoryId(categories[categoryUpdated]?._id, currPageCategory, limitCategory);
					status && setCurrent(data.data);
					setTotalRowsCategory(data.totalRecords)
					setLoading(false);
				}
			} catch (error) {
				console.log(error);
			}
		};
		getContestCatId();
	}, [tabIndex, isEnabled]);

	const handleEnable = async (id, deleted) => {
		try {
			const { status } = await updateContest({ isEnabled: !deleted }, id);
			if (status) {
				setIsEnabled(!isEnabled);
				Message("Contest updated successfully", true)
			} else Message("Unable to update Contest", false);
		} catch (error) {
			Message("Unable to update Contest", false);
			console.log(error);
		}
	};

	const categoryContestsColumns = [
		{
			name: "Contest Name",
			selector: (row) => row.tagName,
			sortable: true,

			cell: (row) => (
				<a onClick={() => history.push("/contest/" + row?._id)}>
					{row.tagName}
				</a>
			),
		},
		{
			name: "Created By",
			selector: (row) => `${row.user.firstName} ${row.user.lastName}`,
			sortable: true,

			cell: (row) => (
				<a onClick={() => history.push("/user/" + row.user?._id)}>
					{`${row.user.firstName} ${row.user.lastName}`}
				</a>
			),
		},
		{
			name: "Created Date",
			selector: (row) => new Date(row.createdAt),
			sortable: true,

			cell: (row) => (
				new Date(row.createdAt).toDateString()
			)
		},
		{
			name: "Created Time",
			selector: (row) => new Date(row.createdAt).toLocaleTimeString(),
			sortable: true,
		},
		{
			name: "Duration (Minutes)",
			selector: (row) => row.duration / 60,
			sortable: true,
		},
		{
			name: "Media",
			cell: (row) =>
				get_url_extension(row.image) == "mp4" ||
					get_url_extension(row.image) == "MP4" ||
					get_url_extension(row.image) == "mov" ||
					get_url_extension(row.image) == "MOV" ? (
					<div>
						<BsFillCameraVideoFill
							style={{ height: "25px", width: "25px", margin: "auto" }}
							onClick={() => {
								setVideoURL(row.image);
							}}
						/>
					</div>
				) : (
					<ModalImage
						small={row.image}
						large={row.image}
						className="w-8 h-8 p-1 rounded-md bg-white/10"
						alt=""
					/>
				),
		},
		{
			name: "Active",
			selector: (row) => row.isEnabled,
			cell: (row) => (
				<Switch
					style={{ border: "1px solid gray" }} // tem
					onChange={() => handleEnable(row?._id, row?.isEnabled)}
					defaultChecked={row?.isEnabled}
					checkedChildren={<BsCheck className="-ml-1 text-xl" />}
					unCheckedChildren={<BsX className="-mr-1 text-xl" />}
				/>
			),

		},
		{
			name: "Actions",
			center: true,
			selector: (row) => row._id,

			cell: (row) => (
				<div className="flex ">
					<a className="underline mx-1" onClick={() => history.push("/contest/edit/" + row?._id)}>
						<div className="flex items-center">
							<BiEdit /> &nbsp;Edit
						</div>
					</a>
					<a className="underline mx-1" onClick={() => deleteRecord(row?._id)}>
						<div className="flex items-center">
							<BiTrash /> &nbsp;Delete
						</div>
					</a>
				</div>
			),
		},
	];

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			<DeleteModal
				title="Contest"
				setVisible={setVisibleDelete}
				confirmDelete={confirmDelete}
				visible={visibleDelete}
			/>
			<div className="flex flex-col space-y-2">
				<div className="flex items-center justify-between pr-20">
					<div>
						<p className="pb-1 text-2xl text-white">
							Talent Categories Contests
						</p>
					</div>
					<button
						onClick={() => history.push("/contest/new")}
						className="btn-border"
					>
						Add Talent Contest
					</button>
				</div>
				{/* <div className="flex w-80 items-center bg-[#111111] rounded-full">
					<RiSearch2Line className="w-5 h-5 ml-2 mr-1 text-gray-400" />
					<input
						type="text"
						className="rounded-full text-gray-400 w-96 focus:outline-none focus:border-none focus:ring-0 bg-[#111111]/30  border-none"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						placeholder="Search"
					/>
				</div> */}
			</div>
			<div className="flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted">
				<Tabs
					selectedIndex={
						parseInt(localStorage.getItem("indexForCategory")) || 0
					}
					onSelect={(index) => {
						localStorage.setItem("indexForCategory", index);
						setTabIndex(index);
					}}
				>
					<TabList>
						{categories.map((item) => (
							<Tab onChange={() => { setCategoryId(item?._id); }} key={item?._id}>
								{item.title}
							</Tab>
						))}
					</TabList>
					{
						<DataTableBase
							columns={categoryContestsColumns}
							data={current}
							progressPending={loading}
							pagination
						/>
					}
				</Tabs>

			</div>

			<Modal
				style={{ top: 20 }}
				centered
				open={videoURL !== ""}
				onCancel={() => setVideoURL("")}
				footer={null}
			>
				<video autoPlay={true} controls={true} src={videoURL}></video>
			</Modal>
		</div>
	);
}

export default Contest;
