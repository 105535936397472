import { Space, Spin } from 'antd'
import { useEffect, useState } from 'react'

const FullPageLoader = () => {
  const [show, setShow] = useState(true)

  useEffect(() => {
    const timer = setInterval(() => setShow(false), 3000)
    return () => clearTimeout(timer)
  }, [])

  if (show) {
    return (
      <div className="flex items-center justify-center w-full h-full bg-opacity-30 bg-[#212121]">
        <Space size="middle">
          <Spin size="large" />
        </Space>
      </div>
    )
  }
  return (null)
}

export default FullPageLoader

