import { useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { InboxOutlined, DeleteOutlined } from "@ant-design/icons";
import { Form, Input, Upload, InputNumber } from "antd";
import { createTalentCategoryById, fileUpload } from "../../services/api";

import Message from "../../components/common/Message";
import Loader from "../../components/common/Loader";
import { useHistory } from "react-router-dom";

const { Item } = Form;
const { Dragger } = Upload;

function NewStack() {
	const history = useHistory();
	const [form] = Form.useForm();
	const [imageUrl, setImageUrl] = useState("");
	const [imageLoading, setImageLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [thumbnailUrl, setThumbnailUrl] = useState('')


	const uploadAPICall = async ({ file }) => {
		const formData = new FormData();
		formData.append("files", file);
		try {
			setImageLoading(true);
			const {
				data: { data, status },
			} = await fileUpload(formData);
			status && Message(`File upload successfully.`, true);
			status && setImageUrl(data[0].downloadHref);
			setImageLoading(false);
		} catch (error) {
			setImageLoading(false);
			console.log("error :>> ", error);
		}
	};

	const props = {
		name: "file",
		multiple: false,
		headers: {
			authorization: "authorization-text",
			contentType: "multipart/form-data",
		},
		showUploadList: false,
		customRequest: uploadAPICall,
		onChange(info) {
			const { status } = info.file;
			status !== "uploading" && console.log(info.file, info.fileList);
			status === "done" &&
				Message(`${info.file.name} File upload successfully.`, true);
			status === "error" &&
				Message(`${info.file.name} file upload failed.`, false);
		},
		onDrop(e) {
			console.log("Dropped files", e.dataTransfer.files);
		},
	};

	const uploadAPICallThumbnail = async ({ file }) => {
		const validImageTypes = ['image/gif', 'image/jpeg', 'image/png',];
		setImageLoading(true)
		if (validImageTypes.includes(file.type)) {
			const formData = new FormData();
			formData.append('files', file);
			try {
				const { data: { data, status } } = await fileUpload(formData)
				status && Message(`File upload successfully.`, true)
				status && setThumbnailUrl(data[0].downloadHref)
				setImageLoading(false)
			} catch (error) {
				setImageLoading(false)
				console.log('error :>> ', error);
			}
		} else Message(`Only Image or Video file allowed.`, false); setImageLoading(false)
	}


	const propsForThumbnail = {
		name: 'file',
		multiple: false,
		headers: {
			authorization: "authorization-text",
			contentType: "multipart/form-data"
		},
		showUploadList: false,
		customRequest: uploadAPICallThumbnail,
		onChange(info) {
			const { status } = info.file
			status !== 'uploading' && console.log(info.file, info.fileList)
			status === 'done' && Message(`${info.file.name} File upload successfully.`, true)
			status === 'error' && Message(`${info.file.name} file upload failed.`, false)
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files)
		},
	}

	const onFinish = async (values) => {
		try {
			setLoading(true);

			if (thumbnailUrl === '') {
				Message('Please upload thumbnail', false)
				setLoading(false)
				return
			}
			values.thumbnail = thumbnailUrl
			values.image = imageUrl;
			const { status } = await createTalentCategoryById(values);
			status
				? Message("Talent Category created successfully", true)
				: Message("Talent Category Already Exists", false);
			status && setImageUrl("");
			form.resetFields();
			setLoading(false);
			history.push('/talent-categories')
		} catch (error) {
			Message("Talent Category Already Exists", false);
			setLoading(false);
			console.log(error);
		}
	};

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			{
				loading ? (
					<div className="absolute top-0 right-0 z-50 flex items-center justify-center w-full h-full">
						<Loader />
					</div>
				) : (
					<>
						<div>
							<p className="pb-1 text-2xl text-white">Add Talent Category</p>
						</div>
						<div className="flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted">
							<Form
								form={form}
								layout="horizontal"
								onFinish={async (val) => await onFinish(val)}
								initialValues={{
									title: undefined,
									duration: undefined,
									orderNumber: undefined,
									image: undefined,
									thumbnail: undefined
								}}
							>
								<div className="flex items-start justify-between space-x-8">
									<div className="w-full px-2 text-lg">
										<Item
											rules={[{ required: true, message: "Title is required" }]}
											name="title"
										>
											<Input
												placeholder="Title"
												className="bg-transparent border border-muted text-muted"
											/>
										</Item>
										{/* <Item // for Duration
											rules={[{ required: true, message: "Fans is required" }]}
											name="fans"
										>
											<InputNumber
												placeholder="Fans"
												className="w-full py-1 bg-transparent border border-muted text-muted"
											/>
										</Item> */}
										<Item
											rules={[{ required: true, message: "Order Number is Required" }]}
											name="orderNumber"
										>
											<InputNumber
												placeholder="Order Number"
												className="w-full py-1 bg-transparent border border-muted text-muted"
											/>
										</Item>
									</div>
									<div className="w-full">
										<div className="h-60">
											{imageLoading ? (
												<Loader />
											) : imageUrl ? (
												<div className="space-y-4">
													<img
														src={imageUrl}
														className="w-full pr-6 bg-cover h-60"
														alt="contest"
													/>
													<p onClick={() => setImageUrl('')} className='inline-flex items-baseline justify-start w-full pr-6 text-lg text-muted cursor-pointer'>
														Remove Image
														<DeleteOutlined className='px-1' style={{ color: '#fff', fontSize: '16px' }} />
													</p>
												</div>
											) : (
												<Dragger {...props}>
													<p className="ant-upload-drag-icon">
														<InboxOutlined
															style={{ fontSize: "80px", color: "#666" }}
														/>
													</p>
													<p className="px-2 text-lg text-muted">
														Click or drag file to this area to upload
													</p>
												</Dragger>
											)}
											{
												imageLoading ? <Loader /> : thumbnailUrl ?
													<div className='space-y-4'>
														<div>
															<img src={thumbnailUrl} className='w-full pr-6 bg-cover h-60' alt="contest" style={{ objectFit: 'contain' }} />
														</div>
														<p onClick={() => setThumbnailUrl('')} className='inline-flex items-baseline justify-start w-full pr-6 text-lg text-muted cursor-pointer'>
															Remove Image
															<DeleteOutlined className='px-1' style={{ color: '#fff', fontSize: '16px' }} />
														</p>
													</div> :
													<div>
														<Dragger className='m-2' {...propsForThumbnail}>
															<p className="ant-upload-drag-icon"><InboxOutlined style={{ fontSize: '80px', color: '#666' }} /></p>
															<p className="px-2 text-lg text-muted">Click or Drag thumbnail Image</p>
														</Dragger>
													</div>
											}
										</div>
									</div>
								</div>
								<div className="flex items-center w-1/2 pl-2 pr-4 space-x-4">
									<button
										htmltype="submit"
										className="w-full py-2 btn-border border-muted"
									>
										Create
									</button>
									<button
										onClick={() => history.push("/talent-categories")}
										className="w-full py-2 btn-border text-danger border-danger"
									>
										Cancel
									</button>
								</div>
							</Form>
						</div>
					</>
				)}
		</div>
	);
}

export default NewStack;
