import { useEffect, useState } from "react";
import { Column, Line } from '@ant-design/plots';
import { LevelProgressionGraph } from "../../services/api";

function LevelProgression() {
	const [data, setData] = useState([]);

	useEffect(() => {
		const getChartData = async () => {
			try {
				const {
					data: { data, status },
				} = await LevelProgressionGraph();
				status && setData(data);
			} catch (error) {
				console.log(error)
			}
		};
		getChartData();
	}, []);

	const config = {
		data,
		padding: 'auto',
		xField: 'number',
		yField: 'total',
		xAxis: {
			// type: 'timeCat',
			tickCount: 5,
		},
		slider: {
			start: 0,
			end: 0.1,
		},
	};
	// return;

	return (

		<div className="overflow-auto pt-8 flex flex-col">
			<div className="flex flex-col space-y-2">
				<div className="flex items-center justify-between pr-20">
					<div>
						{/* {console.log(graphData)} */}
						<p className="pb-1 text-2xl text-white">Level Progression</p>
					</div>
				</div>
			</div>

			<div className="flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted ">
				<Line {...config} />
			</div>
		</div>
	);
}

export default LevelProgression;
