import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { BsChevronDown, BsDot } from 'react-icons/bs';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { fetchPostsByContestId, deletePost } from '../../../services/api'
import ModalImage from "react-modal-image";

import Message from '../../../components/common/Message';
import DeleteModal from '../../../components/common/DeleteModal';

function ContestChildDetails() {
	let { id } = useParams()
	const history = useHistory()
	const [posts, setPosts] = useState([])
	const [visibleDelete, setVisibleDelete] = useState(false)
	const [deletedRecordId, setDeletedRecordId] = useState(null)

	const confirmDelete = async () => {
		try {
			const { status } = await deletePost(deletedRecordId)
			status ? Message('Deleted successfully', true) : Message('Unable to delete', false)
			setVisibleDelete(false)
		} catch (error) {
			Message('Unable to delete', false)
			console.log(error)
		}
	}

	const deleteRecord = (id) => {
		setDeletedRecordId(id)
		setVisibleDelete(true)
	}

	useEffect(() => {
		const getPosts = async () => {
			try {
				const { data: { data, status } } = await fetchPostsByContestId(id)
				status && setPosts(data)
			} catch (error) {
				console.log(error)
			}
		}
		getPosts()
	}, [visibleDelete])

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			<DeleteModal
				title='Post'
				setVisible={setVisibleDelete}
				confirmDelete={confirmDelete}
				visible={visibleDelete} />
			<div className="flex items-start justify-between pr-4">
				<div>
					<p className="pb-1 text-2xl text-white">Posts</p>
					<p className="pb-4 text-muted">{posts?.length} Posts</p>
				</div>
			</div>
			<div className='flex justify-between py-2 pr-2 text-white'>
				<div className='flex items-center justify-between cursor-pointer'>
					<div className='flex items-center space-x-8'>
						<p className='text-xl font-bold capitalize w-80'>Likes</p>
						<p className='text-xl font-bold capitalize w-96'>Description</p>
					</div>
				</div>
				<p className='text-xl font-bold capitalize pr-2'>Media</p>
			</div>
			<div className='flex-1 pb-2 pr-2 space-y-4 overflow-y-auto text-white divide-y divide-gray-500 vertical-scroll'>
				{
					posts?.map(item => (
						<div key={item._id} className='flex justify-between py-2 pr-2 text-white'>
							<div className='flex items-center justify-between cursor-pointer'>
								<div className='flex items-center space-x-8'>
									<p className='text-xl font-medium capitalize w-80'>{item?.likes.likesCounter}</p>
									<p className='text-xl font-medium capitalize w-96'>{item?.description}</p>
								</div>
							</div>
							<div className='flex space-x-8'>
								<ModalImage
									small={item.media ? item.media : '/assets/sidebar/user.svg'}
									large={item.media ? item.media : '/assets/sidebar/user.svg'}
									className='w-8 h-8 p-1 rounded-md bg-white/10'
									alt="user"
								/>
							</div>
						</div>
					))
				}
			</div>
		</div>
	)
}

export default ContestChildDetails
