import { useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { useParams, useHistory } from 'react-router-dom';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { InboxOutlined, DeleteOutlined } from '@ant-design/icons';
import { Form, Input, Upload } from 'antd';
import { createSuperContestChild, fileUpload } from '../../../services/api';

import Message from '../../../components/common/Message';
import Loader from '../../../components/common/Loader';

const { Item } = Form
const { Dragger } = Upload

function NewContest() {
	const history = useHistory()
	let { id } = useParams()
	const [form] = Form.useForm()
	const [imageUrl, setImageUrl] = useState('')
	const [imageLoading, setImageLoading] = useState(false)
	const [loading, setLoading] = useState(false)

	// const uploadAPICall = async ({ file }) => {
	// 	const formData = new FormData();
	// 	formData.append('files', file);
	// 	try {
	// 		setImageLoading(true)
	// 		const { data: { data, status } } = await fileUpload(formData)
	// 		status && Message(`File upload successfully.`, true)
	// 		status && setImageUrl(data[0].downloadHref)
	// 		setImageLoading(false)
	// 	} catch (error) {
	// 		setImageLoading(false)
	// 		console.log('error :>> ', error);
	// 	}
	// }

	const uploadAPICall = async ({ file }) => {
		const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'video/mp4', 'video/MP4', 'video/webp'];
		setImageLoading(true)
		if (validImageTypes.includes(file.type)) {
			const formData = new FormData();
			formData.append('files', file);
			try {
				const { data: { data, status } } = await fileUpload(formData)
				status && Message(`File upload successfully.`, true)
				status && setImageUrl(data[0].downloadHref)
				setImageLoading(false)
			} catch (error) {
				setImageLoading(false)
				console.log('error :>> ', error);
			}
		} else Message(`Only Image or Video file allowed.`, false); setImageLoading(false)
	}

	const props = {
		name: 'file',
		multiple: false,
		headers: {
			authorization: "authorization-text",
			contentType: "multipart/form-data"
		},
		showUploadList: false,
		customRequest: uploadAPICall,
		onChange(info) {
			const { status } = info.file
			status !== 'uploading' && console.log(info.file, info.fileList)
			status === 'done' && Message(`${info.file.name} File upload successfully.`, true)
			status === 'error' && Message(`${info.file.name} file upload failed.`, false)
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files)
		},
	}

	const get_url_extension = (url) => {
		return url.split(/[#?]/)[0].split(".").pop().trim();
	};

	const onFinish = async (values) => {
		try {
			if (imageUrl === '') {
				Message('Please upload media', false)
				setLoading(false)
				return
			}

			setLoading(true)
			values.image = imageUrl
			values.superContest = id
			const { status } = await createSuperContestChild(values)
			status ? Message('Contest child created successfully', true) : Message('Unable to create child contest', false)
			status && setImageUrl('')
			form.resetFields()
			setLoading(false)
			history.push('/child/' + id)
		} catch (error) {
			Message('Unable to create child Contest', false)
			setLoading(false)
			console.log(error)
		}
	}

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			{
				loading ?
					<div className="absolute top-0 right-0 z-50 flex items-center justify-center w-full h-full">
						<Loader />
					</div>
					:
					<>
						<div>
							<p className="pb-1 text-2xl text-white">Add Super Child Contest</p>
						</div>
						<div className='flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted'>
							<Form
								form={form}
								layout="horizontal"
								onFinish={async (val) => await onFinish(val)}
								initialValues={{ tagName: undefined, titleImage: undefined }}
							>
								<div className='flex items-start justify-between space-x-8'>
									<div className='w-full px-2 text-lg'>
										<Item rules={[{ required: true, message: 'Name is required.' }]} name='tagName'>
											<Input placeholder='Name' className='bg-transparent border border-muted text-muted' />
										</Item>
										<Item rules={[{ required: true, message: 'Title Image is required.' }]} name='titleImage'>
											<Input placeholder='Image Title' className='bg-transparent border border-muted text-muted' />
										</Item>
									</div>
									<div className='w-full'>
										<div className='h-60'>
											{
												imageLoading ?
													<Loader /> :
													imageUrl ?
														<div className='space-y-4'>
															{
																get_url_extension(imageUrl) == "mp4" ||
																	get_url_extension(imageUrl) == "MP4" ||
																	get_url_extension(imageUrl) == "mov" ||
																	get_url_extension(imageUrl) == "MOV" ? (
																	<div>
																		<video className='w-full pr-6 bg-cover h-60' autoPlay={true} controls={true} src={imageUrl}></video>
																	</div>
																) : (
																	<img src={imageUrl} className='w-full pr-6 bg-cover h-60' alt="contest" style={{ objectFit: 'contain' }} />
																)
															}
															<p onClick={() => setImageUrl('')} className='inline-flex items-baseline justify-start w-full pr-6 text-lg text-muted cursor-pointer'>
																Remove Image
																<DeleteOutlined className='px-1' style={{ color: '#fff', fontSize: '16px' }} />
															</p>
														</div> :
														<Dragger {...props}>
															<p className="ant-upload-drag-icon"><InboxOutlined style={{ fontSize: '80px', color: '#666' }} /></p>
															<p className="px-2 text-lg text-muted">Click or drag file to this area to upload</p>
														</Dragger>
											}
										</div>
									</div>
								</div>
								<div className='w-1/2 pl-2 pr-4'>
									<Item>
										<button htmltype='submit' className='w-full py-2 btn-border border-muted'>Create</button>
									</Item>
								</div>
							</Form>
						</div>
					</>
			}
		</div>
	)
}

export default NewContest
