import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BsChevronDown } from 'react-icons/bs';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { InboxOutlined, DeleteOutlined } from '@ant-design/icons';
import { Form, Input, Select, Upload, InputNumber } from 'antd';
import { updateLevel, fileUpload, fetchLevelById } from '../../services/api';

import Loader from '../../components/common/Loader';
import Message from '../../components/common/Message';

const { Item } = Form
const { Dragger } = Upload

function EditStack() {
	let { id } = useParams()
	const history = useHistory()
	const [form] = Form.useForm()
	const [imageUrl, setImageUrl] = useState('')
	const [imageLoading, setImageLoading] = useState(false)
	const [loading, setLoading] = useState(false)

	const uploadAPICall = async ({ file }) => {
		const formData = new FormData();
		formData.append('files', file);
		try {
			setImageLoading(true)
			const { data: { data, status } } = await fileUpload(formData)
			status && Message(`File upload successfully.`, true)
			status && setImageUrl(data[0].downloadHref)
			setImageLoading(false)
		} catch (error) {
			setImageLoading(false)
			console.log('error :>> ', error);
		}
	}

	const props = {
		name: 'file',
		multiple: false,
		headers: {
			authorization: "authorization-text",
			contentType: "multipart/form-data"
		},
		showUploadList: false,
		customRequest: uploadAPICall,
		onChange(info) {
			const { status } = info.file
			status !== 'uploading' && console.log(info.file, info.fileList)
			status === 'done' && Message(`${info.file.name} File upload successfully.`, true)
			status === 'error' && Message(`${info.file.name} file upload failed.`, false)
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files)
		},
	}

	const onFinish = async (values) => {
		try {
			if (imageUrl === '') {
				Message('Please upload media', false)
				setLoading(false)
				return
			}

			setLoading(true)
			values.image = imageUrl

			const { status } = await updateLevel(values, id)
			status ? Message('Level updated successfully', true) : Message('Unable to updated Level', false)
			status && setImageUrl('')
			setLoading(false)
			history.push('/level')
		} catch (error) {
			Message('Unable to updated Level', false)
			setLoading(false)
			console.log(error)
		}
	}

	useEffect(() => {
		const getLevelById = async () => {
			try {
				const { data: { data, status } } = await fetchLevelById(id)
				if (status) {
					setImageUrl(data.image)
					form.setFieldsValue({ name: data?.name, fans: data?.fans, xp: data?.xp, number: data?.number })
				}
			} catch (error) {
				console.log(error)
			}
		}

		getLevelById()
	}, [])

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			{
				loading ?
					<div className="absolute top-0 right-0 z-50 flex items-center justify-center w-full h-full">
						<Loader />
					</div>
					:
					<>
						<div>
							<p className="pb-1 text-2xl text-white">Edit Level</p>
						</div>
						<div className='flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted'>
							<Form form={form} onFinish={async (val) => await onFinish(val)}>
								<div className='flex items-start justify-between space-x-8'>
									<div className=' w-1/2 px-2 text-lg'>
										<Item name='name'>
											<Input placeholder='Name' className='bg-transparent border border-muted text-muted' />
										</Item>
										<Item name='number'>
											<Input disabled={true} placeholder='Level Number' className='bg-transparent border border-muted text-muted' />
										</Item>
										<Item name='fans'>
											<InputNumber placeholder='Fans' className='w-full bg-transparent border border-muted text-muted' />
										</Item>
										<Item name='xp'>
											<InputNumber placeholder='XPs' className='w-full bg-transparent border border-muted text-muted' />
										</Item>
									</div>
								</div>
								<div className='flex items-center w-1/2 pl-2 pr-4 space-x-4'>
									<button htmltype='submit' className='w-full py-2 btn-border border-muted'>Update</button>
									<button onClick={() => history.push('/level')} className='w-full py-2 btn-border text-danger border-danger'>Cancel</button>
								</div>
							</Form>
						</div>
					</>
			}
		</div>
	)
}

export default EditStack
