import { Pie } from '@ant-design/plots';
import { useEffect, useState } from 'react';
import { fetchAdminDashboardStats, fetchDeviceTypeGraph, fetchLoginTypeGraph } from "../../services/api";
const PieChart = (type) => {
	const [data, setData] = useState([])

	useEffect(() => {
		const getChartData = async () => {
			try {
				console.log('TYPE', type.type)
				if (type.type === 'revenue') {
					const { data: { data: resp, status } } = await fetchAdminDashboardStats()
					status && setData(resp)
				} else if (type.type === 'deviceType') {
					const { data: { data: resp, status } } = await fetchDeviceTypeGraph()
					status && setData(resp)
				} else if (type.type === 'loginType') {
					const { data: { data: resp, status } } = await fetchLoginTypeGraph()
					status && setData(resp)
				}
			} catch (err) {
				console.log('Error', err)
			}
		}
		getChartData()
	}, [])

	const config = {
		appendPadding: 10,
		data,
		angleField: 'value',
		colorField: 'type',
		radius: 0.9,
		legend: {
			layout: 'vertical',
			position: 'bottom',
		},
		label: {
			type: 'inner',
			offset: '-30%',
			content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
			style: {
				fontSize: 14,
				textAlign: 'center',
			},
		},
		interactions: [
			{
				type: 'element-active',
			},
		],
	}

	return <Pie style={{ height: '380px', color: 'white' }
	} {...config} />;
};

export default PieChart
