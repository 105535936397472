/* eslint-disable jsx-a11y/anchor-is-valid */
import ModalImage from "react-modal-image";
import { useEffect, useState } from "react";
import { RiSearch2Line, RiCloseCircleLine } from "react-icons/ri";
import { fetchWinners } from "../../services/api";
import DataTableBase from "../../components/common/dataTable";
import { BsFillCameraVideoFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { Modal } from "antd";

function Winner() {

	let yesterday = new Date();
	yesterday = yesterday.setDate(yesterday.getDate() - 1);

	const history = useHistory();
	const [search, setSearch] = useState("");
	const [winners, setWinners] = useState([]);
	const [loading, setLoading] = useState(false);
	const [videoURL, setVideoURL] = useState("");
	const [timeArray, setTimeArray] = useState([new Date(yesterday), new Date()]);



	useEffect(() => {
		const getUsers = async () => {
			setLoading(true);
			const {
				data: { data, status },
			} = await fetchWinners(timeArray[0], timeArray[1]);
			status && setWinners(data?.posts);
			setLoading(false);
		};
		getUsers(); // fetch page 1 of users
	}, [timeArray]);

	const get_url_extension = (url) => {
		return url.split(/[#?]/)[0].split(".").pop().trim();
	};

	const columns = [
		{
			name: "Contest Name",
			selector: (row) => row.contestId.tagName,
			sortable: true,

			cell: (row) => (
				<a onClick={() => history.push("/contest/" + row.contestId?._id)}>
					{row.contestId.tagName}
				</a>
			),
		},
		{
			name: "Caption",
			selector: (row) => row.winnerPostId.description,
			sortable: true,
			wrap: true
		},
		{
			name: "Day",
			selector: (row) => new Date(row.createdAt),
			sortable: true,

			cell: (row) => (
				new Date(row.createdAt).toDateString()
			)
		},
		{
			name: "Time",
			selector: (row) =>
				new Date(row.winnerPostId.createdAt).toLocaleTimeString(),
			sortable: true,
		},
		{
			name: "User",
			selector: (row) => `${row.winnerPostId.user.firstName} ${row.winnerPostId.user.lastName}`,
			sortable: true,
			cell: (row) => (
				<a
					onClick={() => history.push("/user/" + row.winnerPostId.user?._id)}
				>{`${row.winnerPostId.user.firstName} ${row.winnerPostId.user.lastName}`}</a>
			),
		},
		{
			name: "Media",
			cell: (row) =>
				get_url_extension(row.winnerPostId.media) === "mp4" ||
					get_url_extension(row.winnerPostId.media) === "MP4" ||
					get_url_extension(row.winnerPostId.media) === "mov" ||
					get_url_extension(row.winnerPostId.media) === "MOV" ? (
					<div>
						<BsFillCameraVideoFill
							style={{ height: "25px", width: "25px", margin: "auto" }}
							onClick={() => {
								setVideoURL(row.winnerPostId.media);
							}}
						/>
					</div>
				) : (
					<ModalImage
						small={row.winnerPostId.media_small || row.winnerPostId.media}
						large={row.winnerPostId.media}
						className="w-8 h-8 p-1 rounded-md bg-white/10"
						alt=""
					/>
				),
		},
	];

	return (
		<div className="vertical-scroll pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			<div className="flex flex-col space-y-2">
				<div className="flex items-center justify-between pr-20">
					<div>
						<p className="pb-1 text-2xl text-white">Winners</p>
					</div>
					<div className="flex items-center justify-between">
						<p className="text-lg capitalize line-clamp-1 overflow-clip text-white pr-3">
							Select Time:
						</p>
						<DateTimeRangePicker onChange={setTimeArray} value={timeArray} />
					</div>
				</div>
				<div className="flex w-80 items-center bg-[#111111] rounded-full">
					<RiSearch2Line className="w-5 h-5 ml-2 mr-1 text-gray-400" />
					<input
						type="text"
						className="rounded-full text-gray-400 w-96 focus:outline-none focus:border-none focus:ring-0 bg-[#111111]/30  border-none"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						placeholder="Search"
					/>
				</div>
			</div>
			<div>
				<div className="flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted">
					{
						<DataTableBase
							progressPending={loading}
							columns={columns}
							data={winners}
							defaultSortFieldId={1}
							pagination
						/>
					}
				</div>
			</div>
			<Modal
				style={{ top: 20, background: 'transparent', color: '#fff', border: 'none' }}
				centered
				visible={videoURL !== ""}
				onCancel={() => setVideoURL("")}
				footer={null}
				closeIcon={RiCloseCircleLine}
			>
				<video style={{ maxWidth: '800xp', margin: 'auto', background: 'transparent' }} autoPlay={true} controls={true} src={videoURL}></video>
			</Modal>
		</div >
	);
}

export default Winner;
