import { message } from 'antd'

const key = 'updatable'

const openMessage = (msg, status) => {
  message.loading({ content: 'Loading...', key })
  status === true ?
    setTimeout(() => message.success({ content: msg, key, duration: 2 }), 1000) :
    setTimeout(() => message.error({ content: msg, key, duration: 2 }), 1000)
}

export default openMessage