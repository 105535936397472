import React, { useState, useRef, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import {
	fetchUserLocationClusters,
	fetchUserLocationTable,
} from "../../services/api";
import Loader from "../../components/common/Loader";
import { BiUser } from "react-icons/bi";
import DataTableBase from "../../components/common/dataTable";
import { Button, Select } from "antd";
import { InboxOutlined, DeleteOutlined, DownOutlined } from "@ant-design/icons";

const Marker = ({ children }) => children;
const { Option } = Select;

function UsersMap() {
	const mapRef = useRef();
	const [bounds, setBounds] = useState(null);
	const [zoom, setZoom] = useState(10);
	const [data, setData] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [label, setLabel] = useState("city");
	const [userLocation, setUserLocation] = useState("");
	const [loading, setLoading] = useState(false);
	const [mapVisible, setMapVisible] = useState(false);

	useEffect(() => {
		const getClusters = async () => {
			try {
				setLoading(true);
				navigator.geolocation.getCurrentPosition(
					(location) => {
						setUserLocation(location);
					},
					(error) => {
						console.log(error);
					}
				);
				const {
					data: { data, status },
				} = await fetchUserLocationClusters();
				const {
					data: { data: dataTable, status: statusTable },
				} = await fetchUserLocationTable(label);
				status && setData(data);
				statusTable && setTableData(dataTable);
				setLoading(false);
			} catch (error) {
				console.log(error);
			}
		};
		getClusters();
	}, [label]);

	const { clusters, supercluster } = useSupercluster({
		points: data,
		bounds,
		zoom,
		options: { radius: 75, maxZoom: 20 },
	});

	const columns = [
		{
			name: `${label.charAt(0).toUpperCase() + label.slice(1)}`,
			selector: (row) => row._id || "Location Not Avalible",
			sortable: true,
		},
		{
			name: "Total",
			selector: (row) => row.total,
			sortable: true,
		},
	];
	return loading ? (
		<Loader />
	) : (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			<div className="flex items-center justify-between">
				<div className="w-50 flex flex-col pr-20 space-y-2">
					<div>
						<p className="pb-1 text-2xl text-white">Users Location</p>
					</div>
				</div>
			</div>
			{mapVisible ? (
				<div className="flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted">
					<GoogleMapReact
						bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
						defaultCenter={{
							lat: userLocation?.coords?.latitude,
							lng: userLocation?.coords?.longitude,
						}}
						defaultZoom={10}
						yesIWantToUseGoogleMapApiInternals
						onGoogleApiLoaded={({ map }) => {
							mapRef.current = map;
						}}
						onChange={({ zoom, bounds }) => {
							setZoom(zoom);
							setBounds([
								bounds.nw.lng,
								bounds.se.lat,
								bounds.se.lng,
								bounds.nw.lat,
							]);
						}}
					>
						{clusters.map((cluster) => {
							const [longitude, latitude] = cluster.geometry.coordinates;
							const { cluster: isCluster, point_count: pointCount } =
								cluster.properties;
							if (isCluster) {
								return (
									<Marker
										key={`user-${cluster.id}`}
										lat={latitude}
										lng={longitude}
									>
										<div
											className="cluster-marker"
											style={{
												width: `${10 + (pointCount / clusters.length) * 10}px`,
												height: `${10 + (pointCount / clusters.length) * 10}px`,
											}}
											onClick={() => {
												const expansionZoom = Math.min(
													supercluster.getClusterExpansionZoom(cluster.id),
													20
												);
												mapRef.current.setZoom(expansionZoom);
												mapRef.current.panTo({ lat: latitude, lng: longitude });
											}}
										>
											{pointCount}
										</div>
									</Marker>
								);
							} else {
								return (
									<Marker
										key={`user-${cluster.properties.crimeId}`}
										lat={latitude}
										lng={longitude}
									>
										<button className="user-marker flex flex-column">
											<img
												src={cluster.properties.image}
												className="p-1 m-1 rounded-md bg-white/10 "
												alt="img"
											/>
											<div
												style={{
													position: "relative",
													height: "40px",
													width: "100px",
													background: "rgba(0,0,0,.5)",
												}}
												className="rounded-md text-white flex justify-center bg-dark content-center user-tooltip-map hidden"
											>
												<img
													src={cluster.properties.image}
													className="p-1 m-1 rounded-md bg-white/10 "
													alt="img"
												/>
												<h2 className="text-white my-auto">
													{cluster.properties.userName}
												</h2>
											</div>
										</button>
									</Marker>
								);
							}
						})}
					</GoogleMapReact>
				</div>
			) : null}
			<div className="flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll">
				<div className="flex justify-between">
					<Select
						onChange={(val) => setLabel(val)}
						suffixIcon={<DownOutlined style={{ color: "white" }} />}
						className="py-1 bg-transparent border border-muted text-muted text-gray-400"
						bordered={false}
						placeholder="Select Group"
					>
						<Option className="bg-transparent" value="city">
							City
						</Option>
						<Option className="bg-transparent" value="country">
							Country
						</Option>
						<Option className="bg-transparent" value="state">
							State
						</Option>
					</Select>
					<button
						onClick={() => setMapVisible(!mapVisible)}
						className="btn-border"
					>
						Show Map
					</button>
				</div>

				<DataTableBase
					columns={columns}
					data={tableData}
					progressPending={loading}
					pagination
				/>
			</div>
		</div>
	);
}

export default UsersMap;
