import { useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { InboxOutlined, DeleteOutlined } from '@ant-design/icons';
import { Form, Input, Upload, InputNumber } from 'antd';
import { createStack, fileUpload } from '../../services/api';

import Message from '../../components/common/Message';
import Loader from '../../components/common/Loader';
import { useHistory } from 'react-router-dom';

const { Item } = Form
const { Dragger } = Upload

function NewStack() {
	const history = useHistory()
	const [form] = Form.useForm()
	const [imageUrl, setImageUrl] = useState('')
	const [imageLoading, setImageLoading] = useState(false)
	const [loading, setLoading] = useState(false)



	const onFinish = async (values) => {
		try {
			const { status } = await createStack(values)
			status ? Message('Stack created successfully', true) : Message('Unable to create Stack', false)
			form.resetFields()
			setLoading(false)
		} catch (error) {
			Message('Unable to create Stack', false)
			setLoading(false)
			console.log(error)
		}
	}

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			{
				loading ?
					<div className="absolute top-0 right-0 z-50 flex items-center justify-center w-full h-full">
						<Loader />
					</div>
					:
					<>
						<div>
							<p className="pb-1 text-2xl text-white">Add Stack</p>
						</div>
						<div className='flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted'>
							<Form
								form={form}
								layout="horizontal"
								onFinish={async (val) => await onFinish(val)}
								initialValues={{
									name: undefined, titleMedia: undefined, numberOfCompitations: undefined,
									contests: [], duration: undefined, orderNumber: undefined
								}}
							>
								<div className='flex items-start justify-between space-x-8'>
									<div className='w-1/2 px-2 text-lg'>
										<Item rules={[{ required: true, message: '' }]} name='name'>
											<Input placeholder='Name' type='text' className='bg-transparent border border-muted text-muted' />
										</Item>

										<Item rules={[{ required: true, message: '' }]} name='orderNumber'>
											<InputNumber placeholder='Order Number' type='number' className='w-full bg-transparent border border-muted text-muted' />
										</Item>
										<Item name='numberOfCompitations'>
											<InputNumber placeholder='Number of Compitations' className='w-full bg-transparent border border-muted text-muted' />
										</Item>
										<Item rules={[{ required: true, message: '' }]} name='duration'>
											<InputNumber placeholder='Duration' type='number' min='1' className='w-full bg-transparent border border-muted text-muted' />
										</Item>
									</div>
								</div>
								<div className='flex items-center w-1/2 pl-2 pr-4 space-x-4'>
									<button htmltype='submit' className='w-full py-2 btn-border border-muted'>Create</button>
									<button onClick={() => history.push('/stack')} className='w-full py-2 btn-border text-danger border-danger'>Cancel</button>
								</div>
							</Form>
						</div>
					</>
			}
		</div>
	)
}

export default NewStack
