import React from 'react';

const DeleteModal = ({ isOpen, onClose, children }) => {
	if (!isOpen) return null;

	return (
		<div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-100 w-[40vw] h-[40vh] m-auto rounded-[20px]">
			<div className="bg-dark p-4 rounded-lg ">
				{children}
			</div>
		</div>
	);
};

export default DeleteModal;
