import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { BsChevronDown } from 'react-icons/bs';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { fetchPostsByUserId } from '../../services/api';
import ModalImage from "react-modal-image";

function Post() {
	let { id } = useParams()
	const history = useHistory()
	const [posts, setPosts] = useState([])
	const [postLength, setPostLength] = useState(0)

	useEffect(() => {
		const getPosts = async () => {
			try {
				const { data: { data, status, length } } = await fetchPostsByUserId(id)
				status && setPosts(data.userPosts)
				status && setPostLength(data.totalPosts)
			} catch (error) {
				console.log(error)
			}
		}
		getPosts()
	}, [])

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			<div className="flex justify-between items-start pr-4">
				<div>
					<p className="text-white pb-1 text-2xl">Post</p>
					<p className="text-muted pb-4">{postLength > 0 ? `${postLength} Posts` : `This user does not have any posts`}</p>
				</div>
			</div>
			<div className='flex justify-between py-2 pr-2 text-white'>
				<div className='flex items-center justify-between cursor-pointer'>
					<div className='flex items-center space-x-8'>
						<p className='text-xl font-semibold capitalize w-96'>Description</p>
						<p className='text-xl font-semibold capitalize w-80'>Likes</p>
						<p className='text-xl font-semibold capitalize w-80'>Comments</p>
					</div>
				</div>
				<p className='text-xl font-semibold capitalize pr-2'>Media</p>
			</div>
			<div className='flex-1 overflow-y-auto vertical-scroll text-white pb-2 space-y-4 divide-gray-500 divide-y pr-2'>
				{
					posts?.map(item => (
						<div key={item._id} className='flex justify-between py-2 pr-2 text-white'>
							<div className='flex items-center justify-between cursor-pointer'>
								<div className='flex items-center space-x-8'>
									<p className='text-xl font-semibold capitalize w-96'>{item?.description}</p>
									<p className='text-xl font-semibold capitalize w-80'>{item?.likes.likesCounter}</p>
									<p className='text-xl font-semibold capitalize w-80'>{item?.comments.length}</p>
								</div>
							</div>
							<div className='flex space-x-8'>
								<ModalImage
									small={item.media ? item.media : '/assets/sidebar/user.svg'}
									large={item.media ? item.media : '/assets/sidebar/user.svg'}
									className='w-8 h-8 p-1 rounded-md bg-white/10'
									alt="user"
								/>
							</div>
						</div>
					))
				}
			</div>
			{/* <div className="flex justify-between text-muted items-center px-4">
				<p>1-5 of  5</p>
				<div className="flex items-center space-x-2">
					<p>Rows per page:</p>
					<div className="btn-border border-muted text-muted flex items-center space-x-1">
						<p>10</p>
						<BsChevronDown />
					</div>
					<div className="btn-border p-2 border-muted text-muted">
						<FaArrowLeft />
					</div>
					<div className="btn-border p-2 border-muted text-muted">
						<FaArrowRight />
					</div>
				</div>
			</div> */}
		</div>
	)
}

export default Post
