import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ModalImage from "react-modal-image";
import { BsDot } from 'react-icons/bs';
import { fetchContestsByStackId, deleteContest } from '../../services/api'
import { BsFillCameraVideoFill } from "react-icons/bs";
import { Modal, Switch } from "antd";

import Message from '../../components/common/Message';
import DeleteModal from '../../components/common/DeleteModal';

function Stack() {
	let { id } = useParams()
	const history = useHistory()
	const [contests, setContests] = useState([])
	const [visibleDelete, setVisibleDelete] = useState(false)
	const [deletedRecordId, setDeletedRecordId] = useState(null)
	const [videoURL, setVideoURL] = useState("");


	const confirmDelete = async () => {
		try {
			const { status } = await deleteContest(deletedRecordId)
			status ? Message('Deleted successfully', true) : Message('Unable to delete', false)
			setVisibleDelete(false)
		} catch (error) {
			Message('Unable to delete', false)
			console.log(error)
		}
	}

	const deleteRecord = (id) => {
		setDeletedRecordId(id)
		setVisibleDelete(true)
	}

	useEffect(() => {
		const getContests = async () => {
			try {
				const { data: { data, status } } = await fetchContestsByStackId(id)
				status && setContests(data)
			} catch (error) {
				console.log(error)
			}
		}
		getContests()
	}, [visibleDelete])

	const get_url_extension = (url) => {
		return url.split(/[#?]/)[0].split(".").pop().trim();
	};

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			<DeleteModal
				title='Contest'
				setVisible={setVisibleDelete}
				confirmDelete={confirmDelete}
				visible={visibleDelete} />
			<div className="flex items-start justify-between pr-4">
				<div>
					<p className="pb-1 text-2xl text-white">Stack</p>
					<p className="pb-4 text-muted">{contests?.length} Contests</p>

				</div>
				<button onClick={() => history.push('/contest/new')} className="btn-border">Add Contest</button>
			</div>
			<div className='flex-1 pb-2 pr-2 space-y-4 overflow-y-auto text-white divide-y divide-gray-500 vertical-scroll'>
				{
					contests?.map(item => (
						<div key={item?._id} className='flex items-center justify-between pt-4 cursor-pointer'>
							<div className='flex items-center -ml-1 space-x-0'>
								<BsDot className='text-4xl text-green-600' />
								<p className='text-xl capitalize'>{item?.tagName}</p>
							</div>
							<div className='flex space-x-8'>
								{
									get_url_extension(item.image) == "mp4" ||
										get_url_extension(item.image) == "MP4" ||
										get_url_extension(item.image) == "mov" ||
										get_url_extension(item.image) == "MOV" ? (
										<div>
											<BsFillCameraVideoFill
												style={{ height: "25px", width: "25px", margin: "auto" }}
												onClick={() => {
													setVideoURL(item.image);
												}}
											/>
										</div>
									) : (
										<ModalImage
											small={item?.image}
											large={item?.image}
											className='w-8 h-8 p-1 rounded-md bg-white/10'
											alt=""
										/>
									)
								}
								<button onClick={() => history.push('/contest/edit/' + item?._id)} className="text-lg text-muted">Edit</button>
								<button onClick={() => deleteRecord(item?._id)} className="text-lg text-danger">Delete</button>
							</div>
						</div>
					))
				}
			</div>
			<Modal
				style={{ top: 20 }}
				centered
				visible={videoURL !== ""}
				onCancel={() => setVideoURL("")}
				footer={null}
			>
				<video autoPlay={true} controls={true} src={videoURL}></video>
			</Modal>
		</div>
	)
}

export default Stack
