import { useState } from "react"
import { login } from '../services/api'
import Message from '../components/common/Message';

export default function useProvideAuth() {
	const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")))
	const [token, setToken] = useState(localStorage.getItem("user"))

	const signIn = async (data, redirect, loading) => {
		try {
			const { data: { status, data: resp } } = await login(data)
			if (status) {
				setUser(resp)
				setToken(resp?.accessToken)
				localStorage.setItem('user', JSON.stringify(resp))
				localStorage.setItem('token', resp?.accessToken)
				redirect()
				loading()
			}
		} catch (error) {
			Message('Invalid Credentials', false)
			loading()
			console.log({ error })
		}
	}


	const signOut = _ => {
		window.localStorage.clear()
		setUser(null)
		setToken('')
	}

	return {
		user,
		token,
		signIn,
		signOut
	}
}