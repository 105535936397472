import { Modal } from 'antd'
import moment from 'moment';
import { useEffect, useState } from 'react';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs'

function UserModal({ visible, setVisible, normalPost, currentPost, superPost, level }) {
	const [post, setPost] = useState({})

	const handleSuperPost = (side) => {
		const index = superPost.findIndex(x => x.id === post.id)
		side === 'left' ? setPost(superPost[index - 1]) : setPost(superPost[index + 1])
	}

	const handleNormalPost = (side) => {
		const index = normalPost.findIndex(x => x.id === post.id)
		side === 'left' ? setPost(normalPost[index - 1]) : setPost(normalPost[index + 1])
	}

	useEffect(() => {
		setPost(currentPost)
	}, [currentPost])

	const get_url_extension = (url) => {
		if (url !== undefined) {
			return url.split(/[#?]/)[0].split(".").pop().trim();
		}
		return;
	};

	return (
		<Modal
			title=''
			centered
			bodyStyle={{ background: "linear-gradient(90deg, rgba(25,24,45,1) 0%, rgba(43,40,40,0.8870141806722689) 0%)" }}
			visible={visible}
			footer={null}
			onCancel={() => setVisible(false)}
			closable={false}
			width={900}>
			<div className='flex items-center justify-between w-full py-10 space-x-2'>
				{
					currentPost.type === 'normalPost' ?
						normalPost.findIndex(x => x.id === post.id) === 0 ?
							<BsChevronLeft className='text-4xl cursor-not-allowed text-white/50' /> :
							<BsChevronLeft onClick={() => handleNormalPost('left')} className='text-4xl cursor-pointer text-white/50 hover:text-white' />
						:
						superPost.findIndex(x => x.id === post.id) === 0 ?
							<BsChevronLeft className='text-4xl cursor-not-allowed text-white/50' /> :
							<BsChevronLeft onClick={() => handleSuperPost('left')} className='text-4xl cursor-pointer text-white/50 hover:text-white' />
				}
				<div className='flex items-center justify-center w-full space-x-8'>
					{
						get_url_extension(post.media) == "mp4" ||
							get_url_extension(post.media) == "MP4" ||
							get_url_extension(post.media) == "mov" ||
							get_url_extension(post.media) == "MOV" ? (
							<video
								className='p-1 rounded-lg border-solid border-2 border-gray-100 w-[320px] h-[320px] bg-white/10'
								controls={true}
								src={post.media}>
							</video>
						) : (
							<img
								src={post?.media}
								onError={e => { e.target.onerror = null; e.target.src = "/assets/sidebar/user.svg" }}
								className='p-1 rounded-lg border-solid border-2 border-gray-100 w-[320px] h-[320px] bg-white/10' alt="img"
							/>
						)
					}
					<div className='w-1/2 space-y-3 text-lg'>
						<div className='flex space-x-2'>
							<p className='text-xl text-white'>Post Created:</p>
							<p className='text-white/70'>{moment(post?.postCreated).format('DD/MM/YYYY')}</p>
						</div>
						<div className='flex space-x-2'>
							<p className='text-xl text-white'>Contest participated :</p>
							<p className='text-white/70'>{post?.contestParticipated}</p>
						</div>
						<div className='flex space-x-2'>
							<p className='text-xl text-white'>Stack number:</p>
							<p className='text-white/70'>{post?.stackNumber}</p>
						</div>
						<div className='flex space-x-2'>
							<p className='text-xl text-white'>Likes received:</p>
							<p className='text-white/70'>{post?.likes}</p>
						</div>
						<div className='flex space-x-2'>
							<p className='text-xl text-white'>Comments received:</p>
							<p className='text-white/70'>{post?.comments}</p>
						</div>
						<div className='flex space-x-2'>
							<p className='text-xl text-white'>User Level:</p>
							<p className='text-white/70'>{level}</p>
						</div>
					</div>
				</div>
				{
					currentPost.type === 'normalPost' ?
						normalPost.findIndex(x => x.id === post.id) === normalPost.length - 1 ?
							<BsChevronRight className='text-4xl cursor-not-allowed text-white/50' /> :
							<BsChevronRight onClick={() => handleNormalPost('right')} className='text-4xl cursor-pointer text-white/50 hover:text-white' />
						:
						superPost.findIndex(x => x.id === post.id) === superPost.length - 1 ?
							<BsChevronRight className='text-4xl cursor-not-allowed text-white/50' /> :
							<BsChevronRight onClick={() => handleSuperPost('right')} className='text-4xl cursor-pointer text-white/50 hover:text-white' />
				}
			</div>
		</Modal>
	)
}

export default UserModal
