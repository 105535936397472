import { useEffect, useState } from 'react';
import ModalImage from "react-modal-image";
import { RiSearch2Line } from 'react-icons/ri';
import { deleteStack, fetchStacks } from '../../services/api'
import { useHistory } from 'react-router-dom'
import NoData from '../../components/common/NoData';
import Loader from '../../components/common/Loader';


import Message from '../../components/common/Message';
import DeleteModal from '../../components/common/DeleteModal';

function Stack() {
	const history = useHistory()
	const [search, setSearch] = useState('')
	const [stacks, setStacks] = useState([])
	const [visibleDelete, setVisibleDelete] = useState(false)
	const [deletedRecordId, setDeletedRecordId] = useState(null)
	const [loading, setLoading] = useState(false)

	const filteredStacks = stacks.filter(stack => {
		return (
			stack.name.toLowerCase().includes(search.toLowerCase())
		)
	}
	)

	const confirmDelete = async () => {
		try {
			const { status } = await deleteStack(deletedRecordId)
			status ? Message('Deleted successfully', true) : Message('Unable to delete', false)
			setVisibleDelete(false)
		} catch (error) {
			Message('Unable to delete', false)
			console.log(error)
		}
	}

	const deleteRecord = (id) => {
		setDeletedRecordId(id)
		setVisibleDelete(true)
	}

	useEffect(() => {
		const getStacks = async () => {
			try {
				setLoading(true)
				const { data: { data, status } } = await fetchStacks()
				status && setStacks(data)
				setLoading(false)
			} catch (error) {
				console.log(error)
			}
		}
		getStacks()
	}, [visibleDelete])

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			<DeleteModal
				title='Stack'
				setVisible={setVisibleDelete}
				confirmDelete={confirmDelete}
				visible={visibleDelete} />
			<div className="flex flex-col pb-4 pr-20">
				<div className="flex items-center justify-between pr-20">
					<div>
						<p className="pb-1 text-2xl text-white">Stack List</p>
						<p className="text-muted">{stacks?.length} Stacks</p>
					</div>
					<button onClick={() => history.push('/stack/new')} className="btn-border">Add Stack</button>
				</div>
				<div className='flex items-center space-x-2'>
					<div className="flex items-center bg-[#111111] rounded-full">
						<RiSearch2Line className="w-5 h-5 ml-2 mr-1 text-gray-400" />
						<input
							type="text"
							className="rounded-full text-gray-400 w-96 focus:outline-none focus:border-none focus:ring-0 bg-[#111111]/30  border-none"
							value={search}
							onChange={e => setSearch(e.target.value)}
							placeholder="Search" />
					</div>
				</div>
			</div>
			<div className='flex-1 pb-2 space-y-4 overflow-y-auto text-white divide-y divide-gray-500 vertical-scroll'>
				{
					loading ?
						<div className="absolute top-0 right-0 z-50 flex items-center justify-center w-full h-full">
							<Loader />
						</div>
						:
						filteredStacks?.map(item => (
							<div key={item?._id} className='flex items-center justify-between pt-4 cursor-pointer'>
								<div onClick={() => history.push('/stack/' + item?._id)} className='w-1/2'>
									<p className='text-xl capitalize'>{item?.name}</p>
								</div>
								<div className='flex space-x-4'>
									<button onClick={() => history.push('/stack/edit/' + item?._id)} className="px-4 text-lg text-muted">Edit</button>
									<button onClick={() => deleteRecord(item?._id)} className="px-4 text-lg text-danger">Delete</button>
								</div>
							</div>
						))
				}
			</div>
		</div>
	)
}

export default Stack
