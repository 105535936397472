/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { RiSearch2Line } from "react-icons/ri";
import { BiEdit } from "react-icons/bi";
import { fetchRewards } from "../../services/api";
import DataTableBase from "../../components/common/dataTable";

function Reward() {
	const history = useHistory();
	const [search, setSearch] = useState("");
	const [rewards, setRewards] = useState([]);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [totalRewards, setTotalRewards] = useState(0);
	const [loading, setLoading] = useState(false);

	const filteredRewards = rewards.filter((reward) => {
		return reward?.type.toLowerCase().includes(search.toLowerCase());
	});

	useEffect(() => {
		const getRewards = async () => {
			try {
				setLoading(true);
				const {
					data: { data, status },
				} = await fetchRewards(page, limit);
				status && setRewards(data.rewards);
				status && setTotalRewards(data.totalRewards);
				setLoading(false);
			} catch (error) {
				setLoading(false);
				console.log(error);
			}
		};
		getRewards();
	}, [page, limit]);


	const columns = [
		{
			name: "Reward Type",
			selector: (row) => row.type,
			sortable: true,

		},
		{
			name: "XP Value",
			selector: (row) => row.value,
			sortable: true,

		},
		{
			name: "Actions",
			center: true,
			cell: (row) => (
				<a onClick={() => history.push("/reward/edit/" + row._id)}>
					<div className="flex items-center underline">
						<BiEdit /> &nbsp;Edit
					</div>
				</a>
			),
		},
	];

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			<div className="flex flex-col space-y-2">
				<div className="flex items-center justify-between pr-20">
					<div>
						<p className="pb-1 text-2xl text-white">Rewards System</p>
						<p className="text-muted">{totalRewards} Rewards</p>
					</div>
				</div>
				<div className="flex w-80 items-center bg-[#111111] rounded-full">
					<RiSearch2Line className="w-5 h-5 ml-2 mr-1 text-gray-400" />
					<input
						type="text"
						className="rounded-full text-gray-400 w-96 focus:outline-none focus:border-none focus:ring-0 bg-[#111111]/30  border-none"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						placeholder="Search"
					/>
				</div>
			</div>
			{
				(
					<div className="flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted">
						{

							< DataTableBase
								progressPending={loading}
								columns={columns}
								data={filteredRewards}
								defaultSortFieldId={1}
								pagination
							/>
						}
					</div>
				)}
		</div>
	);
}

export default Reward;
