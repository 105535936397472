/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import PieGraph from "../../components/common/PieGraph";
import { BsFillCameraVideoFill } from "react-icons/bs";
import { RiCloseCircleLine } from "react-icons/ri";
import { Modal } from "antd";
import { fetchAdminDashboard, fetchWinners, fetchAdminDashboardCategory } from "../../services/api";
import ModalImage from "react-modal-image";
import { useHistory } from "react-router-dom";
import DataTableBase from "../../components/common/dataTable";
import CanvasJSReact from '../../components/libs/canvasjs.react';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const calculatePercentage = (currentMonth, lastMonth) => {
	let res = ((currentMonth - lastMonth) / lastMonth).toFixed(2);
	return isFinite(res) ? res : 100;
}

function Home() {
	let yesterday = new Date();
	yesterday = yesterday.setDate(yesterday.getDate() - 1);

	const history = useHistory();
	const [data, setData] = useState({});
	const [categoriesData, setCategoriesData] = useState([]);
	const [winners, setWinners] = useState([]);
	const [loading, setLoading] = useState(false);
	const [videoURL, setVideoURL] = useState("");
	const [timeArray, setTimeArray] = useState([new Date(yesterday), new Date()]);

	useEffect(() => {
		const getDashboard = async () => {
			try {
				setLoading(true);
				const {
					data: { data: resp, status },
				} = await fetchAdminDashboard();
				console.log(data)
				const {
					data: { data: respCat, status: statusCat },
				} = await fetchAdminDashboardCategory();
				const {
					data: { data: winnerData, status: winnerStatus },
				} = await fetchWinners(timeArray[0], timeArray[1]);
				// const {
				// 	data: { data, status: actionStatus },
				// } = await fetchuserActionChart({ actions: keys, view: "overview" });
				status && setData(resp);
				statusCat && setCategoriesData(respCat);
				winnerStatus && setWinners(winnerData?.posts);
				setLoading(false);
			} catch (error) {
				setLoading(false);
				console.log(error);
			}
		};
		getDashboard();
	}, []);

	const get_url_extension = (url) => {
		return url.split(/[#?]/)[0].split(".").pop().trim();
	};

	const Card = ({ title, count, percentage, lastMonth, currentMonth }) => {
		return (
			<div className="w-full py-2 px-4 rounded-[30px] card-bg">
				<p className="pb-4 text-xl text-gray-400">{title}</p>
				<div className="grid grid-cols-2 py-4">
					<p className="text-3xl font-semibold text-white">{count}</p>
					{+percentage > 0 ? (
						<div className="text-[#89EA79] rounded-xl w-[70px] flex items-center justify-center bg-[#89EA79]/20">
							<p className="text-lg">{percentage}%</p>
						</div>
					) : (
						<div className="text-[#EA7979] rounded-xl w-[70px] flex items-center justify-center bg-[#EA7979]/20">
							<p className="text-lg">{percentage}%</p>
						</div>
					)}
				</div>
				<p className="pb-1 text-lg text-gray-400">Current month: {currentMonth}</p>
				<p className="pb-1 text-lg text-gray-400">Last month: {lastMonth}</p>
			</div>
		);
	};

	const columns = [
		{
			name: "Contest Name",
			selector: (row) => row.contestId.tagName,
			sortable: true,

			cell: (row) => (
				<a onClick={() => history.push("/contest/" + row.contestId?._id)}>
					{row.contestId.tagName}
				</a>
			),
		},
		{
			name: "Caption",
			selector: (row) => row.winnerPostId.description,
			sortable: true,
			wrap: true
		},
		{
			name: "Day",
			selector: (row) => new Date(row.winnerPostId.createdAt),
			sortable: true,

			cell: (row) => (
				new Date(row.winnerPostId.createdAt).toDateString()
			)
		},
		{
			name: "Time",
			selector: (row) =>
				new Date(row.winnerPostId.createdAt).toLocaleTimeString(),
			sortable: true,
		},
		{
			name: "User",
			selector: (row) => `${row.winnerPostId.user.firstName} ${row.winnerPostId.user.lastName}`,
			sortable: true,
			cell: (row) => (
				<a
					onClick={() => history.push("/user/" + row.winnerPostId.user?._id)}
				>{`${row.winnerPostId.user.firstName} ${row.winnerPostId.user.lastName}`}</a>
			),
		},
		{
			name: "Media",
			cell: (row) =>
				get_url_extension(row.winnerPostId.media) === "mp4" ||
					get_url_extension(row.winnerPostId.media) === "MP4" ||
					get_url_extension(row.winnerPostId.media) === "mov" ||
					get_url_extension(row.winnerPostId.media) === "MOV" ? (
					<div>
						<BsFillCameraVideoFill
							style={{ height: "25px", width: "25px", margin: "auto" }}
							onClick={() => {
								setVideoURL(row.winnerPostId.media);
							}}
						/>
					</div>
				) : (
					<ModalImage
						small={row.winnerPostId.media_small || row.winnerPostId.media}
						large={row.winnerPostId.media}
						className="w-8 h-8 p-1 rounded-md bg-white/10"
						alt=""
					/>
				),
		},
	];


	CanvasJS.addColorSet("white",
		[
			"#ffffff",
			"#fff344",
			"#fffdfg",
			"#fffdfg",
			"#fffdfg",
		]);

	return (
		<div className="py-8">
			<div className="grid items-center grid-cols-4 gap-x-4">
				<Card
					title="Users (Paying Users)"
					count={data?.users?.total + " (" + data?.payingUser + ")"}
					percentage={calculatePercentage(
						data?.users?.currentMonth,
						data?.users?.lastMonth
					)}
					lastMonth={data?.users?.lastMonth}
					currentMonth={data?.users?.currentMonth}
				/>
				<Card
					title="Super Contest Participants"
					count={data?.superContestParticipated?.total}
					percentage={calculatePercentage(
						data?.superContestParticipated?.currentMonth,
						data?.superContestParticipated?.lastMonth
					)}
					lastMonth={data?.superContestParticipated?.lastMonth}
					currentMonth={data?.superContestParticipated?.currentMonth}

				/>
				<Card
					title="Contest Participants"
					count={data?.contestParticipated?.total}
					percentage={calculatePercentage(
						data?.contestParticipated?.currentMonth,
						data?.contestParticipated?.lastMonth
					)}
					lastMonth={data?.contestParticipated?.lastMonth}
					currentMonth={data?.contestParticipated?.currentMonth}

				/>
				<Card
					title="Winners"
					count={data?.winners?.total}
					percentage={calculatePercentage(
						data?.winners?.currentMonth,
						data?.winners?.lastMonth
					)}
					lastMonth={data?.winners?.lastMonth}
					currentMonth={data?.winners?.currentMonth}

				/>
			</div>
			<div className="grid items-center grid-cols-4 gap-x-4 my-3">
				{
					categoriesData.length > 0 ?
						categoriesData.map(
							item =>
								<Card
									key={item._id}
									title={item['categoryName']}
									count={item['total']}
									percentage={calculatePercentage(
										item['currMonth'],
										item['lastMonth']
									)}
									lastMonth={item['lastMonth']}
									currentMonth={item['currMonth']}
								/>)
						: null
				}
			</div>
			<div className="grid items-center grid-cols-12 py-4 gap-x-4 ">
				<div className="col-span-4 text-white rounded-[30px] card-bg h-[480px]">
					<p className="py-4 text-xl text-center">Revenue Overview</p>
					<PieGraph type={"revenue"} />
				</div>
				<div className="col-span-4 text-white rounded-[30px] card-bg h-[480px]">
					<p className="py-4 text-xl text-center">Device Type Analytics</p>
					<PieGraph type={"deviceType"} />
				</div>
				<div className="col-span-4 text-white rounded-[30px] card-bg h-[480px]">
					<p className="py-4 text-xl text-center">Login Type Analytics</p>
					<PieGraph type={"loginType"} />
				</div>
				{/* <div className="overflow-y-auto vertical-scroll col-span-12 my-5 text-white card-bg h-[480px] rounded-[30px] p-3">
					{
						<CanvasJSChart options={options} containerProps={{ height: "400px", width: '100%' }} />
					}
				</div> */}
				<div className="overflow-y-auto vertical-scroll col-span-12 my-5 text-white card-bg h-[480px] rounded-[30px] p-3">
					{
						<DataTableBase
							progressPending={loading}
							columns={columns}
							data={winners}
							defaultSortFieldId={1}
							title="Winners Listing (24 Hours)"
						/>
					}
				</div>
			</div>
			<Modal
				style={{ top: 20, background: 'transparent', color: '#fff', border: 'none' }}
				centered
				visible={videoURL !== ""}
				onCancel={() => setVideoURL("")}
				footer={null}
				closeIcon={RiCloseCircleLine}
			>
				<video autoPlay={true} controls={true} src={videoURL}></video>
			</Modal>
		</div>
	);
}

export default Home;
