import { Button, Form, Input } from 'antd'
import { useContext, useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { AuthContext } from '../../context/Auth'
import { RightOutlined } from "@ant-design/icons"

const { Item } = Form

function Login() {
	const [form] = Form.useForm()
	const history = useHistory()
	const location = useLocation()
	const auth = useContext(AuthContext)
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => (auth?.token || localStorage.getItem('token')) ? history.push('/') : undefined, [])

	const login = async data => {
		setIsLoading(true)
		auth.signIn(
			{ ...data, role: 'admin', loginType: "local" },
			() => history.replace("/"),
			() => setIsLoading(false)
		)
	}

	return (
		<div style={{ backgroundImage: `url('assets/bg.jpg')` }} className="flex justify-between h-screen bg-fixed bg-cover">
			<div className="w-full h-full m px-6 pb-6 md:pb-12 md:px-12">
				<div className="flex flex-col items-start justify-center w-full h-full px-4 py-12 card-bg md:px-6 lg:pl-12 lg:pr-20 rounded-b-xl">
					<div className='flex items-center space-x-4'>
						<img className="w-20 h-20 p-2 my-4 md:my-12" src="assets/w.svg" alt="wooo" />
					</div>
					<div className="w-full space-y-8 text-white">
						<p className="text-3xl font-bold">Admin Login</p>
						<Form
							initialValues={{ email: undefined, password: undefined }}
							form={form}
							autoComplete="off"
							onFinish={async values => await login(values)}>
							<Item
								name="email"
								rules={[
									{ type: 'email', message: 'Email not valid!' },
									{ required: true, message: 'Email is required.' }
								]}
							>
								<Input
									className='h-10 text-gray-300 bg-transparent border-gray-400'
									size="large"
									placeholder="Email" />
							</Item>
							<br />
							<Item
								name="password"
								rules={[{ required: true, message: ' ' }, { min: 4 }]}
							>
								<Input.Password
									className='h-10 bg-transparent border-gray-400'
									size="large"
									placeholder="Password"
								/>
							</Item>
							<br />
							<div className='flex flex-col items-center justify-between md:space-x-4 md:flex-row'>
								<Button
									block
									loading={isLoading}
									size='large'
									className="flex items-center justify-center w-full h-12 my-4 space-x-6 font-semibold tracking-widest text-white uppercase bg-gray-800 border-gray-800 rounded-md md:w-60 hover:bg-opacity-90 hover:bg-gray-800 hover:text-white hover:border-gray-800 focus:bg-gray-800 focus:text-white focus:border-gray-800"
									htmlType="submit">
									Login
									<RightOutlined className='text-sm' />
								</Button>
							</div>
						</Form>
					</div>
				</div>
			</div>
			<div className="items-end justify-center hidden w-full h-full pt-20 md:flex">
				<div className="px-6 lg:px-20">
					<p className="py-4 text-3xl font-bold text-right text-white ">Woo</p>
					<div className="flex justify-end pb-20">
						<p className="text-right text-white md:w-full lg:w-2/3">
							Intro
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login