import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { fetchPostsByContestId, deletePost } from '../../services/api'
import ModalImage from "react-modal-image";
import DataTableBase from "../../components/common/dataTable";
import { BsFillCameraVideoFill } from "react-icons/bs";
import { BiTrash } from "react-icons/bi";
import { Modal, Switch } from "antd";

import Message from '../../components/common/Message';
import DeleteModal from '../../components/common/DeleteModal';

function ContestDetails() {
	let { id } = useParams()
	const history = useHistory()
	const [posts, setPosts] = useState([])
	const [visibleDelete, setVisibleDelete] = useState(false)
	const [deletedRecordId, setDeletedRecordId] = useState(null)
	const [loading, setLoading] = useState(false);
	const [videoURL, setVideoURL] = useState("");

	const confirmDelete = async () => {
		try {
			const { status } = await deletePost(deletedRecordId)
			status ? Message('Deleted successfully', true) : Message('Unable to delete', false)
			setVisibleDelete(false)
		} catch (error) {
			Message('Unable to delete', false)
			console.log(error)
		}
	}

	const deleteRecord = (id) => {
		setDeletedRecordId(id)
		setVisibleDelete(true)
	}

	useEffect(() => {
		const getPosts = async () => {
			try {
				setLoading(true)
				const { data: { data, status } } = await fetchPostsByContestId(id)
				status && setPosts(data)
				setLoading(false)
			} catch (error) {
				console.log(error)
			}
		}
		getPosts()
	}, [visibleDelete])

	const get_url_extension = (url) => {
		if (url !== undefined) {
			return url.split(/[#?]/)[0].split(".").pop().trim();
		}
		return;
	};

	const columns = [
		{
			name: "Description",
			selector: (row) => row.description,
			sortable: true,

			wrap: true
		},
		{
			name: "Username",
			selector: (row) => row.user.userName,


			cell: (row) => (
				<a onClick={() => history.push("/user/" + row.user?._id)}>
					{row.user.userName}
				</a>
			),
		},
		{
			name: "Likes",
			selector: (row) => row.likes.likesCounter,
			sortable: true,

		},
		{
			name: "Commnets",
			selector: (row) => row.comments.length,
			sortable: true,

		},
		{
			name: "Media",
			cell: (row) =>
				get_url_extension(row.media) == "mp4" ||
					get_url_extension(row.media) == "MP4" ||
					get_url_extension(row.media) == "mov" ||
					get_url_extension(row.media) == "MOV" ? (
					<div>
						<BsFillCameraVideoFill
							style={{ height: "25px", width: "25px", margin: "auto" }}
							onClick={() => {
								setVideoURL(row.media);
							}}
						/>
					</div>
				) : (
					<ModalImage
						small={row.media}
						large={row.media}
						className="w-8 h-8 p-1 rounded-md bg-white/10"
						alt=""
					/>
				),
		},
		{
			name: "Actions",
			center: true,
			selector: (row) => row._id,

			cell: (row) => (
				<a className="underline mx-1" onClick={() => deleteRecord(row?._id)}>
					<div className="flex items-center">
						<BiTrash /> &nbsp;Delete
					</div>
				</a>
			),
		},
	];
	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			<DeleteModal
				title='Contest'
				setVisible={setVisibleDelete}
				confirmDelete={confirmDelete}
				visible={visibleDelete} />
			<div className="flex items-start justify-between pr-4">
				<div>
					<p className="pb-1 text-2xl text-white">Posts</p>
					<p className="pb-4 text-muted">{posts?.length} Posts</p>
				</div>
			</div>
			<div className='flex-1 pb-2 pr-2 space-y-4 overflow-y-auto text-white divide-y divide-gray-500 vertical-scroll'>
				{
					<DataTableBase
						columns={columns}
						data={posts}
						progressPending={loading}
						pagination
					/>
				}
			</div>
			<Modal
				style={{ top: 20 }}
				centered
				visible={videoURL !== ""}
				onCancel={() => setVideoURL("")}
				footer={null}
			>
				<video autoPlay={true} controls={true} src={videoURL}></video>
			</Modal>
		</div>
	)
}

export default ContestDetails
