import { useEffect, useState } from 'react';
import { InboxOutlined, DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { Form, Input, Select, Upload, InputNumber } from 'antd';
import { createContest, fileUpload, fetchStacks, fetchCategories, fetchTalentCategories } from '../../services/api';
import { useHistory } from 'react-router-dom'

import Message from '../../components/common/Message';
import Loader from '../../components/common/Loader';

const { Item } = Form
const { Option } = Select
const { Dragger } = Upload

function NewContest() {
	const history = useHistory()
	const [form] = Form.useForm()
	const [imageUrl, setImageUrl] = useState('')
	const [stacks, setStacks] = useState([])
	const [imageLoading, setImageLoading] = useState(false)
	const [loading, setLoading] = useState(false)
	const [categories, setCategories] = useState([])
	const [talentCategories, setTalentCategories] = useState([])
	const [categoryId, setCategoryId] = useState('')
	const [talent, setTalent] = useState(false)

	const get_url_extension = (url) => {
		return url.split(/[#?]/)[0].split(".").pop().trim();
	};

	const uploadAPICall = async ({ file }) => {
		const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'video/mp4', 'video/MP4', 'video/webp'];
		setImageLoading(true)
		if (validImageTypes.includes(file.type)) {
			const formData = new FormData();
			formData.append('files', file);
			try {
				const { data: { data, status } } = await fileUpload(formData)
				status && Message(`File upload successfully.`, true)
				status && setImageUrl(data[0].downloadHref)
				setImageLoading(false)
			} catch (error) {
				setImageLoading(false)
				console.log('error :>> ', error);
			}
		} else Message(`Only Image or Video file allowed.`, false); setImageLoading(false)
	}

	const props = {
		name: 'file',
		multiple: false,
		headers: {
			authorization: "authorization-text",
			contentType: "multipart/form-data"
		},
		showUploadList: false,
		customRequest: uploadAPICall,
		onChange(info) {
			const { status } = info.file
			status !== 'uploading' && console.log(info.file, info.fileList)
			status === 'done' && Message(`${info.file.name} File upload successfully.`, true)
			status === 'error' && Message(`${info.file.name} file upload failed.`, false)
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files)
		},
	}

	const onFinish = async (values) => {
		try {
			if (imageUrl === '') {
				Message('Please upload media', false)
				setLoading(false)
				return
			}
			if (values.tagName.charAt(0) != '#') {
				Message('Cannot Create a contest without # in start', false)
				setLoading(false)
				return
			}
			setLoading(true)
			values.image = imageUrl
			values.contestType = 'commonContest'
			if (talent) values.category = values.talentCategory
			values.category === '' ? delete values.category : values.category = values.category
			const { status } = await createContest(values)
			status ? Message('Contest created successfully', true) : Message('Unable to create contest', false)
			status && setImageUrl('')
			form.resetFields()
			setLoading(false)
			history.push('/contest')
		} catch (error) {
			Message('Unable to create Contest', false)
			setLoading(false)
			console.log(error)
		}
	}

	useEffect(() => {
		const getStacks = async () => {
			try {
				const { data: { data, status } } = await fetchStacks()
				const { data: { data: categoryData, status: categoryStatus } } = await fetchCategories()
				status && setStacks(data)
				categoryStatus && setCategories(categoryData)
			} catch (error) {
				console.log(error)
			}
		}
		getStacks()
	}, [])

	useEffect(() => {
		const getTalentCategories = async () => {
			try {
				const { data: { data, status } } = await fetchTalentCategories()
				status && setTalentCategories(data)
			} catch (error) {
				console.log(error)
			}
		}
		getTalentCategories()
	}, [])

	const [contest, setContest] = useState("")

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			{
				loading ?
					<div className="absolute top-0 right-0 z-50 flex items-center justify-center w-full h-full">
						<Loader />
					</div>
					:
					<>
						<div>
							<p className="pb-1 text-2xl text-white">Add Contest</p>
						</div>
						<div className='flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted'>
							<Form
								form={form}
								layout="horizontal"
								onFinish={async (val) => await onFinish(val)}
								initialValues={{ tagName: undefined, titleImage: undefined, duration: undefined, contestType: [], stack: [], category: undefined, talentCategory: undefined }}
							>
								<div className='flex items-start justify-between space-x-8'>
									<div className='w-full px-2 text-lg'>

										<Item rules={[{ required: true, message: '' }, { pattern: /^#[a-zA-Z]+$/, message: "Pattern Should Be #ContestName, No Special Characters." }]} name='tagName'>
											<Input placeholder='Name' className='bg-transparent border border-muted text-muted' />
										</Item>

										<Item rules={[{ required: false, message: '' }]} name='titleImage'>
											<Input placeholder='Image Title' className='bg-transparent border border-muted text-muted' />
										</Item>

										{contest !== 'superContest' ?
											<Item name='category'>
												<Select onChange={(val) => {
													setCategoryId(val)
													const selectedCategoryName = categories.find(category => category._id === val)?.categoryName || '';
													if (selectedCategoryName == 'Talents') setTalent(true)
												}}
													className='py-1 bg-transparent border border-muted text-muted'
													bordered={false}
													placeholder="Select Category">
													<Option className="bg-transparent" value=''>Select Category</Option>
													{
														categories.map(
															category =>
																category.categoryName !== 'Influencer' ?
																	<Option className="bg-transparent" key={category._id} value={category._id}>{category.categoryName}</Option>
																	: null
														)
													}
												</Select>
											</Item>
											: null
										}
										{
											talent ? <Item name='talentCategory'>
												<Select
													className='py-1 bg-transparent border border-muted text-muted'
													bordered={false}
													placeholder="Select Talent Category">
													<Option className="bg-transparent" value=''>Select Category</Option>
													{
														talentCategories.map(
															category =>
																category.categoryName !== 'Influencer' ?
																	<Option className="bg-transparent" key={category._id} value={category._id}>{category.title}</Option>
																	: null
														)
													}
												</Select>
											</Item>
												: null
										}
										{
											categoryId !== '' ?
												<Item rules={[{ required: true, message: '' }]} name='duration'>
													<InputNumber placeholder='Duration' className='w-full bg-transparent border border-muted text-muted' />
												</Item> : null
										}
										{
											categoryId === '' ?
												<Item name='stack' rules={[{ required: true, message: '' }]}>
													<Select className='py-1 bg-transparent border border-muted text-muted' bordered={false} placeholder="Select Stack">
														{
															stacks?.map(item => <Option className="bg-transparent" key={item?._id} value={item?._id}>{item?.name}</Option>)
														}
													</Select>
												</Item>
												: null
										}
									</div>
									<div className='w-full'>
										<div className='h-60'>
											{

												imageLoading ? <Loader /> : imageUrl ?
													<div className='space-y-4'>
														{
															get_url_extension(imageUrl) == "mp4" ||
																get_url_extension(imageUrl) == "MP4" ||
																get_url_extension(imageUrl) == "mov" ||
																get_url_extension(imageUrl) == "MOV" ? (
																<div>
																	<video className='w-full pr-6 bg-cover h-60' autoPlay={true} controls={true} src={imageUrl}></video>
																</div>
															) : (
																<img src={imageUrl} className='w-full pr-6 bg-cover h-60' alt="contest" style={{ objectFit: 'contain' }} />
															)
														}
														<p onClick={() => setImageUrl('')} className='inline-flex items-baseline justify-start w-full pr-6 text-lg text-muted cursor-pointer'>
															Remove Image
															<DeleteOutlined className='px-1' style={{ color: '#fff', fontSize: '16px' }} />
														</p>
													</div> :
													<Dragger className='m-2' {...props}>
														<p className="ant-upload-drag-icon"><InboxOutlined style={{ fontSize: '80px', color: '#666' }} /></p>
														<p className="px-2 text-lg text-muted">Click or Drag Contest Image/Video</p>
													</Dragger>
											}
										</div>
									</div>
								</div>
								<div className='flex items-center w-1/2 pl-2 pr-4 space-x-4'>
									<button htmltype='submit' className='w-full py-2 btn-border border-muted'>Create</button>
									<button onClick={() => history.push('/contest')} className='w-full py-2 btn-border text-danger border-danger'>Cancel</button>
								</div>
							</Form>
						</div>
					</>
			}
		</div >
	)
}

export default NewContest
