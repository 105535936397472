import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BsChevronDown } from 'react-icons/bs';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { DownOutlined } from '@ant-design/icons';
import { Form, Input, Select } from 'antd';
import { updateNotification, fetchNotificationById } from '../../services/api';

import Loader from '../../components/common/Loader';
import Message from '../../components/common/Message';

const { Item } = Form
const { Option } = Select
const { TextArea } = Input

function EditNotification() {
	let { id } = useParams()
	const history = useHistory()
	const [form] = Form.useForm()
	const [loading, setLoading] = useState(false)

	const onFinish = async (values) => {
		try {
			setLoading(true)
			const { status } = await updateNotification(values, id)
			status ? Message('Notification updated successfully', true) : Message('Unable to updated Notification', false)
			setLoading(false)
			history.push('/notification-message')
		} catch (error) {
			Message('Unable to update Notification', false)
			setLoading(false)
			console.log(error)
		}
	}

	useEffect(() => {
		const getNotificationById = async () => {
			try {
				const { data: { data, status } } = await fetchNotificationById(id)
				if (status) {
					form.setFieldsValue({ type: data?.type, msg: data?.msg })
				}
			} catch (error) {
				console.log(error)
			}
		}

		getNotificationById()
	}, [])

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			{
				loading ?
					<div className="absolute top-0 right-0 z-50 flex items-center justify-center w-full h-full">
						<Loader />
					</div>
					:
					<>
						<div>
							<p className="pb-1 text-2xl text-white">Edit Notifications</p>
						</div>
						<div className='flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted'>
							<Form
								form={form}
								onFinish={async val => await onFinish(val)}
							>
								<div className='flex items-start justify-between space-x-8'>
									<div className='w-full px-2 text-lg'>
										<Item rules={[{ required: true, message: '' }]} name='type'>
											<Input disabled={true} placeholder='Type' className='bg-transparent border border-muted text-muted' />
										</Item>
										<Item rules={[{ required: true, message: '' }]} name='msg'>
											<Input placeholder='Message' className='bg-transparent border border-muted text-muted' />
										</Item>
									</div>
									<div className='w-full'>
									</div>
								</div>
								<div className='flex items-center w-1/2 pl-2 pr-4 space-x-4'>
									<button htmltype='submit' className='w-full py-2 btn-border border-muted'>Update</button>
									<button onClick={() => history.push('/notification-message')} className='w-full py-2 btn-border text-danger border-danger'>Cancel</button>
								</div>
							</Form>
						</div>
					</>
			}
		</div>
	)
}

export default EditNotification
