import { useEffect, useState, useMemo } from "react";
import ModalImage from "react-modal-image";
import { useHistory } from "react-router-dom";
import { RiSearch2Line } from "react-icons/ri";
import { FaUserAlt } from "react-icons/fa";
import { fetchUsers } from "../../services/api";
import DataTableBase from "../../components/common/dataTable";
import { RiCloseCircleLine } from "react-icons/ri";
import { Form, Modal, Input } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { broadcastNotification } from "../../services/api";

import Message from "../../components/common/Message";
import Loader from "../../components/common/Loader";

const { TextArea } = Input;
const { Item } = Form;

function BroadcastNotification() {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState("");
	const [isDeleted, setIsDeleted] = useState(false);
	const [data, setData] = useState([]);
	const [totalRows, setTotalRows] = useState(0);
	const [limit, setLimit] = useState(10);
	const [currPage, setCurrPage] = useState(1);
	const [modal, setModal] = useState(false);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [form] = Form.useForm();

	useEffect(() => {
		const getUsers = async () => {
			setLoading(true);
			const {
				data: { data, status },
			} = await fetchUsers(search, currPage, limit);
			status && setData(data.users);
			status && setTotalRows(data.totalCount);
			setLoading(false);
		};
		getUsers();
	}, [currPage, limit, isDeleted, search]);

	useEffect(() => {
		setTotalRows(filteredUsers.length);
	}, [search]);

	const filteredUsers = data.filter((user) => {
		return (
			user?.firstName?.toLowerCase().includes(search?.toLowerCase()) ||
			user?.email?.toLowerCase().includes(search?.toLowerCase()) ||
			user?.lastName?.toLowerCase().includes(search?.toLowerCase()) ||
			user?.deviceType?.toLowerCase().includes(search?.toLowerCase())
		);
	});

	const onFinish = async (values) => {
		try {
			setLoading(true);
			let payload = values.message;
			let tokens = [];
			selectedUsers.map((user) => {
				tokens.push(user.fcmToken);
			});
			let data = {
				tokens,
				payload,
			};
			const { status } = await broadcastNotification(data);
			status
				? Message("Notification broadcast successfully", true)
				: Message("Unable to broadcast Notification", false);
			form.resetFields();
			setSelectedUsers([]);
			setModal(false);
			setLoading(false);
		} catch (error) {
			Message("Unable to broadcast Notification", false);
			setLoading(false);
			console.log(error);
		}
	};

	const removeUser = (id) => {
		let filtered = selectedUsers.filter((user) => {
			return user._id !== id;
		});
		setSelectedUsers(filtered);
	};

	const addUserToList = (row, event) => {
		const found = selectedUsers.find((obj) => {
			return obj._id === row?._id;
		});
		if (!found || selectedUsers.length == 0) {
			console.log('FCM', row.fcmToken)
			if ((row.fcmToken != null && row.fcmToken != "") && (row.notificationEnabled || row.notificationEnabled === undefined)) {
				setSelectedUsers([...selectedUsers, row]);
			} else alert("User is not logged in");
		} else alert("Alreday Added");
	};

	const handleSelectAll = async () => {
		try {
			setLoading(true);
			const {
				data: { data, status },
			} = await fetchUsers("", 1, totalRows);

			let filtered = data.users;
			filtered = filtered.filter((user) => {

				if ((user.fcmToken != null && user.fcmToken != "") && (user.notificationEnabled))
					return user.fcmToken
			});
			status && setSelectedUsers(filtered);
			setLoading(false);
		} catch (err) {
			console.log("ERROR", err);
		}
	};

	// Toggle the state so React Data Table changes to clearSelectedRows are triggered

	const columns = [
		{
			name: "Name",
			selector: (row) => `${row.firstName} ${row.lastName}` || "N/A",
			sortable: true,

			cell: (row) => (
				<a onClick={() => history.push("/user/" + row?._id)}>
					{`${row?.firstName} ${row?.lastName}`}
				</a>
			),

		},
		{
			name: "Username",
			selector: (row) => row.userName,
			sortable: true,

		},
		{
			name: "Device Type",
			selector: (row) => row.deviceType || "N/A",
			sortable: true,

		},
		{
			name: "Number",
			selector: (row) => row.phoneNumber || "N/A",

		},
		{
			name: "Email",
			selector: (row) => row.email,
			sortable: true,

		},
		{
			name: "Profile Picture",
			cell: (row) =>
				row.image !== "" ? (
					<ModalImage
						small={row.image}
						large={row.image}
						className="w-8 h-8 p-1 rounded-md bg-white/10"
						alt=""
					/>
				) : (
					<FaUserAlt style={{ fontSize: "18px" }} />
				),

		},
	];

	// const conditionalRowStyles = (row) => ;

	const conditionalRowStyles = [
		{
			when: (row) => row.fcmToken == "" || undefined,
			style: {
				backgroundColor: "#00000033",
			},
		},
		// You can also pass a callback to style for additional customization
		{
			when: (row) => row.fcmToken !== "" || undefined,
			style: {
				cursor: "pointer",
			},
		},
	];

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			<>
				<div>
					<p className="text-white pb-1 text-2xl">Broadcast Notification</p>
				</div>
				<div className="flex w-80 items-center bg-[#111111] rounded-full">
					<RiSearch2Line className="w-5 h-5 ml-2 mr-1 text-gray-400" />
					<input
						type="text"
						className="rounded-full text-gray-400 w-96 focus:outline-none focus:border-none focus:ring-0 bg-[#111111]/30  border-none"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						placeholder="Search"
					/>
				</div>
				<div className="flex justify-between overflow-y-auto vertical-scroll">
					<div className="w-1/2 flex-1 text-white py-6 space-y-4 overflow-y-auto vertical-scroll divide-muted pr-2 divide-y">
						<div className="flex justify-between">
							<p className="text-white pb-1 text-2xl text-center">
								List Of Users
							</p>
							<div>
								<button className="btn-border mx-1" onClick={handleSelectAll}>
									Select All
								</button>
							</div>
						</div>
						<div className="overflow-y-auto vertical-scroll">
							<DataTableBase
								columns={columns}
								data={filteredUsers}
								progressPending={loading}
								pagination
								paginationServer
								paginationTotalRows={totalRows}
								onChangeRowsPerPage={(newLimit, page) => {
									setLimit(newLimit);
									setCurrPage(page);
								}}
								onChangePage={(page) => setCurrPage(page)}
								onRowClicked={(row, event) => {
									addUserToList(row, event);
								}}
								conditionalRowStyles={conditionalRowStyles}
							/>
						</div>
					</div>
					<div className="w-1/2 flex-1 text-white py-6 space-y-4 overflow-y-auto vertical-scroll divide-muted pr-2 divide-y">
						<div className="flex justify-between">
							<p className="text-white pb-1 text-2xl text-center">
								Selected Users
							</p>
							{selectedUsers.length > 0 ? (
								<div>
									<button
										className="btn-border mx-1"
										onClick={() => setModal(true)}
									>
										Send Notification
									</button>
									<button
										className="btn-border mx-1"
										onClick={() => setSelectedUsers([])}
									>
										Remove All
									</button>
								</div>
							) : null}
						</div>
						<div className="flex flex-wrap">
							{loading ? (
								<div className="absolute top-0 right-0 z-50 flex items-center justify-center w-full h-full">
									<Loader />
								</div>
							) : (
								selectedUsers?.map((item) => (
									<div key={item._id} className="m-1">
										<span className="px-5 py-2 rounded-full text-gray-500 bg-gray-200 font-semibold text-sm flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease">
											<p className="px-2">{item.userName}</p>
											<img
												src={item?.image}
												alt=""
												style={{
													height: "24px",
													width: "24px",
													borderRadius: "50%",
												}}
												className="mx-2"
												onError={(e) => {
													e.target.onerror = null;
													e.target.src = "/assets/sidebar/user.svg";
												}}
											/>
											<button
												className="bg-transparent hover focus:outline-none"
												onClick={() => removeUser(item._id)}
											>
												<AiOutlineClose />
											</button>
										</span>
									</div>
								))
							)}
						</div>
					</div>
				</div>
				<Modal
					style={{
						top: 20,
						background: "#fff	",
						border: "none",
					}}
					centered
					open={modal}
					onCancel={() => setModal(false)}
					footer={null}
					closeIcon={RiCloseCircleLine}
				>
					<Form
						form={form}
						layout="horizontal"
						onFinish={async (val) => await onFinish(val)}
						initialValues={{ message: undefined }}
					>
						<div className="w-full">
							<div className="text-lg w-full px-2">
								<Item
									name="message"
									rules={[{ required: true, message: "Missing Message" }]}
								>
									<TextArea
										rows={6}
										className="border py-1 w-full border-muted text-muted bg-transparent"
										placeholder="Message"
									/>
								</Item>
							</div>
						</div>
						<div className="flex justify-center items-center flex-row">
							<Item className="w-1/2 m-2">
								<button htmltype="submit" className=" w-full py-2 m-2 border">
									Broadcast
								</button>
							</Item>
						</div>
					</Form>
				</Modal>
			</>
		</div>
	);
}

export default BroadcastNotification;
