/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { deleteLevelRange, fetchLevelsRange } from '../../services/api'
import { useHistory } from 'react-router-dom'
import DataTableBase from "../../components/common/dataTable";
import { BiEdit } from "react-icons/bi";
import { BiTrash } from "react-icons/bi";
import { Modal, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';


import Message from '../../components/common/Message';
import DeleteModal from '../../components/common/DeleteModal';
const { Dragger } = Upload;

function Level() {
	const history = useHistory()
	const [levels, setLevels] = useState([])
	const [totalRows, setTotalRows] = useState(0);
	const [loading, setLoading] = useState(false)
	const [limit, setLimit] = useState(10);
	const [currPage, setCurrPage] = useState(1);
	const [deletedRecordId, setDeletedRecordId] = useState(null)
	const [visibleDelete, setVisibleDelete] = useState(false)
	const [visibleFileUpload, setVisibleFileUpload] = useState(false);
	const [fileLoading, setFileLoading] = useState(false);


	useEffect(() => {
		const getLevels = async () => {
			try {
				setLoading(true)
				const { data: { data, status } } = await fetchLevelsRange(currPage, limit)
				status && setLevels(data.levels)
				status && setTotalRows(data.totalLevels)
				setLoading(false)
			} catch (error) {
				setLoading(false)
				console.log(error)
			}
		}
		getLevels()
	}, [currPage, limit, visibleDelete])


	const uploadAPICall = async ({ file }) => {
		console.log(file.type)
		// const validImageTypes = [''];
		// setFileLoading(true)
		// if (validImageTypes.includes(file.type)) {
		// 	const formData = new FormData();
		// 	formData.append('files', file);
		// try {
		// 	const { data: { data, status } } = await fileUpload(formData)
		// 	status && Message(`File upload successfully.`, true)
		// 	status && setImageUrl(data[0].downloadHref)
		// 	setImageLoading(false)
		// } catch (error) {
		// 	setImageLoading(false)
		// 	console.log('error :>> ', error);
		// }
		// } else Message(`Only Image or Video file allowed.`, false); setImageLoading(false)
	}


	const props = {
		name: 'file',
		multiple: false,
		headers: {
			authorization: "authorization-text",
			contentType: "multipart/form-data"
		},
		showUploadList: false,
		customRequest: uploadAPICall,
		onChange(info) {
			const { status } = info.file
			status !== 'uploading' && console.log(info.file, info.fileList)
			status === 'done' && Message(`${info.file.name} File upload successfully.`, true)
			status === 'error' && Message(`${info.file.name} file upload failed.`, false)
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files)
		},
	}

	const confirmDelete = async () => {
		try {
			const { status } = await deleteLevelRange(deletedRecordId);
			status
				? Message("Deleted successfully", true)
				: Message("Unable to delete", false);
			setVisibleDelete(false);
		} catch (error) {
			Message("Unable to delete", false);
			console.log(error);
		}
	};

	const deleteRecord = (id) => {
		setDeletedRecordId(id)
		setVisibleDelete(true)
	}

	const columns = [
		{
			name: "Level Name",
			selector: (row) => row.levelName,
			sortable: true,

		},
		{
			name: "Level Limit",
			selector: (row) => row.levelLimit,
			sortable: true,

		},
		{
			name: "XP Limit",
			selector: (row) => row.xpLimit,
			sortable: true,

		},
		{
			name: "Influencer Level",
			selector: (row) => row.influencerLevel,
			sortable: true,

		},
		{
			name: "Category",
			selector: (row) => row.category,
			sortable: true,

		},
		{
			name: "Entry Fee USD",
			selector: (row) => row.entryFeeUSD?.$numberDecimal || row.entryFeeUSD || 'N/A',
			sortable: true,
		},
		{
			name: "Entry Fee Coins",
			selector: (row) => row.entryFeeCoins,
			sortable: true,
		},
		{
			name: "Actions",
			center: true,
			cell: (row) => (
				<div className="flex ">
					<a className=" mx-1" onClick={() => history.push("/level-range/edit/" + row?._id)}>
						<div className="flex items-center underline">
							<BiEdit /> &nbsp;Edit
						</div>
					</a>
					<a className="mx-1" onClick={() => deleteRecord(row?._id)}>
						<div className="flex items-center underline">
							<BiTrash /> &nbsp;Delete
						</div>
					</a>
				</div>
			),
		},
	];

	return (

		< div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]" >
			<div className="flex flex-col space-y-2">
				<div className="flex items-center justify-between pr-20">
					<div className='my-2'>
						<p className="pb-1 text-4xl text-white">Level Range</p>
						<p className="text-muted">{totalRows} Level Range</p>
					</div>
					<div>
						<button onClick={() => history.push('/level-range/new')} className="btn-border mx-2">Add Level Range</button>
						<button onClick={() => { setVisibleFileUpload(true) }} className="btn-border mx-2">File Upload</button>
					</div>
				</div>
			</div>

			<div className='flex-1 pb-4 space-y-4 overflow-y-auto text-white divide-y divide-gray-500 vertical-scroll'>
				{
					<DataTableBase
						columns={columns}
						data={levels}
						progressPending={loading}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={(newLimit, page) => {
							setLimit(newLimit);
							setCurrPage(page);
						}}
						onChangePage={(page) => setCurrPage(page)}
						paginationComponentOptions={{
							rowsPerPageText: "Items per Page : ",
							rangeSeparatorText: "-",
							selectAllRowsItem: true,
							selectAllRowsItemText: "All",
						}}
					/>
				}
			</div>
			<DeleteModal
				title="Contest"
				setVisible={setVisibleDelete}
				confirmDelete={confirmDelete}
				visible={visibleDelete}
			/>

			<Modal
				title={``}
				centered
				visible={visibleFileUpload}
				onCancel={() => setVisibleFileUpload(false)}
				footer={null}
				width={500}
			>
				<Dragger {...props} style={{ backgroundColor: "#fff !important" }}>
					<p className="ant-upload-drag-icon"><InboxOutlined style={{ fontSize: '80px', color: '#fff' }} /></p>
					<p className="px-2 text-lg text-white">Click or drag file to this area to upload</p>
				</Dragger>
			</Modal>
		</div >
	)
}

export default Level
