import { useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { Form, Input } from "antd";
import { createReward } from "../../services/api";

import Message from "../../components/common/Message";
import Loader from "../../components/common/Loader";

const { Item } = Form;

function NewReward() {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const onFinish = async (values) => {
		try {
			setLoading(true);
			const { status } = await createReward(values);
			status
				? Message("Reward created successfully", true)
				: Message("Unable to create Reward", false);
			form.resetFields();
			setLoading(false);
		} catch (error) {
			Message("Unable to create Reward", false);
			setLoading(false);
			console.log(error);
		}
	};

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			{loading ? (
				<div className="absolute top-0 right-0 z-50 flex items-center justify-center w-full h-full">
					<Loader />
				</div>
			) : (
				<>
					<div>
						<p className="pb-1 text-2xl text-white capitalize">Add Reward</p>
					</div>
					<div className="flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted">
						<Form
							form={form}
							layout="horizontal"
							onFinish={async (val) => await onFinish(val)}
							initialValues={{
								title: undefined,
								normalXp: undefined,
								winnerXp: undefined,
							}}
						>
							<div className="flex items-start justify-between space-x-8">
								<div className="w-full px-2 text-lg">
									<Item
										rules={[{ required: true, message: "Title is required" }]}
										name="title"
									>
										<Input
											placeholder="Title"
											className="bg-transparent border border-muted text-muted"
										/>
									</Item>
									<Item
										rules={[
											{ required: true, message: "Normal XP is required" },
										]}
										name="normalXp"
									>
										<Input
											placeholder="Normal XPs"
											className="bg-transparent border border-muted text-muted"
										/>
									</Item>
									<Item
										rules={[
											{ required: true, message: "Winner XP is required" },
										]}
										name="winnerXp"
									>
										<Input
											placeholder="Winner XPs"
											className="bg-transparent border border-muted text-muted"
										/>
									</Item>
								</div>
								<div className="w-full"></div>
							</div>
							<div className="w-1/2 pl-2 pr-4">
								<Item>
									<button
										htmltype="submit"
										className="w-full py-2 btn-border border-muted"
									>
										Create
									</button>
								</Item>
							</div>
						</Form>
					</div>
				</>
			)}
		</div>
	);
}

export default NewReward;
