import axios from "axios"

import {
	LOGIN,
	STACK,
	CONTEST,
	SUPERCONTEST,
	USER,
	REWARD,
	LEVEL,
	NOTIFICATIONMESSAGE,
	WINNER,
	SUPERCONTESTCHILD,
	POST,
	ADMIN,
	COMMENT,
	REPORT,
	CATEGORY,
	LEVELRANGE
} from "../constants"

const { REACT_APP_AXIOS_RETRY, REACT_APP_API_PREFIX, REACT_APP_STACK_API_PREFIX, REACT_APP_PAYMENT_API } = process.env

axios.defaults.headers.common = {
	'Authorization': `Bearer ${localStorage.getItem('token')}`
}

const AXIOS_RETRY = REACT_APP_AXIOS_RETRY,
	USER_API = REACT_APP_API_PREFIX,
	STACK_API = REACT_APP_STACK_API_PREFIX,
	PAYMENT_API = REACT_APP_PAYMENT_API

const retry = {
	[AXIOS_RETRY]: { retries: 2 },
	errorHandling: { global: true },
}

export const login = data => axios.post(`${USER_API}${LOGIN} `, data, retry);
export const fetchStacks = () => axios.get(`${STACK_API}${ADMIN}/${STACK}`, retry);
export const fetchStackById = id => axios.get(`${STACK_API}${ADMIN}/${STACK}/${id}`, retry);
export const createStack = data => axios.post(`${STACK_API}${ADMIN}/${STACK}`, data, retry);
export const updateStack = (data, id) => axios.put(`${STACK_API}${ADMIN}/${STACK}/${id}`, data, retry);
export const deleteStack = id => axios.delete(`${STACK_API}${ADMIN}/${STACK}/${id}`, retry);
export const fetchContests = (page, limit) => axios.get(`${STACK_API}${CONTEST}?page=${page}&limit=${limit}`, retry);
export const fetchSuperContests = () => axios.get(`${STACK_API}${SUPERCONTEST}`, retry);
export const fetchContestByCategoryId = (id, page, limit) => axios.get(`${STACK_API}${ADMIN}/${CONTEST}/${CATEGORY}/${id}?page=${page}&limit=${limit}`, retry);
export const fetchContestById = id => axios.get(`${STACK_API}${ADMIN}/${CONTEST}/${id}`, retry);
export const fetchSuperContestById = id => axios.get(`${STACK_API}${SUPERCONTEST}/${id}`, retry);
export const fetchContestsByStackId = id => axios.get(`${STACK_API}${CONTEST}/${STACK}/${id}`, retry);
export const createContest = data => axios.post(`${STACK_API}${ADMIN}/${CONTEST}`, data, retry);
export const createSuperContest = data => axios.post(`${STACK_API}${SUPERCONTEST}`, data, retry);
export const updateContest = (data, id) => axios.put(`${STACK_API}${CONTEST}/${id}`, data, retry);
export const updateSuperContest = (data, id) => axios.put(`${STACK_API}${SUPERCONTEST}/${id}`, data, retry);
export const deleteContest = id => axios.delete(`${STACK_API}${CONTEST}/${id}`, retry);
export const fetchCategories = () => axios.get(`${STACK_API}${CATEGORY}`, retry)
export const fetchContestCountForCategory = () => axios.get(`${STACK_API}${CONTEST}/stats`, retry)
export const fetchSuperContestChilds = () => axios.get(`${STACK_API}${ADMIN}/${SUPERCONTESTCHILD}`, retry);
export const fetchSuperContestChildById = id => axios.get(`${STACK_API}${SUPERCONTESTCHILD}/${id}`, retry);
export const createSuperContestChild = data => axios.post(`${STACK_API}${SUPERCONTESTCHILD}`, data, retry);
export const updateSuperContestChild = (data, id) => axios.put(`${STACK_API}${SUPERCONTESTCHILD}/${id}`, data, retry);
export const deleteSuperContestChild = id => axios.delete(`${STACK_API}${SUPERCONTESTCHILD}/${id}`, retry);
export const fetchUsers = (search, page, limit) => axios.get(`${USER_API}${ADMIN}/search/${USER}?search=${search}&page=${page}&limit=${limit}`, retry); // /search/user?search=3&limit=3&page=3 // search, page, limit
export const fetchUserById = id => axios.get(`${USER_API}${USER}/${id}`, retry);
export const updateUser = (data, id) => axios.put(`${USER_API}${USER}/${id}`, data, retry);
export const fetchInfluencers = (search, page, limit) => axios.get(`${USER_API}${ADMIN}/influencer/?search=${search}&page=${page}&limit=${limit}`, retry);
export const fetchRewards = (page, limit) => axios.get(`${USER_API}${ADMIN}/${REWARD}?limit=${limit}&page=${page}`, retry);
export const createReward = data => axios.post(`${USER_API}${ADMIN}/${REWARD}`, data, retry);
export const fetchRewardById = id => axios.get(`${USER_API}${ADMIN}/${REWARD}/${id}`, retry);
export const updateReward = (data, id) => axios.put(`${USER_API}${ADMIN}/${REWARD}/${id}`, data, retry);
export const deleteReward = id => axios.delete(`${USER_API}${ADMIN}/${REWARD}/${id}`, retry);
export const fetchPostsByUserId = id => axios.get(`${USER_API}${USER}/${POST}s/${id}`, retry);
export const fetchUsersByReferralCode = query => axios.get(`${USER_API}${ADMIN}/referral-users?query=${query}`)


export const fetchPostById = id => axios.get(`${STACK_API}${POST}/${ADMIN}/${id}`, retry);
export const fetchPostsByContestId = id => axios.get(`${STACK_API}${CONTEST}/${POST}/all/${id}`, retry);
export const deletePost = id => axios.delete(`${STACK_API}${POST}/${id}`, retry);
export const deleteSuperPost = id => axios.delete(`${STACK_API}super-contst/${POST}/${id}`, retry);


export const fetchLevels = (page, limit) => axios.get(`${USER_API}${LEVEL}?limit=${limit}&page=${page}`, retry);
export const createLevel = data => axios.post(`${USER_API}${ADMIN}/${LEVEL}`, data, retry);
export const fetchLevelById = id => axios.get(`${USER_API}${ADMIN}/${LEVEL}/${id}`, retry);
export const updateLevel = (data, id) => axios.put(`${USER_API}${ADMIN}/${LEVEL}/${id}`, data, retry);
export const deleteLevel = id => axios.delete(`${USER_API}${ADMIN}/${LEVEL}/${id}`, retry);

export const fetchLevelsRange = (page, limit) => axios.get(`${USER_API}${ADMIN}/${LEVELRANGE}?limit=${limit}&page=${page}`, retry);
export const createLevelRange = data => axios.post(`${USER_API}${ADMIN}/${LEVELRANGE}`, data, retry);
export const fetchLevelRangeById = id => axios.get(`${USER_API}${ADMIN}/${LEVELRANGE}/${id}`, retry);
export const updateLevelRange = (data, id) => axios.put(`${USER_API}${ADMIN}/${LEVELRANGE}/${id}`, data, retry);
export const deleteLevelRange = id => axios.delete(`${USER_API}${ADMIN}/${LEVELRANGE}/${id}`, retry);

export const fetchNotifications = () => axios.get(`${USER_API}${ADMIN}/${NOTIFICATIONMESSAGE}`, retry); //`${USER_API}${NOTIFICATIONMESSAGE}`
export const fetchNotificationById = id => axios.get(`${USER_API}${ADMIN}/${NOTIFICATIONMESSAGE}/${id}`, retry);
export const updateNotification = (data, id) => axios.put(`${USER_API}${ADMIN}/${NOTIFICATIONMESSAGE}/${id}`, data, retry);
export const broadcastNotification = (data) => axios.post(`${USER_API}${ADMIN}/notification-bulk`, data, retry);


export const scheduleNotification = () => axios.get(`${USER_API}${ADMIN}/notification-schedule`, retry);
export const scheduleNotificationById = id => axios.get(`${USER_API}${ADMIN}/notification-schedule/${id}`, retry);
export const scheduleNotificationUpdate = (data, id) => axios.put(`${USER_API}${ADMIN}/notification-schedule/${id}`, data, retry);
export const scheduleNotificationCreate = data => axios.post(`${USER_API}${ADMIN}/notification-schedule`, data, retry);
export const scheduleNotificationDelete = id => axios.delete(`${USER_API}${ADMIN}/notification-schedule/${id}`, retry);


export const fetchWinners = (minTime, maxTime) => axios.get(`${STACK_API}${ADMIN}/${WINNER}/?minTime=${minTime}&maxTime=${maxTime}`, retry);
// export const createWinner = data => axios.post(`${USER_API}${WINNER}`, data, retry);
// export const fetchWinnerById = id => axios.get(`${USER_API}${WINNER}/${id}`, retry);
// export const updateWinner = (data, id) => axios.put(`${USER_API}${WINNER}/${id}`, data, retry);
// export const deleteWinner = id => axios.delete(`${USER_API}${WINNER}/${id}`, retry);


export const fetchReportedPosts = () => axios.get(`${USER_API}${ADMIN}/${REPORT}/${POST}`, retry);
export const updateReportedPost = (id, data) => axios.put(`${USER_API}${ADMIN}/${REPORT}/${POST}/${id}`, data, retry);
export const fetchReportedUsers = () => axios.get(`${USER_API}${ADMIN}/${REPORT}/${USER}`, retry);
export const updateReportedUser = (id, data) => axios.put(`${USER_API}${ADMIN}/${REPORT}/${USER}/${id}`, data, retry);


export const fetchAdminDashboard = _ => axios.get(`${USER_API}${ADMIN}/dashboard`, retry);
export const fetchAdminDashboardCategory = _ => axios.get(`${USER_API}${ADMIN}/dashboard/category`, retry);
export const fetchAdminDashboardStats = _ => axios.get(`${USER_API}${ADMIN}/dashboard/stats`, retry);
export const fetchDeviceTypeGraph = _ => axios.get(`${USER_API}${ADMIN}/device-type`, retry);
export const fetchLoginTypeGraph = _ => axios.get(`${USER_API}${ADMIN}/login-type`, retry);
export const fetchAdminUserDetails = (id) => axios.get(`${USER_API}${ADMIN}/${USER}/${id}`, retry);
export const fetchAdminContestParticipated = (id, limit, page) => axios.get(`${USER_API}${ADMIN}/${CONTEST}/${id}?limit=${limit}&page=${page}`, retry);
export const fetchAdminSuperContestParticipated = (id, limit, page) => axios.get(`${USER_API}${ADMIN}/super-contest/${id}?limit=${limit}&page=${page}`, retry);
export const fetchAdminComments = (id, limit, page) => axios.get(`${USER_API}${ADMIN}/${COMMENT}s/${id}?limit=${limit}&page=${page}`, retry);
export const fetchAdminPosts = (id, limit, page, type) => axios.get(`${USER_API}${ADMIN}/${POST}s/${id}?limit=${limit}&page=${page}&type=${type}`, retry);
export const fetchAdminFans = (id, limit, page) => axios.get(`${USER_API}${ADMIN}/fans/${id}?limit=${limit}&page=${page}`, retry);
export const fetchAdminFollowings = (id, limit, page) => axios.get(`${USER_API}${ADMIN}/followings/${id}?limit=${limit}&page=${page}`, retry);
export const fetchUserReport = (startTime, endTime) => axios.get(`${USER_API}${ADMIN}/report/user-report?minTime=${startTime}&maxTime=${endTime}`, retry)

export const fetchuserActionChart = (actions) => axios.post(`${USER_API}user-action-graph`, actions, retry); // ${USER_API}user-action-graph
export const LevelProgressionGraph = () => axios.get(`${USER_API}${ADMIN}/level-progression-graph`, retry); // ${USER_API}user-action-graph
export const fetchUserLocationClusters = () => axios.get(`${USER_API}${ADMIN}/user-location-clusters`, retry); // ${USER_API}user-action-graph
export const fetchUserLocationTable = (label) => axios.get(`${USER_API}${ADMIN}/user-location-table/${label}`, retry);

export const fetchTalentCategories = () => axios.get(`${STACK_API}talent-category/`, retry);
export const fetchTalentCategoryById = (id) => axios.get(`${STACK_API}admin/talent-category/${id}`, retry);
export const createTalentCategoryById = (data) => axios.post(`${STACK_API}admin/talent-category/`, data, retry);
export const updateTalentCategoryById = (data, id) => axios.put(`${STACK_API}admin/talent-category/${id}`, data, retry);
export const deleteTalentCategories = (id) => axios.delete(`${STACK_API}admin/talent-category/${id}`, retry);
export const getContestsByCategoryId = (id) => axios.get(`${STACK_API}talent-contest/category/${id}`, retry)
export const fetchContestsActivity = (startTime, endTime) => axios.get(`${STACK_API}admin/contest-activity?minTime=${startTime}&maxTime=${endTime}`, retry)

export const fetchWithdrawRequests = () => axios.get(`${PAYMENT_API}${ADMIN}/payouts`, retry);
export const fetchWithdrawRequestById = (id) => axios.get(`${PAYMENT_API}${ADMIN}/payouts/${id}`, retry);
export const actionWithdraw = (data) => axios.post(`${PAYMENT_API}${ADMIN}/payout`, data, retry);
export const fileUpload = body => axios.post(`${USER_API}files`, body, retry);
export const sendEmailApi = body => axios.delete(`${USER_API}delete-user-web`, {
	data: body,
	retry
});
export const verifyOtpApi = body => axios.post(`${USER_API}delete-user-web/otp`, body, retry);
