import { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import { useParams } from 'react-router-dom';
import { fetchAdminComments, fetchAdminContestParticipated, fetchAdminFans, fetchAdminFollowings, fetchAdminPosts, fetchAdminSuperContestParticipated, fetchAdminUserDetails } from '../../services/api'
import { AiOutlineHeart } from 'react-icons/ai';
import { FaRegComment } from 'react-icons/fa';
import { BsChevronDown } from 'react-icons/bs';

import UserModal from '../../components/common/UserModal';
import Loader from '../../components/common/Loader';
import moment from 'moment';

const { Panel } = Collapse

function UserDetails() {
	let { id } = useParams()
	const [user, setUser] = useState({})
	const [loading, setLoading] = useState(false)
	const [counts, setCounts] = useState({})
	const [contestPage, setContestPage] = useState(0)
	const [superContestPage, setSuperContestPage] = useState(0)
	const [postPage, setPostPage] = useState(1)
	const [superPostPage, setSuperPostPage] = useState(1)
	const [fansPage, setFansPage] = useState(0)
	const [followingsPage, setFollowingsPage] = useState(0)
	const [commentsPage, setCommentsPage] = useState(0)
	const [currentPost, setCurrentPost] = useState({})
	const [fans, setFans] = useState([])
	const [galleryDisplay, setGalleryDisplay] = useState(true)
	const [gallerySuperDisplay, setGallerySuperDisplay] = useState(true)
	const [comments, setComments] = useState([])
	const [followings, setFollowings] = useState([])
	const [posts, setPosts] = useState([])
	const [superPosts, setSuperPosts] = useState([])
	const [contestParticipated, setcontestParticipated] = useState([])
	const [superContestParticipated, setSupercontestParticipated] = useState([])
	const [visible, setVisible] = useState(false)

	const getDisplayPost = () => (
		<div onClick={(event) => { event.stopPropagation(); setGalleryDisplay(!galleryDisplay) }} className="flex items-center space-x-1 btn-border border-muted text-muted">
			<p>{galleryDisplay ? 'Gallery View' : 'List View'}</p>
			<BsChevronDown />
		</div>
	)

	const getDisplaySuperPost = () => (
		<div onClick={(event) => { event.stopPropagation(); setGallerySuperDisplay(!gallerySuperDisplay) }} className="flex items-center space-x-1 btn-border border-muted text-muted">
			<p>{gallerySuperDisplay ? 'Gallery View' : 'List View'}</p>
			<BsChevronDown />
		</div>
	)

	useEffect(() => {
		const getUser = async () => {
			try {
				setLoading(true)
				const { data: { data, status } } = await fetchAdminUserDetails(id)
				if (status) {
					setUser(data.user)
					setcontestParticipated(data.contest)
					setSupercontestParticipated(data.superContest)
					setComments(data.comments)
					setFans(data.fans)
					setPosts(data.posts)
					setSuperPosts(data.superPosts)
					setFollowings(data.followings)
					setCounts(data.count)
				}
				setLoading(false)
			} catch (error) {
				console.log(error)
			}
		}
		getUser()
	}, [])

	useEffect(() => {
		const getContestParticipation = async () => {
			try {
				const { data: { data, status } } = await fetchAdminContestParticipated(id, 3, contestPage)
				status && setcontestParticipated(prev => [...prev, ...data])
			} catch (error) {
				console.log(error)
			}
		}

		if (contestPage) getContestParticipation()

	}, [contestPage])

	useEffect(() => {
		const getSuperContestParticipation = async () => {
			try {
				const { data: { data, status } } = await fetchAdminSuperContestParticipated(id, 3, superContestPage)
				status && setSupercontestParticipated(prev => [...prev, ...data])
			} catch (error) {
				console.log(error)
			}
		}

		if (superContestPage) getSuperContestParticipation()

	}, [superContestPage])

	useEffect(() => {
		const getPost = async () => {
			try {

				const { data: { data, status } } = await fetchAdminPosts(id, 8, postPage, "normalContest")
				status && setPosts(prev => [...prev, ...data])

			} catch (error) {
				console.log(error)
			}
		}

		if (postPage > 1) getPost()

	}, [postPage])

	useEffect(() => {
		const getSuperPost = async () => {
			try {

				const { data: { data, status } } = await fetchAdminPosts(id, 8, superPostPage, "superContest")
				status && setSuperPosts(prev => [...prev, ...data])

			} catch (error) {
				console.log(error)
			}
		}

		if (superPostPage > 1) getSuperPost()

	}, [superPostPage])

	useEffect(() => {
		const getFans = async () => {
			try {

				const { data: { data, status } } = await fetchAdminFans(id, 4, fansPage)
				status && setFans(prev => [...prev, ...data])

			} catch (error) {
				console.log(error)
			}
		}

		if (fansPage) getFans()

	}, [fansPage])

	useEffect(() => {
		const getFollowings = async () => {
			try {


				const { data: { data, status } } = await fetchAdminFollowings(id, 4, followingsPage)
				status && setFollowings(prev => [...prev, ...data])

			} catch (error) {
				console.log(error)
			}
		}

		if (followingsPage) getFollowings()

	}, [followingsPage])

	useEffect(() => {
		const getComments = async () => {
			try {
				const { data: { data, status } } = await fetchAdminComments(id, 4, commentsPage)
				status && setComments(prev => [...prev, ...data])
			} catch (error) {
				console.log(error)
			}
		}

		if (commentsPage) getComments()

	}, [commentsPage])


	const get_url_extension = (url) => {
		if (url !== undefined) {
			return url.split(/[#?]/)[0].split(".").pop().trim();
		}
		return;
	};

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			<>
				<UserModal level={user.level} normalPost={posts} superPost={superPosts} currentPost={currentPost} setVisible={setVisible} visible={visible} />
				<div className="flex items-start justify-between pr-4 mb-3">
					<div className='flex items-center space-x-2'>
						<img src={user?.image}
							onError={e => { e.target.onerror = null; e.target.src = "/assets/sidebar/user.svg" }}
							className='w-12 h-12 p-1 rounded-md bg-white/10' alt="img"
						/>
						<p className="text-2xl text-white capitalize">{user.firstName} {user.lastName}</p>
					</div>
				</div>
				<div className='flex-1 pt-6 pb-2 pr-2 space-y-4 overflow-y-auto text-white divide-y divide-gray-500 vertical-scroll'>
					<div className='grid grid-cols-4'>
						<div className='flex flex-col items-center justify-between pt-4'>
							<p className='w-full text-xl capitalize text-white/60'>Name</p>
							<p className='w-full text-xl capitalize'>{user?.firstName} {user?.lastName}</p>
						</div>
						<div className='flex flex-col items-center justify-between pt-4'>
							<p className='w-full text-xl text-white/60'>Username</p>
							<p className='w-full text-xl'>{user?.userName}</p>
						</div>
						<div className='flex flex-col items-center justify-between pt-4'>
							<p className='w-full text-xl text-white/60'>Email</p>
							<p className='w-full text-xl'>{user?.email}</p>
						</div>
						<div className='flex flex-col items-center justify-between pt-4'>
							<p className='w-full text-xl text-white/60'>Phone number</p>
							<p className='w-full text-xl'>{user?.phoneNumber}</p>
						</div>
						<div className='flex flex-col items-center justify-between pt-4'>
							<p className='w-full text-xl text-white/60'>Wins</p>
							<p className='w-full text-xl'>{user?.wins}</p>
						</div>
					</div>
					{
						loading ?
							<div className='h-full my-4'>
								<Loader />
							</div> :
							<div className='py-4'>
								<Collapse defaultActiveKey={['1']} style={{ marginLeft: '-10px' }} ghost>
									<Panel header={`Contests Participated : ${counts.contest}`} key="1">
										<div className='-my-2 space-y-2'>
											{contestParticipated.map(item => <div key={item._id} className='flex items-center justify-between'>
												<div className='flex items-center space-x-2'>
													<img src={item?.contest?.contestId?.image}
														onError={e => { e.target.onerror = null; e.target.src = "/assets/sidebar/user.svg" }}
														className='w-10 h-10 p-1 rounded-lg bg-white/10' alt="img" />
													<p className="text-lg capitalize text-white/60">{item?.contest?.contestId?.tagName}</p>
												</div>
												<div className='flex items-center justify-between space-x-6 text-lg text-white/60'>
													<div className='flex items-center justify-start w-16 space-x-2'>
														<AiOutlineHeart className="w-5 h-5" />
														<p>{item?.likes?.likesCounter}</p>
													</div>
													<div className='flex items-center justify-start w-16 space-x-2'>
														<FaRegComment className="w-5 h-5" />
														<p>{item?.comments.length}</p>
													</div>
													<p className='w-16 text-right'>{item?.comments.length ? (item?.isWinner ? item?.experience + 100 : item?.experience) + (item?.comments.length * 10) :
														item?.isWinner ? item?.experience + 100 : item?.experience
													} XPs</p>
												</div>
											</div>)}
											<p onClick={() => setContestPage(prev => prev + 1)} className='text-lg text-right text-white'>{contestParticipated.length < counts.contest ? <span className='cursor-pointer'>See More</span> : ''}</p>
										</div>
									</Panel>
									<Panel header={`Super Contests Participated : ${counts.superContest}`} key="2">
										<div className='-my-2 space-y-2'>
											{superContestParticipated.map(item => <div key={item._id} className='flex items-center justify-between'>
												<div className='flex items-center space-x-2'>
													<img src={item?.contest?.contestId?.image}
														onError={e => { e.target.onerror = null; e.target.src = "/assets/sidebar/user.svg" }}
														className='w-10 h-10 p-1 rounded-lg bg-white/10' alt="img" />
													<p className="text-lg capitalize text-white/60">{item?.contest?.contestId?.tagName}</p>
												</div>
												<div className='flex items-center justify-between space-x-6 text-lg text-white/60'>
													<div className='flex items-center justify-start w-16 space-x-2'>
														<AiOutlineHeart className="w-5 h-5" />
														<p>{item?.likes?.likesCounter}</p>
													</div>
													<div className='flex items-center justify-start w-16 space-x-2'>
														<FaRegComment className="w-5 h-5" />
														<p>{item?.comments.length}</p>
													</div>
													<p className='w-16 text-right'>{item?.comments.length ? (item?.isWinner ? item?.experience + 100 : item?.experience) + (item?.comments.length * 10) :
														item?.isWinner ? item?.experience + 100 : item?.experience
													} XPs</p>
												</div>
											</div>)}
											<p onClick={() => setSuperContestPage(prev => prev + 1)} className='text-lg text-right text-white'>{superContestParticipated.length < counts.superContest ? <span className='cursor-pointer'>See More</span> : ''}</p>
										</div>
									</Panel>
									<Panel header={`Posts Created : ${counts.posts}`} key="3">
										<div className="flex w-80 items-center my-2"> {getDisplayPost()}</div>
										<div className='-my-2'>
											{
												galleryDisplay ?
													<div className='grid grid-cols-6 gap-2 py-2'>
														{
															posts.map(item =>
																get_url_extension(item.media) == "mp4" ||
																	get_url_extension(item.media) == "MP4" ||
																	get_url_extension(item.media) == "mov" ||
																	get_url_extension(item.media) == "MOV" ? (
																	<video
																		className='p-1 rounded-lg cursor-pointer bg-white/10 my-auto'
																		key={item?.id}
																		onClick={() => { setVisible(true); setCurrentPost({ ...item, type: 'normalPost' }) }}
																		onError={e => { e.target.onerror = null; e.target.src = "/assets/sidebar/user.svg" }}
																		controls={true}
																		src={item.media}></video>
																) : (
																	<img src={item?.media}
																		key={item?.id}
																		onClick={() => { setVisible(true); setCurrentPost({ ...item, type: 'normalPost' }) }}
																		onError={e => { e.target.onerror = null; e.target.src = "/assets/sidebar/user.svg" }}
																		className='p-1 rounded-lg cursor-pointer bg-white/10 my-auto' alt="img" />
																),
															)
														}
													</div>
													:
													<div className='pb-4 space-y-4'>
														{
															posts.map(post =>
																<div key={post._id} style={{ background: "linear-gradient(90deg, rgba(25,24,45,1) 0%, rgba(43,40,40,0.8870141806722689) 0%)" }}
																	className='flex items-end w-full p-5 space-x-8 rounded-xl'>
																	{
																		get_url_extension(post.media) == "mp4" ||
																			get_url_extension(post.media) == "MP4" ||
																			get_url_extension(post.media) == "mov" ||
																			get_url_extension(post.media) == "MOV"
																			? <video
																				controls={true}
																				className='rounded-lg w-[280px] h-[280px]'
																				src={post.media}
																			></video>
																			: <img
																				src={post?.media}
																				className='rounded-lg w-[280px] h-[280px]' alt="img"
																			/>
																	}
																	<div className='w-1/2 pb-2 space-y-3 text-lg'>
																		<div className='flex space-x-2'>
																			<p className='text-xl text-white'>Post Created:</p>
																			<p className='text-white/70'>{moment(post?.postCreated).format('DD/MM/YYYY')}</p>
																		</div>
																		<div className='flex space-x-2'>
																			<p className='text-xl text-white'>Contest participated :</p>
																			<p className='text-white/70'>{post?.contestParticipated}</p>
																		</div>
																		<div className='flex space-x-2'>
																			<p className='text-xl text-white'>Stack number:</p>
																			<p className='text-white/70'>{post?.stackNumber}</p>
																		</div>
																		<div className='flex space-x-2'>
																			<p className='text-xl text-white'>Likes received:</p>
																			<p className='text-white/70'>{post?.likes}</p>
																		</div>
																		<div className='flex space-x-2'>
																			<p className='text-xl text-white'>Comments received:</p>
																			<p className='text-white/70'>{post?.comments}</p>
																		</div>
																		<div className='flex space-x-2'>
																			<p className='text-xl text-white'>User Level:</p>
																			<p className='text-white/70'>{user.level}</p>
																		</div>
																	</div>
																</div>
															)
														}
													</div>
											}
											<p onClick={() => setPostPage(prev => prev + 1)} className='text-lg text-right text-white'>{posts.length < counts.posts ? <span className='cursor-pointer'>See More</span> : ''}</p>
										</div>
									</Panel>
									<Panel header={`Super Contest Posts Created : ${counts.superPosts}`} key="4">
										<div className="flex w-80 items-center my-2"> {getDisplaySuperPost()}</div>
										<div className='-my-2 '>
											{
												gallerySuperDisplay ?
													<div className='grid grid-cols-8 gap-4 py-4'>
														{
															superPosts.map(item => <img src={item?.media}
																key={item?.id}
																onClick={() => { setVisible(true); setCurrentPost({ ...item, type: 'superPost' }) }}
																onError={e => { e.target.onerror = null; e.target.src = "/assets/sidebar/user.svg" }}
																className='p-1 rounded-lg cursor-pointer w-28 h-28 bg-white/10' alt="img" />)
														}
													</div>
													:
													<div className='pb-4 space-y-4'>
														{
															superPosts.map(post =>
																<div key={post._id} style={{ background: "linear-gradient(90deg, rgba(25,24,45,1) 0%, rgba(43,40,40,0.8870141806722689) 0%)" }}
																	className='flex items-end w-full p-5 space-x-8 rounded-xl'>
																	<img src={post?.media}
																		onError={e => { e.target.onerror = null; e.target.src = "/assets/sidebar/user.svg" }}
																		className='rounded-lg w-[280px] h-[280px]' alt="img" />
																	<div className='w-1/2 pb-2 space-y-3 text-lg'>
																		<div className='flex space-x-2'>
																			<p className='text-xl text-white'>Post Created:</p>
																			<p className='text-white/70'>{moment(post?.postCreated).format('DD/MM/YYYY')}</p>
																		</div>
																		<div className='flex space-x-2'>
																			<p className='text-xl text-white'>Contest participated :</p>
																			<p className='text-white/70'>{post?.contestParticipated}</p>
																		</div>
																		<div className='flex space-x-2'>
																			<p className='text-xl text-white'>Stack number:</p>
																			<p className='text-white/70'>{post?.stackNumber}</p>
																		</div>
																		<div className='flex space-x-2'>
																			<p className='text-xl text-white'>Likes received:</p>
																			<p className='text-white/70'>{post?.likes}</p>
																		</div>
																		<div className='flex space-x-2'>
																			<p className='text-xl text-white'>Comments received:</p>
																			<p className='text-white/70'>{post?.comments}</p>
																		</div>
																		<div className='flex space-x-2'>
																			<p className='text-xl text-white'>User Level:</p>
																			<p className='text-white/70'>{user.level}</p>
																		</div>
																	</div>
																</div>
															)
														}
													</div>
											}
											<p onClick={() => setSuperPostPage(prev => prev + 1)} className='text-lg text-right text-white'>{superPosts.length < counts.superPosts ? <span className='cursor-pointer'>See More</span> : ''}</p>
										</div>
									</Panel>
									<Panel header={`Fans : ${counts.fans}`} key="5">
										<div className='-my-2 space-y-2'>
											{fans.map(fan => <div key={fan._id} className='flex items-center justify-between'>
												<div className='flex items-center space-x-2'>
													<img src={fan?.userId?.image}
														onError={e => { e.target.onerror = null; e.target.src = "/assets/sidebar/user.svg" }}
														className='w-10 h-10 p-1 rounded-lg bg-white/10' alt="img" />
													<p className="text-lg capitalize text-white/60">{fan?.userId?.firstName} {fan?.userId?.lastName}</p>
												</div>
												<div className='flex items-center space-x-6 text-lg text-white/60'>
													<p className='tracking-wide'>{moment(fan?.createdAt).format('DD/MM/YYYY')}</p>
												</div>
											</div>)}
											<p onClick={() => setFansPage(prev => prev + 1)} className='text-lg text-right text-white'>{fans.length < counts.fans ? <span className='cursor-pointer'>See More</span> : ''}</p>
										</div>
									</Panel>
									<Panel header={`Following : ${counts.followings}`} key="6">
										<div className='-my-2 space-y-2'>
											{followings.map(following => <div key={following._id} className='flex items-center justify-between'>
												<div className='flex items-center space-x-2'>
													<img src={following?.followId?.image}
														onError={e => { e.target.onerror = null; e.target.src = "/assets/sidebar/user.svg" }}
														className='w-10 h-10 p-1 rounded-lg bg-white/10' alt="img" />
													<p className="text-lg capitalize text-white/60">{following?.followId?.firstName} {following?.followId?.lastName}</p>
												</div>
												<div className='flex items-center space-x-6 text-lg text-white/60'>
													<p className='tracking-wide'>{moment(following?.createdAt).format('DD/MM/YYYY')}</p>
												</div>
											</div>)}
											<p onClick={() => setFollowingsPage(prev => prev + 1)} className='text-lg text-right text-white'>{followings.length < counts.followings ? <span className='cursor-pointer'>See More</span> : ''}</p>
										</div>
									</Panel>
									<Panel header={`Comments Posted : ${counts.comments}`} key="10">
										<div className='-my-2 space-y-2'>
											{
												comments.map(
													comment =>
														<div key={comment._id} className='flex items-center justify-between'>
															<div className='flex items-center space-x-2'>
																<p className="text-lg capitalize text-white/60">{comment.text}</p>
															</div>
															<div className='flex items-center space-x-6 text-lg text-white/60'>
																<p>{moment(comment?.createdAt).format('DD/MM/YYYY')}</p>
															</div>
														</div>
												)
											}
											<p onClick={() => setCommentsPage(prev => prev + 1)} className='text-lg text-right text-white'>{comments.length < counts.comments ? <span className='cursor-pointer'>See More</span> : ''}</p>
										</div>
									</Panel>
									<Panel header={`Levels completed: ${user.level}`} key="7"></Panel>
								</Collapse>
							</div>
					}
				</div>
			</>
		</div >
	)
}

export default UserDetails
