import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BsChevronDown } from 'react-icons/bs';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { InboxOutlined, DeleteOutlined } from '@ant-design/icons';
import { Form, Input, Upload } from 'antd';
import { updateUser, fileUpload, fetchUserById } from '../../services/api';

import Loader from '../../components/common/Loader';
import Message from '../../components/common/Message';

const { Item } = Form
const { Dragger } = Upload

function EditUser() {
	let { id } = useParams()
	const history = useHistory()
	const [form] = Form.useForm()
	const [imageUrl, setImageUrl] = useState('')
	const [imageLoading, setImageLoading] = useState(false)
	const [loading, setLoading] = useState(false)

	const uploadAPICall = async ({ file }) => {
		const formData = new FormData();
		formData.append('files', file);
		try {
			setImageLoading(true)
			const { data: { data, status } } = await fileUpload(formData)
			status && Message(`File upload successfully.`, true)
			status && setImageUrl(data[0].downloadHref)
			setImageLoading(false)
		} catch (error) {
			setImageLoading(false)
			console.log('error :>> ', error);
		}
	}

	const props = {
		name: 'file',
		multiple: false,
		headers: {
			authorization: "authorization-text",
			contentType: "multipart/form-data"
		},
		showUploadList: false,
		customRequest: uploadAPICall,
		onChange(info) {
			const { status } = info.file
			status !== 'uploading' && console.log(info.file, info.fileList)
			status === 'done' && Message(`${info.file.name} File upload successfully.`, true)
			status === 'error' && Message(`${info.file.name} file upload failed.`, false)
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files)
		},
	}

	const onFinish = async (values) => {
		setLoading(true)
		try {
			values.image = imageUrl
			if (imageUrl === '') {
				Message('Please upload media', false)
				setLoading(false)
				return
			}
			delete values.email
			delete values.userName
			const { status } = await updateUser(values, id)
			status ? Message('User updated successfully', true) : Message('Unable to updated user', false)
			status && setImageUrl('')
			setLoading(false)
			history.push('/user')
		} catch (error) {
			Message('Unable to updated User', false)
			setLoading(false)
			console.log(error)
		}
	}

	useEffect(() => {
		const getUserById = async () => {
			try {
				const { data: { data, status } } = await fetchUserById(id)
				if (status) {
					setImageUrl(data.image)
					form.setFieldsValue({
						userName: data?.userName, firstName: data?.firstName,
						lastName: data?.lastName, email: data?.email, phoneNumber: data?.phoneNumber
					})
				}
			} catch (error) {
				console.log(error)
			}
		}

		getUserById()
	}, [])

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			{
				loading ?
					<div className="absolute top-0 right-0 z-50 flex items-center justify-center w-full h-full">
						<Loader />
					</div>
					:
					<>
						<div>
							<p className="pb-1 text-2xl text-white">Edit User</p>
						</div>
						<div className='flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted'>
							<Form
								form={form}
								onFinish={async (val) => await onFinish(val)}
							>
								<div className='flex items-start justify-between space-x-8'>
									<div className='w-full px-2 text-lg'>
										<Item name='userName'>
											<Input disabled={true} placeholder='User Name' className='bg-transparent border border-muted text-muted' />
										</Item>
										<Item name='email'>
											<Input disabled={true} placeholder='Email' className='bg-transparent border border-muted text-muted' />
										</Item>
										<Item name='firstName'>
											<Input placeholder='First Name' className='bg-transparent border border-muted text-muted' />
										</Item>
										<Item name='lastName'>
											<Input placeholder='Last Name' className='bg-transparent border border-muted text-muted' />
										</Item>
										<Item name='phoneNumber'>
											<Input placeholder='Phone Number' className='bg-transparent border border-muted text-muted' />
										</Item>
									</div>
									<div className='w-full'>
										<div className='h-60'>
											{
												imageLoading ?
													<Loader /> :
													imageUrl ?
														<div className='space-y-4'>
															<img src={imageUrl} className='w-full pr-6 bg-cover h-60' alt="contest" style={{ objectFit: 'contain' }} />
															<p onClick={() => setImageUrl('')} className='inline-flex items-baseline justify-start w-full pr-6 text-lg text-muted cursor-pointer'>
																Remove Image
																<DeleteOutlined className='px-1' style={{ color: '#fff', fontSize: '16px' }} />
															</p>
														</div> :
														<Dragger {...props}>
															<p className="ant-upload-drag-icon"><InboxOutlined style={{ fontSize: '80px', color: '#666' }} /></p>
															<p className="px-2 text-lg text-muted">Click or drag file to this area to upload</p>
														</Dragger>
											}
										</div>
									</div>
								</div>
								<div className='flex items-center w-1/2 pl-2 pr-4 space-x-4'>
									<button htmltype='submit' className='w-full py-2 btn-border border-muted'>Update</button>
									<button onClick={() => history.push('/user')} className='w-full py-2 btn-border text-danger border-danger'>Cancel</button>
								</div>
							</Form>
						</div>
					</>
			}
		</div>
	)
}

export default EditUser
