import { useEffect, useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { deletePost, fetchReportedPosts, deleteSuperPost, updateReportedPost } from '../../services/api';

import Message from '../../components/common/Message';
import DeleteModal from '../../components/common/DeleteModal';

function ReportPost() {
  const [posts, setPosts] = useState([])
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [deletedRecord, setDeletedRecord] = useState(null)

  const confirmDelete = async () => {
    try {
      if (deletedRecord?.post) {
        const { status } = await deletePost(deletedRecord?.post?._id)
        if (status) {
          const { status: resp } = await updateReportedPost(deletedRecord?._id, { isDeleted: true })
          resp ? Message('Deleted successfully', true) : Message('Unable to delete', false)
        }
        setVisibleDelete(false)
      }
      if (deletedRecord?.superPost) {
        const { status } = await deleteSuperPost(deletedRecord?.superPost?._id)
        if (status) {
          const { status: resp } = await updateReportedPost(deletedRecord?._id, { isDeleted: true })
          resp ? Message('Deleted successfully', true) : Message('Unable to delete', false)
        }
        setVisibleDelete(false)
      }
    } catch (error) {
      Message('Unable to delete', false)
      setVisibleDelete(false)
      console.log(error)
    }
  }

  const deleteRecord = (item) => {
    setDeletedRecord(item)
    setVisibleDelete(true)
  }

  useEffect(() => {
    const getReportedPosts = async () => {
      try {
        const { data: { data, status } } = await fetchReportedPosts()
        status && setPosts(data)
      } catch (error) {
        console.log(error)
      }
    }
    getReportedPosts()
  }, [visibleDelete])

  return (
    <div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
      <DeleteModal
        title={deletedRecord?.post ? deletedRecord?.post?.title : deletedRecord?.superPost?.title}
        image={deletedRecord?.post ? deletedRecord?.post?.media : deletedRecord?.superPost?.media}
        setVisible={setVisibleDelete}
        confirmDelete={confirmDelete}
        visible={visibleDelete} />
      <div className="flex items-start justify-between pr-4">
        <div>
          <p className="pb-1 text-2xl text-white">Reported Posts</p>
          <p className="pb-4 text-muted">{posts.length} posts</p>
        </div>
      </div>
      <div className='flex items-center justify-between pt-4 text-white'>
        <div className='flex items-center space-x-0'>
          <p className='text-xl w-80'>Title</p>
          <p className='overflow-hidden text-xl w-96'>Description</p>
        </div>
        <div className='flex space-x-8'>
          <p className='overflow-hidden text-xl w-96'>Media</p>
        </div>
      </div>
      <div className='flex-1 pb-2 pr-2 space-y-4 overflow-y-auto text-white divide-y divide-gray-500 vertical-scroll'>
        {
          posts?.map(item => (
            <div onClick={() => deleteRecord(item)} key={item?._id} className='flex items-center justify-between pt-4 cursor-pointer'>
              <div className='flex items-center space-x-0'>
                {
                  item.post ?
                    <p className='text-xl capitalize w-80'>{item?.post?.title}</p> :
                    <p className='text-xl capitalize w-80'>{item?.superPost?.title}</p>
                }
                {
                  item.post ?
                    <p className='text-xl capitalize w-80'>{item?.post?.description}</p> :
                    <p className='overflow-hidden text-xl capitalize w-96'>{item?.superPost?.description}</p>
                }
              </div>
              <div className='flex items-start px-2 space-x-8 w-96'>
                {
                  item.post ?
                    <img src={item?.post?.media}
                      onError={e => { e.target.onerror = null; e.target.src = "/assets/sidebar/user.svg" }}
                      className='w-8 h-8 p-1 rounded-md bg-white/10' alt="img" /> :
                    <img src={item?.post?.media}
                      onError={e => { e.target.onerror = null; e.target.src = "/assets/sidebar/user.svg" }}
                      className='w-8 h-8 p-1 rounded-md bg-white/10' alt="img" />
                }
              </div>
            </div>
          ))
        }
      </div>
      <div className="flex items-center justify-between px-4 text-muted">
        <p>1-5 of  5</p>
        <div className="flex items-center space-x-2">
          <p>Rows per page:</p>
          <div className="flex items-center space-x-1 btn-border border-muted text-muted">
            <p>10</p>
            <BsChevronDown />
          </div>
          <div className="p-2 btn-border border-muted text-muted">
            <FaArrowLeft />
          </div>
          <div className="p-2 btn-border border-muted text-muted">
            <FaArrowRight />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportPost
