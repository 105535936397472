import { useEffect, useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { fetchReportedUsers, updateReportedUser, updateUser } from '../../services/api';

import Message from '../../components/common/Message';
import DeleteModal from '../../components/common/DeleteModal';

function ReportPost() {
  const [users, setUsers] = useState([])
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [deletedRecord, setDeletedRecord] = useState({})

  const confirmDelete = async () => {
    try {
      const { status } = await updateUser({ isDeleted: true }, deletedRecord?.reportedUser?._id)
      if (status) {
        const { status: resp } = await updateReportedUser(deletedRecord?._id, { isDeleted: true })
        resp ? Message('Deleted successfully', true) : Message('Unable to delete', false)
      }
      setVisibleDelete(false)
    } catch (error) {
      Message('Unable to delete', false)
      setVisibleDelete(false)
      console.log(error)
    }
  }

  const deleteRecord = (item) => {
    setDeletedRecord(item)
    setVisibleDelete(true)
  }

  useEffect(() => {
    const getReportedUsers = async () => {
      try {
        const { data: { data, status } } = await fetchReportedUsers()
        status && setUsers(data)
      } catch (error) {
        console.log(error)
      }
    }
    getReportedUsers()
  }, [visibleDelete])

  return (
    <div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
      <DeleteModal
        title={`${deletedRecord?.reportedUser?.firstName} ${deletedRecord?.reportedUser?.lastName}`}
        data={deletedRecord?.reportedUser}
        setVisible={setVisibleDelete}
        confirmDelete={confirmDelete}
        visible={visibleDelete} />
      <div className="flex items-start justify-between pr-4">
        <div>
          <p className="pb-1 text-2xl text-white">Reported Users</p>
          <p className="pb-4 text-muted">{users.length} users</p>
        </div>
      </div>
      <div className='flex items-center justify-between pt-4 text-white'>
        <p className='w-full overflow-hidden text-xl'>User</p>
        <p className='w-full overflow-hidden text-xl'>ReportedBy</p>
        <p className='w-full overflow-hidden text-xl'>Reason</p>
      </div>
      <div className='flex-1 pb-2 pr-2 space-y-4 overflow-y-auto text-white divide-y divide-gray-500 vertical-scroll'>
        {
          users?.map(item => (
            <div onClick={() => deleteRecord(item)} key={item?._id} className='flex items-center justify-between pt-4 cursor-pointer'>
              <div className='flex items-center justify-between w-full'>
                <div className='flex items-center w-full space-x-2 overflow-hidden text-xl'>
                  <img src={item?.reportedUser?.image}
                    onError={e => { e.target.onerror = null; e.target.src = "/assets/sidebar/user.svg" }}
                    className='w-12 h-12 p-1 rounded-md bg-white/10' alt="img" />
                  <div className='space-y-1'>
                    <p className='text-base capitalize '>{item?.reportedUser?.firstName} {item?.reportedUser?.lastName}</p>
                    <p className='text-sm text-white/60'>{item?.reportedUser?.email}</p>
                  </div>
                </div>
                <div className='flex w-full space-x-2 overflow-hidden text-xl'>
                  <img src={item?.user?.image}
                    onError={e => { e.target.onerror = null; e.target.src = "/assets/sidebar/user.svg" }}
                    className='w-12 h-12 p-1 rounded-md bg-white/10' alt="img" />
                  <div className='space-y-1'>
                    <p className='text-base capitalize '>{item?.user?.firstName} {item?.user?.lastName}</p>
                    <p className='text-sm text-white/60'>{item?.user?.email}</p>
                  </div>
                </div>
                <div className='flex w-full px-2 text-lg'>
                  <p className='line-clamp-2'>{item?.reason}</p>
                </div>
              </div>
            </div>
          ))
        }
      </div>
      <div className="flex items-center justify-between px-4 text-muted">
        <p>1-5 of  5</p>
        <div className="flex items-center space-x-2">
          <p>Rows per page:</p>
          <div className="flex items-center space-x-1 btn-border border-muted text-muted">
            <p>10</p>
            <BsChevronDown />
          </div>
          <div className="p-2 btn-border border-muted text-muted">
            <FaArrowLeft />
          </div>
          <div className="p-2 btn-border border-muted text-muted">
            <FaArrowRight />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportPost
