import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/Auth";
import { TbStack2 } from "react-icons/tb";
import { GiPodiumWinner } from "react-icons/gi";
import { FaUsers, FaUserAlt, FaBusinessTime, FaGamepad } from "react-icons/fa";
// import { IoAnalytics} from 'react-icons/io';
import { BsFileBarGraph, BsFilePost, BsTrophyFill } from "react-icons/bs";
import { MdOutlineReport, MdLogout, MdAnalytics, MdPayment, MdGamepad, MdGames, MdReportGmailerrorred } from "react-icons/md";
import { notification } from "antd";
import { matchPath } from "react-router";
import { BiGame, BiParagraph } from "react-icons/bi";


export default function AppMenu() {
	const history = useHistory();
	const auth = useContext(AuthContext);
	const [route, setRoute] = useState("");
	const [active, setActive] = useState("");

	return (
		<div className="h-screen side-border rounded-r-[50px]">
			<div className="h-full flex flex-col side-bg rounded-r-[50px] mr-2 px-8 text-white">
				<div className="pt-8">
					<div
						onClick={() => history.push("/")}
						className="h-12 cursor-pointer"
					>
						<img src="/assets/logo.svg" alt="wooo" />
					</div>
				</div>
				<div className="flex flex-col justify-between h-full text-base py-14">
					<div className="space-y-4">
						<div
							onClick={() => {
								setRoute("");
								setActive(!active)
								console.log(history)
							}}
							className={`flex items-center space-x-3 cursor-pointer ${history.location.pathname !== '/'
								? "opacity-50"
								: "opacity-100"
								}`}
						>
							<img
								className="w-6"
								src="/assets/sidebar/category.svg"
								alt="Performance"
							/>
							<p onClick={() => { history.push("/") }}>Overview</p>
						</div>
						<div
							onClick={() => {
								setRoute("user");
								setActive(!active);
							}}
							className={`flex items-center space-x-3 cursor-pointer ${route === "user"
								? "opacity-100"
								: "opacity-50"
								}`}
						>
							<FaUsers className="w-6 text-2xl" />
							<p>Users</p>
						</div>
						<div
							className={`flex-col text-base dropdown-menu transition-all ${route === "user" && active === true ? "flex" : "hidden"
								}`}
						>
							<div className={`flex items-center mx-4 cursor-pointer ${history.location.pathname === ("/user")
								? "opacity-100"
								: "opacity-50"
								}`}>
								<p onClick={() => { history.push("/user") }}>User List</p>
							</div>
							<div className={`flex items-center mx-4 cursor-pointer ${history.location.pathname === ("/influencer-users")
								? "opacity-100"
								: "opacity-50"
								}`}>
								<p onClick={() => { history.push("/influencer-users") }}>Influencer List</p>
							</div>
							<div className={`flex items-center mx-4 cursor-pointer ${history.location.pathname === ("/user-reports")
								? "opacity-100"
								: "opacity-50"
								}`}>
								<p onClick={() => { history.push("/user-reports") }}>User Reports</p>
							</div>
							<div className={`flex items-center mx-4 cursor-pointer ${history.location.pathname === ("/user-referral")
								? "opacity-100"
								: "opacity-50"
								}`}>
								<p onClick={() => { history.push("/user-referral") }}>User Referral</p>
							</div>
						</div>
						<div
							onClick={() => {
								history.push("/stack");
								setRoute("stack");
							}}
							className={`flex items-center space-x-3 cursor-pointer ${history.location.pathname.includes("/stack")
								? "opacity-100"
								: "opacity-50"
								}`}
						>
							<TbStack2 className="w-6 text-2xl" />
							<p>Stacks</p>
						</div>
						{/* <div
							onClick={() => {
								history.push("/contest");
								setRoute("contest");
							}}
							className={`flex items-center space-x-3 cursor-pointer ${history.location.pathname.includes("/contest")
								? "opacity-100"
								: "opacity-50"
								}`}
						>
							<BsFilePost className="w-6 text-lg" />
							<p>Contests</p>
						</div> */}
						<div
							onClick={() => {
								setRoute("contests");
								setActive(!active);
							}}
							className={`flex items-center space-x-3 cursor-pointer ${route === "contests"
								? "opacity-100"
								: "opacity-50"
								}`}
						>
							<FaGamepad className="w-6 text-lg" />
							<p>Contests</p>
						</div>
						<div
							className={`flex-col text-base dropdown-menu transition-all ${route === "contests" && active === true ? "flex" : "hidden"
								}`}
						>
							<div className={`flex items-center mx-4 cursor-pointer ${history.location.pathname === ("/contest")
								? "opacity-100"
								: "opacity-50"
								}`}>
								<p onClick={() => { history.push("/contest") }}>Contests</p>
							</div>
							<div className={`flex items-center mx-4 cursor-pointer ${history.location.pathname === ("/talent-categories")
								? "opacity-100"
								: "opacity-50"
								}`}>
								<p onClick={() => { history.push("/talent-categories") }}>Talent Categories</p>
							</div>
							<div className={`flex items-center mx-4 cursor-pointer ${history.location.pathname === ("/talent-contests")
								? "opacity-100"
								: "opacity-50"
								}`}>
								<p onClick={() => { history.push("/talent-contests") }}>Talent Contests</p>
							</div>
							<div className={`flex items-center mx-4 cursor-pointer ${history.location.pathname === ("/contest-activity")
								? "opacity-100"
								: "opacity-50"
								}`}>
								<p onClick={() => { history.push("/contest-activity") }}>Contest Activity</p>
							</div>
						</div>
						{/* </div> */}
						<div
							onClick={() => {
								setRoute("analytics");
								setActive(!active);
							}}
							className={`flex items-center space-x-3 cursor-pointer ${route === "analytics"
								? "opacity-100"
								: "opacity-50"
								}`}
						>
							<MdAnalytics className="w-6 text-lg" />
							<p>Analytics</p>
						</div>
						<div
							className={`flex-col text-base dropdown-menu transition-all ${route === "analytics" && active === true ? "flex" : "hidden"
								}`}
						>
							<div className={`flex items-center mx-4 cursor-pointer ${history.location.pathname === ("/action-analytics")
								? "opacity-100"
								: "opacity-50"
								}`}>
								<p onClick={() => { history.push("/action-analytics") }}>Action Analytics</p>
							</div>
							<div className={`flex items-center mx-4 cursor-pointer ${history.location.pathname === ("/level-progression")
								? "opacity-100"
								: "opacity-50"
								}`}>
								<p onClick={() => { history.push("/level-progression") }}>Level Progression</p>
							</div>
							<div className={`flex items-center mx-4 cursor-pointer ${history.location.pathname === ("/users-location")
								? "opacity-100"
								: "opacity-50"
								}`}>
								<p onClick={() => { history.push("/users-location") }}>Users Location</p>
							</div>
						</div>
						<div
							onClick={() => {
								setRoute("payment");
								setActive(!active);
							}}
							className={`flex items-center space-x-3 cursor-pointer ${route === "payment"
								? "opacity-100"
								: "opacity-50"
								}`}
						>
							<MdPayment className="w-6 text-lg" />
							<p>Payment</p>
						</div>
						<div
							className={`flex-col text-base dropdown-menu transition-all ${route === "payment" && active === true ? "flex" : "hidden"
								}`}
						>
							<div className={`flex items-center mx-4 cursor-pointer ${history.location.pathname === ("/withdraw-request")
								? "opacity-100"
								: "opacity-50"
								}`}>
								<p onClick={() => { history.push("/withdraw-request") }}>Withdraw Requests</p>
							</div>
						</div>
					</div>
					<div className="flex flex-col space-y-6 text-base">
						<div className="space-y-2">
							<p className="pb-2 text-xs">MENU</p>
							<div
								onClick={() => {
									history.push("/reward");
									setRoute("reward");
								}}
								className={`flex items-center space-x-3 cursor-pointer ${history.location.pathname.includes("/reward")
									? "opacity-100"
									: "opacity-50"
									}`}
							>
								<BsTrophyFill className="w-6 text-lg" />
								<p>Rewards</p>
							</div>
							<div
								onClick={() => {
									setRoute("level");
									setActive(!active);
								}}
								className={`flex items-center space-x-3 cursor-pointer ${route === "level"
									? "opacity-100"
									: "opacity-50"
									}`}
							>
								<BsFileBarGraph className="w-6 text-2xl" />
								<p>Levels</p>
							</div>
							<div
								className={`flex-col text-base dropdown-menu transition-all ${route === "level" && active === true ? "flex" : "hidden"
									}`}
							>
								<div className={`flex items-center mx-4 cursor-pointer ${history.location.pathname === ("/level")
									? "opacity-100"
									: "opacity-50"
									}`}>
									<p onClick={() => { history.push("/level") }}>Level</p>
								</div>
								<div className={`flex items-center mx-4 cursor-pointer ${history.location.pathname === ("/level-range")
									? "opacity-100"
									: "opacity-50"
									}`}>
									<p onClick={() => { history.push("/level-range") }}>Level Range</p>
								</div>
							</div>
							<div
								onClick={() => {
									history.push("/winner");
									setRoute("winner");
								}}
								className={`flex items-center space-x-3 cursor-pointer ${history.location.pathname.includes("/winner")
									? "opacity-100"
									: "opacity-50"
									}`}
							>
								<GiPodiumWinner className="w-6 text-xl" />
								<p>Winners</p>
							</div>
							<div
								onClick={() => {
									setRoute("notification");
									setActive(!active)
								}}
								className={`flex items-center space-x-3 cursor-pointer ${route === "notification"
									? "opacity-100"
									: "opacity-50"
									}`}
							>
								<img
									className="w-6"
									src="/assets/sidebar/message.svg"
									alt="Performance"
								/>
								<p>Notifications</p>
							</div>
							<div
								className={`flex-col text-base dropdown-menu transition-all ${route === "notification" && active === true ? "flex" : "hidden"
									}`}
							>
								<div className={`flex items-center mx-2 cursor-pointer ${history.location.pathname.includes("/schedule-notification")
									? "opacity-100"
									: "opacity-50"
									}`}>
									<p onClick={() => { console.log(history); history.push("/schedule-notification") }}>Schedule Notification</p>
								</div>
								<div className={`flex items-center mx-2 cursor-pointer ${history.location.pathname.includes("/notification-message/broadcast")
									? "opacity-100"
									: "opacity-50"
									}`}>
									<p onClick={() => { history.push("/notification-message/broadcast") }}>Brodcast Notification</p>
								</div>
								<div className={`flex items-center mx-2 cursor-pointer ${history.location.pathname === "/notification-message"
									? "opacity-100"
									: "opacity-50"
									}`}>
									<p onClick={() => { history.push("/notification-message") }}>Notification Messages</p>
								</div>
							</div>
						</div>
						<div className="space-y-2">
							<p className="pb-2 text-xs">UTILITIES</p>
							<div
								onClick={() => {
									history.push("/report");
									setRoute("report");
								}}
								className={`flex items-center space-x-3 cursor-pointer ${history.location.pathname.includes("/report")
									? "opacity-100"
									: "opacity-50"
									}`}
							>
								<MdOutlineReport className="w-6 text-2xl" />
								<p>Reports</p>
							</div>
							<div
								className={`flex items-center space-x-3 cursor-pointer ${history.location.pathname.includes("/settings")
									? "opacity-100"
									: "opacity-50"
									}`}
							>
								<img
									className="w-6"
									src="/assets/sidebar/settings.svg"
									alt="Performance"
								/>
								<p>Settings</p>
							</div>
							<div
								onClick={() => {
									auth.signOut();
									setRoute("logout");
								}}
								className={`flex items-center space-x-3 cursor-pointer ${history.location.pathname.includes("/logout")
									? "opacity-100"
									: "opacity-50"
									}`}
							>
								<MdLogout className="w-6 ml-[2px] text-2xl" />
								<p>Logout</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div >
	);
}
