import { useState } from "react";
import { Form, Input } from "antd";
import { scheduleNotificationCreate } from "../../services/api";

import Message from "../../components/common/Message";
import Loader from "../../components/common/Loader";
import { useHistory } from "react-router-dom";

const { Item } = Form;

function NewReward() {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	const onFinish = async (data) => {
		try {
			setLoading(true);
			const { status } = await scheduleNotificationCreate(data);
			status
				? Message("Schdeule notification created successfully", true)
				: Message("Unable to create schedule notification", false);
			form.resetFields();
			history.push("/schedule-notification/")
			setLoading(false);
		} catch (error) {
			Message("Unable to create schedule notification", false);
			setLoading(false);
			console.log(error);
		}
	};

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			{loading ? (
				<div className="absolute top-0 right-0 z-50 flex items-center justify-center w-full h-full">
					<Loader />
				</div>
			) : (
				<>
					<div>
						<p className="pb-1 text-2xl text-white capitalize">Add Schdeule Notification</p>
					</div>
					<div className="flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted">
						<Form
							form={form}
							layout="horizontal"
							onFinish={async (val) => await onFinish(val)}
							initialValues={{
								message: undefined,
								scheduleTime: undefined,
								title: undefined
							}}
						>
							<div className="flex items-start justify-between space-x-8">
								<div className="w-full px-2 text-lg">
									<Item
										rules={[{ required: true, message: "Name is required" }]}
										name="title"
									>
										<Input
											placeholder="Name"
											className="bg-transparent border border-muted text-muted"
										/>
									</Item>
									<Item
										rules={[{ required: true, message: "Message is required" }]}
										name="message"
									>
										<Input
											placeholder="Message"
											className="bg-transparent border border-muted text-muted"
										/>
									</Item>
									<Item
										rules={[
											{ required: true, message: "Schedule Time is required" },
										]}
										name="scheduleTime"
									>
										<Input
											placeholder="Schedule Time"
											className="bg-transparent border border-muted text-muted"
										/>
									</Item>
								</div>
								<div className="w-full"></div>
							</div>
							<div className="w-1/2 pl-2 pr-4">
								<Item>
									<button
										htmltype="submit"
										className="w-full py-2 btn-border border-muted"
									>
										Create
									</button>
								</Item>
							</div>
						</Form>
					</div>
				</>
			)}
		</div>
	);
}

export default NewReward;
