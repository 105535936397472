export const LOGIN = 'login-admin'
export const STACK = 'stack'
export const CONTEST = 'contest'
export const SUPERCONTEST = 'supercontest'
export const SUPERCONTESTCHILD = 'super-contest-childs'
export const USER = 'user'
export const REWARD = 'reward'
export const LEVEL = 'level'
export const NOTIFICATION = 'notification'
export const NOTIFICATIONMESSAGE = 'notification-message'
export const WINNER = 'winners'
export const POST = 'post'
export const ADMIN = 'admin'
export const REPORT = 'report'
export const COMMENT = 'comment'
export const CATEGORY = 'category'
export const LEVELRANGE = 'level-range'


