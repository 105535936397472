import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { BsChevronDown } from 'react-icons/bs';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { fetchPostsByUserId } from '../../services/api';

function Report() {
  const history = useHistory()

  return (
    <div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
      <div className="flex items-start justify-between pr-4">
        <p className="pb-1 text-2xl text-white">Report</p>
      </div>
      <div className='flex-1 pt-10 pb-2 pr-2 space-y-4 overflow-y-auto text-white divide-y divide-gray-500 vertical-scroll'>
        <div onClick={() => history.push('/report/post')} className='flex items-center justify-between pt-4 pr-10 cursor-pointer'>
          <p className='text-xl capitalize w-80'>Reported Posts</p>
          <FaArrowRight className='text-xl' />
        </div>
        <div onClick={() => history.push('/report/user')} className='flex items-center justify-between pt-4 pr-10 cursor-pointer'>
          <p className='text-xl capitalize w-80'>Reported Users</p>
          <FaArrowRight className='text-xl' />
        </div>
      </div>
      <div className="flex items-center justify-between px-4 text-muted">
        <p>1-5 of  5</p>
        <div className="flex items-center space-x-2">
          <p>Rows per page:</p>
          <div className="flex items-center space-x-1 btn-border border-muted text-muted">
            <p>10</p>
            <BsChevronDown />
          </div>
          <div className="p-2 btn-border border-muted text-muted">
            <FaArrowLeft />
          </div>
          <div className="p-2 btn-border border-muted text-muted">
            <FaArrowRight />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Report
