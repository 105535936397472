import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BsChevronDown } from 'react-icons/bs';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { Form, Input } from 'antd';
import { fetchRewardById, updateReward } from '../../services/api';

import Loader from '../../components/common/Loader';
import Message from '../../components/common/Message';

const { Item } = Form

function EditReward() {
	let { id } = useParams()
	const history = useHistory()
	const [form] = Form.useForm()
	const [loading, setLoading] = useState(false)

	const onFinish = async (values) => {
		setLoading(true)
		try {
			const { status } = await updateReward(values, id)
			status ? Message('Reward updated successfully', true) : Message('Unable to updated reward', false)
			setLoading(false)
			history.push('/reward')
		} catch (error) {
			Message('Unable to updated Reward', false)
			setLoading(false)
			console.log(error)
		}
	}

	useEffect(() => {
		const getRewardById = async () => {
			try {
				const { data: { data, status } } = await fetchRewardById(id)
				status && form.setFieldsValue({ type: data?.type, value: data?.value })
			} catch (error) {
				console.log(error)
			}
		}

		getRewardById()
	}, [])

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			{
				loading ?
					<div className="absolute top-0 right-0 z-50 flex items-center justify-center w-full h-full">
						<Loader />
					</div>
					:
					<>
						<div>
							<p className="pb-1 text-2xl text-white">Edit Reward</p>
						</div>
						<div className='flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted'>
							<Form
								form={form}
								onFinish={async val => await onFinish(val)}
							>
								<div className='flex items-start justify-between space-x-8'>
									<div className='w-full px-2 text-lg'>
										<Item rules={[{ required: true, message: '' }]} name='type'>
											<Input disabled={true} placeholder='Title' className='bg-transparent border border-muted text-muted' />
										</Item>
										<Item rules={[{ required: true, message: '' }]} name='value'>
											<Input placeholder='XPs' type='number' min={0} className='bg-transparent border border-muted text-muted' />
										</Item>
									</div>
									<div className='w-full'>
									</div>
								</div>
								<div className='flex items-center w-1/2 pl-2 pr-4 space-x-4'>
									<button htmltype='submit' className='w-full py-2 btn-border border-muted'>Update</button>
									<button onClick={() => history.push('/reward')} className='w-full py-2 btn-border text-danger border-danger'>Cancel</button>
								</div>
							</Form>
						</div>
					</>
			}
		</div>
	)
}

export default EditReward
