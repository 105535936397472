/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useMemo, useState } from "react";
import ModalImage from "react-modal-image";
import { useHistory } from "react-router-dom";
import { RiSearch2Line } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { FaUserAlt } from "react-icons/fa";
import { fetchUsers } from "../../services/api";
import DataTableBase from "../../components/common/dataTable";
import { DownOutlined } from "@ant-design/icons";
import { Checkbox, Select } from "antd";

const { Option } = Select;
function Users() {
	const defaultValues = ["name", "phoneNumber", "email"];

	const history = useHistory();
	const [search, setSearch] = useState("");
	const [isDeleted, setIsDeleted] = useState(false);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [limit, setLimit] = useState(10);
	const [currPage, setCurrPage] = useState(1);
	const [keys, setKeys] = useState([]);
	const [columnsToDisplay, setColumnsToDisplay] = useState(defaultValues);

	const filteredUsers = data.filter((user) => {
		return (
			user?.firstName?.toLowerCase().includes(search?.toLowerCase()) ||
			user?.email?.toLowerCase().includes(search?.toLowerCase()) ||
			user?.lastName?.toLowerCase().includes(search?.toLowerCase()) ||
			user?.deviceType?.toLowerCase().includes(search?.toLowerCase())
		);
	});

	useEffect(() => {
		const getUsers = async () => {
			setLoading(true);
			const {
				data: { data, status },
			} = await fetchUsers(search, currPage, limit);
			status && setData(data.users);
			status && setTotalRows(data.totalCount);
			status && setKeys(data.keys);
			setLoading(false);
		};
		getUsers();
	}, [currPage, limit, isDeleted, search]);

	useEffect(() => {
		setTotalRows(filteredUsers.length);
	}, [search]);

	const columns = useMemo(
		() => [
			{
				name: "Name",
				selector: (row) => `${row.firstName} ${row.lastName}` || "N/A",
				sortable: true,
				cell: (row) => (
					<a onClick={() => history.push("/user/" + row?._id)}>
						{`${row?.firstName} ${row?.lastName}`}
					</a>
				),
			},
			{
				name: "Number",
				selector: (row) => row.phoneNumber || "N/A",
			},
			{
				name: "Email",
				selector: (row) => row.email,
				sortable: true,
			},
			{
				name: "Wins",
				selector: (row) => row.wins,
				sortable: true,
				omit: columnsToDisplay.includes("wins") ? false : true,
			},
			{
				name: "Login Type",
				selector: (row) => row.loginType,
				sortable: true,
				omit: columnsToDisplay.includes("loginType") ? false : true,
			},
			{
				name: "Device Type",
				selector: (row) => row.deviceType || "N/A",
				sortable: true,
				omit: columnsToDisplay.includes("deviceType") ? false : true,
			},
			{
				name: "Subscription",
				selector: (row) =>
					row.superContestSubscription === true ? "True" : "False",
				sortable: true,
				omit: columnsToDisplay.includes("superContestSubscription")
					? false
					: true,
			},
			{
				name: "Subscribed On",
				selector: (row) =>
					row.subscriptionDate !== undefined
						? new Date(row.subscriptionDate)
						: "N/A",
				cell: (row) =>
					row.subscriptionDate !== undefined
						? new Date(row.subscriptionDate).toLocaleDateString()
						: "N/A",
				omit: columnsToDisplay.includes("subscriptionDate") ? false : true,
			},
			{
				name: "Joined Date",
				selector: (row) => new Date(row.createdAt),
				cell: (row) => new Date(row.createdAt).toDateString(),
				omit: columnsToDisplay.includes("createdAt") ? false : true,
			},
			{
				name: "Profile Picture",
				omit: columnsToDisplay.includes("image") ? false : true,
				cell: (row) =>
					row.image !== "" ? (
						<ModalImage
							small={row.image}
							large={row.image}
							className="w-8 h-8 p-1 rounded-md bg-white/10"
							alt=""
						/>
					) : (
						<FaUserAlt style={{ fontSize: "18px" }} />
					),
			},
			{
				name: "Actions",
				center: true,
				cell: (row) => (
					<div>
						<a onClick={() => history.push("/user/edit/" + row?._id)}>
							<div className="flex items-center">
								<BiEditAlt /> &nbsp;Edit
							</div>
						</a>
					</div>
				),
			},
		],
		[keys, columnsToDisplay]
	);
	const onChange = (e) => {
		console.log(`checked = ${e.target.checked}`);
	};

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			<div className="flex items-center justify-between">
				<div className="w-50 flex flex-col pr-20 space-y-2">
					<div>
						<p className="pb-1 text-2xl text-white">Users</p>
						<p className="text-muted">{totalRows} Users</p>
					</div>
					<div className="flex w-80 items-center bg-[#111111] rounded-full">
						<RiSearch2Line className="w-5 h-5 ml-2 mr-1 text-gray-400" />
						<input
							type="text"
							className="rounded-full text-gray-400 w-96 focus:outline-none focus:border-none focus:ring-0 bg-[#111111]/30  border-none"
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							placeholder="Search"
						/>
					</div>
				</div>
				<div className="rounded-full text-gray-400 w-96 focus:outline-none focus:border-none focus:ring-0 bg-[#111111]/30  border-none">
					<Select
						mode="multiple"
						allowClear
						style={{
							width: "100%",
						}}
						placeholder="Click Coumns To Display"
						onChange={(val) => setColumnsToDisplay(val)}
						options={keys}
					/>
				</div>
			</div>
			{
				<div className="flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted">
					<DataTableBase
						columns={columns}
						data={filteredUsers}
						progressPending={loading}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={(newLimit, page) => {
							setLimit(newLimit);
							setCurrPage(page);
						}}
						onChangePage={(page) => setCurrPage(page)}
					/>
				</div>
			}
		</div>
	);
}

export default Users;
