import { useEffect, useState } from 'react';
import { fetchWithdrawRequests, actionWithdraw } from '../../services/api'
import { useHistory } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Message from '../../components/common/Message';
import DataTableBase from '../../components/common/dataTable';
import { BiBasket, BiEdit } from 'react-icons/bi';
import { Modal } from 'antd';

function WithdrawRequest() {
	const history = useHistory()
	const [loading, setLoading] = useState(false)
	const [tabIndex, setTabIndex] = useState(0)
	const [approved, setApproved] = useState([])
	const [declined, setDeclined] = useState([])
	const [pending, setPending] = useState([])
	const [processed, setProcessed] = useState([])
	const [visible, setVisible] = useState(false)

	useEffect(() => {
		const getRequest = async () => {
			try {
				const { data: { data, status } } = await fetchWithdrawRequests()
				if (status) {
					console.log(data)
					await data.map(({ records, status }) => {
						switch (status) {
							case 'success':
								setApproved(records)
								break;
							case 'declined':
								setDeclined(records)
								break;
							case 'pending':
								setPending(records)
								break;
							case 'processed':
								setProcessed(records)
								break;
							default:
								break;
						}
					})
				}
				setLoading(false)
			} catch (error) {
				setLoading(false)
				console.log(error)
			}
		}
		getRequest()
	}, [tabIndex])

	const action = async (id, remarks, status) => {
		setLoading(true)
		const actionResp = await actionWithdraw({ id, remarks, status })
		if (actionResp.status) {
			Message('Action Done', true)
			window.location.reload(true);
			setLoading(false)
		} else {
			Message('Something went wrong', false)
		}
		setLoading(false)
	}

	const columns = [
		{
			name: 'Coins',
			selector: (row) => row.coins,
			sortable: true,
		},
		{
			name: 'Status',
			selector: (row) => row.status,
			sortable: true,

		},
		{
			name: 'Currency',
			selector: (row) => row.currency || 'WOO',
			sortable: true,

		},
		{
			name: 'User',
			selector: (row) => `${row.userId.userName}` || 'N/A',
			sortable: true,
			cell: (row) => (
				<a onClick={() => history.push('/user/' + row?._id)}>
					{`${row?.userId?.firstName} ${row?.userId?.lastName}`}
				</a>
			),
		},
		{
			name: 'Comments',
			selector: (row) => row.comments || 'N/A',
			sortable: true,

		},
		{
			name: 'Payout initiated Date',
			selector: (row) => row.createdAt || 'N/A',
			sortable: true,
		},
	];
	const pendingColumns = [
		{
			name: 'Coins',
			selector: (row) => row.coins,
			sortable: true,
		},
		{
			name: 'Status',
			selector: (row) => row.status,
			sortable: true,

		},
		{
			name: 'Currency',
			selector: (row) => row.coins + ' - ' + row.currency,
			sortable: true,

		},
		{
			name: 'User',
			selector: (row) => `${row.userId.userName}` || 'N/A',
			sortable: true,
			cell: (row) => (
				<a onClick={() => history.push('/user/' + row?._id)}>
					{`${row.userId.userName}`}
				</a>
			),
		},
		{
			name: 'Comments',
			selector: (row) => row.comments || 'N/A',
			sortable: true,

		},
		{
			name: 'Payout initiated Date',
			selector: (row) => row.createdAt || 'N/A',
			sortable: true,

		},
		{
			name: 'Actions',
			cell: (row) => (
				<div className='flex' >
					<div className='flex'>
						<a className='mx-1' onClick={() => action(row._id, 'Approved, You will receive your fund in two days.', 'approved')}>
							<div className='flex items-center underline'>
								<BiEdit /> &nbsp;Approve
							</div>
						</a>
						<a className='mx-1' onClick={() => action(row._id, 'Please Contact Admin for Status', 'declined')}>
							<div className='flex items-center underline'>
								<BiBasket /> &nbsp;Decline
							</div>
						</a>
					</div>
				</div >
			),
		},

	];


	return (
		<div className='pt-8 flex flex-col h-[calc(100vh_-_96px)]'>
			<div className='flex flex-col space-y-2'>
				<div className='flex items-center justify-between pr-20 mb-4'>
					<div>
						<p className='pb-1 text-4xl text-white my-1'>Withdraw Requests</p>
						<span className='text-gray-400'>Withdraw Requests With Influencer, This could take up to 2-4 business days to get to Influencers Bank Account.</span>
					</div>
				</div>
			</div>
			<div className='flex-1 pb-4 space-y-4 overflow-y-auto text-white divide-y divide-gray-500 vertical-scroll'>
				<Tabs
					selectedIndex={
						parseInt(localStorage.getItem('indexForCategory')) || 0
					}
					onSelect={(index) => {
						localStorage.setItem('indexForCategory', index);
						setTabIndex(index);
					}}
				>
					<TabList>
						<Tab>Pending</Tab>
						<Tab>Approved</Tab>
						<Tab >Declined</Tab>
						<Tab >Processed</Tab>
					</TabList>
					<TabPanel>
						{
							< DataTableBase
								columns={pendingColumns}
								data={pending}
								progressPending={loading}
								pagination

							/>
						}
					</TabPanel>
					<TabPanel>
						{
							<DataTableBase
								columns={columns}
								data={approved}
								progressPending={loading}
								pagination
							/>
						}
					</TabPanel>
					<TabPanel>
						{
							<DataTableBase
								columns={columns}
								data={declined}
								progressPending={loading}
								pagination
							/>
						}
					</TabPanel>
					<TabPanel>
						{
							<DataTableBase
								columns={columns}
								data={processed}
								progressPending={loading}
								pagination
							/>
						}
					</TabPanel>
				</Tabs>
			</div>
			<Modal
				title={'Test'}
				centered
				onCancel={() => setVisible(false)}
				footer={null}
				width={500}>
			</Modal>
		</div >
	)
}

export default WithdrawRequest
