import moment from 'moment'
import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { BsChevronDown } from 'react-icons/bs';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { fetchPostById } from '../../services/api'


function PostDetails() {
  let { id } = useParams()
  const history = useHistory()
  const [post, setPost] = useState({})

  useEffect(() => {
    const getPost = async () => {
      try {
        const { data: { data, status } } = await fetchPostById(id)
        status && setPost(data[0])
      } catch (error) {
        console.log(error)
      }
    }
    getPost()
  }, [])

  return (
    <div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
      <div className="flex items-start justify-between pr-4">
        <div className='flex items-end space-x-4'>
          <img src={post?.media}
            onError={e => { e.target.onerror = null; e.target.src = "/assets/sidebar/user.svg" }}
            className='w-16 h-16 p-1 rounded-md bg-white/10' alt="img" />
          <p className="text-2xl text-white capitalize">{post?.title}</p>
        </div>
      </div>
      <div className='flex-1 pt-10 pb-2 overflow-y-auto text-white vertical-scroll'>
        <div className='pr-2 space-y-4 divide-y divide-gray-500'>
          <div className='flex items-center justify-between pt-4'>
            <p className='w-full text-xl text-white/70'>Title :</p>
            <p className='w-full text-xl capitalize'>{post?.title}</p>
          </div>
          <div className='flex items-center justify-between pt-4'>
            <p className='w-full text-xl text-white/70'>Contest :</p>
            <div className='flex items-center w-full space-x-4'>
              <p className='overflow-hidden text-xl w-80'>{post?.contest?.tagName}</p>
              <img src={post?.media}
                onError={e => { e.target.onerror = null; e.target.src = "/assets/sidebar/user.svg" }}
                className='w-10 h-10 p-1 rounded-md bg-white/10' alt="img" />
            </div>
          </div>
          <div className='flex items-center justify-between pt-4'>
            <p className='w-full text-xl text-white/70'>Full Name</p>
            <p className='w-full text-xl'>{post?.user?.firstName} {post?.user?.lastName}</p>
          </div>
          <div className='flex items-center justify-between pt-4'>
            <p className='w-full text-xl text-white/70'>User Name :</p>
            <p className='w-full text-xl'>{post?.user?.userName}</p>
          </div>
          <div className='flex items-center justify-between pt-4'>
            <p className='w-full text-xl text-white/70'>Likes :</p>
            <p className='w-full text-xl'>{post?.likes?.likesCounter}</p>
          </div>
          <div className='flex items-start justify-between pt-4'>
            <p className='w-full text-xl capitalize text-white/70'>Description :</p>
            <p className='w-full text-xl capitalize'>{post?.description}</p>
          </div>
        </div>
        <div className='flex items-start justify-between pt-16'>
          <p className='w-full text-xl capitalize'>Comments :</p>
        </div>
        <div className='pr-2 space-y-4 divide-y divide-gray-500'>
          {
            post?.comments?.length &&
            post?.comments?.map(item => (
              <div key={item?._id} className='flex items-center justify-between pt-4 pr-1 cursor-pointer'>
                <div className='flex items-center space-x-8'>
                  <p className='text-xl capitalize text-white/70 line-clamp-1 w-80 overflow-clip'>{item?.text}</p>
                </div>
                <div className='flex space-x-8'>
                  <p className='text-xl capitalize w-96 overflow-clip'><span className='pr-8 text-sm text-white/70'>{moment(item?.date).format('h:mma')}</span> {moment(item?.date).format('d MMM, YYYY')}</p>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default PostDetails
