import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BsCheck, BsX } from 'react-icons/bs';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { InboxOutlined, DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { Form, Input, Select, Upload, InputNumber } from 'antd';
import { updateContest, fileUpload, fetchStacks, fetchContestById, fetchCategories } from '../../services/api';

import Loader from '../../components/common/Loader';
import Message from '../../components/common/Message';

const { Item } = Form
const { Option } = Select
const { Dragger } = Upload

function EditContest() {
	let { id } = useParams()
	const history = useHistory()
	const [form] = Form.useForm()
	const [stacks, setStacks] = useState([])
	const [imageUrl, setImageUrl] = useState('')
	const [imageLoading, setImageLoading] = useState(false)
	const [loading, setLoading] = useState(false)
	const [categories, setCategories] = useState([])
	const [categoryId, setCategoryId] = useState('')


	const uploadAPICall = async ({ file }) => {
		const formData = new FormData();
		formData.append('files', file);
		try {
			setImageLoading(true)
			const { data: { data, status } } = await fileUpload(formData)
			status && Message(`File upload successfully.`, true)
			status && setImageUrl(data[0].downloadHref)
			setImageLoading(false)
		} catch (error) {
			setImageLoading(false)
			console.log('error :>> ', error);
		}
	}

	const props = {
		name: 'file',
		multiple: false,
		headers: {
			authorization: "authorization-text",
			contentType: "multipart/form-data"
		},
		showUploadList: false,
		customRequest: uploadAPICall,
		onChange(info) {
			const { status } = info.file
			status !== 'uploading' && console.log(info.file, info.fileList)
			status === 'done' && Message(`${info.file.name} File upload successfully.`, true)
			status === 'error' && Message(`${info.file.name} file upload failed.`, false)
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files)
		},
	}

	useEffect(() => {
		const getStacks = async () => {
			try {
				const { data: { data, status } } = await fetchStacks()
				const { data: { data: categoryData, status: categoryStatus } } = await fetchCategories()
				status && setStacks(data)
				categoryStatus && setCategories(categoryData)
			} catch (error) {
				console.log(error)
			}
		}

		const getContestById = async () => {
			try {
				const { data: { data, status } } = await fetchContestById(id)
				if (status) {
					console.log('TEST', data)
					setCategoryId(data.category)
					setImageUrl(data.image)
					form.setFieldsValue({
						tagName: data?.tagName, titleImage: data?.titleImage, stack: data?.stack, isEnabled: data?.isEnabled, category: data?.category, duration: data?.duration
					})
				}
			} catch (error) {
				console.log(error)
			}
		}

		getStacks()
		getContestById()
	}, [])



	const onFinish = async (values) => {
		setLoading(true)
		try {
			if (imageUrl === '') {
				Message('Please upload media', false)
				setLoading(false)
				return
			}
			if (values.tagName.charAt(0) != '#') {
				Message('Cannot Create a contest without # in start', false)
				setLoading(false)
				return
			}
			values.image = imageUrl
			values.category == undefined || values.category == "" ? delete values.category && delete values.duration : delete values.stack
			const { status } = await updateContest(values, id)
			status ? Message('Contest updated successfully', true) : Message('Unable to updated contest', false)
			status && setImageUrl('')
			setLoading(false)
			history.push('/contest')
		} catch (error) {
			Message('Unable to updated Contest', false)
			setLoading(false)
			console.log(error)
		}
	}

	const get_url_extension = (url) => {
		return url.split(/[#?]/)[0].split(".").pop().trim();
	};

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			{
				loading ?
					<div className="absolute top-0 right-0 z-50 flex items-center justify-center w-full h-full">
						<Loader />
					</div>
					:
					<>
						<div>
							<p className="pb-1 text-2xl text-white">Edit Contest</p>
						</div>
						<div className='flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted'>
							<Form
								form={form}
								onFinish={async (val) => await onFinish(val)}>
								<div className='flex items-start justify-between space-x-8'>
									<div className='w-full px-2 text-lg'>
										<Item rules={[{ required: true, message: 'Tag Name is Required' }]} name='tagName'>
											<Input placeholder='Name' className='bg-transparent border border-muted text-muted' />
										</Item>

										<Item name='titleImage'>
											<Input placeholder='Image Title' className='bg-transparent border border-muted text-muted' />
										</Item>
										{
											categoryId == '' || categoryId == undefined ?
												<Item name='stack'>
													<Select className='py-1 bg-transparent border border-muted text-muted' bordered={false} placeholder="Select Stack">
														{
															stacks?.map(item => <Option className="bg-transparent" key={item?._id} value={item?._id}>{item?.name}</Option>)
														}
													</Select>
												</Item>
												:
												<div>
													<Item rules={[{ required: true, message: '' }]} name='duration'>
														<InputNumber placeholder='Duration' className='w-full bg-transparent border border-muted text-muted' />
													</Item>
													<Item name='category'>
														< Select onChange={(val) => setCategoryId(val)} className='py-1 bg-transparent border border-muted text-muted' bordered={false} placeholder="Select Category">
															<Option className="bg-transparent" value=''>Select Category</Option>
															{
																categories.map(
																	category =>
																		category.categoryName !== 'Influencer' ?
																			<Option className="bg-transparent" key={category._id} value={category._id}>{category.categoryName}</Option>
																			: null
																)
															}
														</Select>
													</Item>
												</div>
										}

										{/* <Item name='category'>
											< Select onChange={(val) => setCategoryId(val)} className='py-1 bg-transparent border border-muted text-muted' bordered={false} placeholder="Select Category">
												<Option className="bg-transparent" value=''>Select Category</Option>
												{
													categories.map(
														category => category.categoryName !== 'Influencer' ?
															<Option className="bg-transparent" key={category._id} value={category._id}>{category.categoryName}</Option> : null)
												}
											</Select>
										</Item> */}

										{/* {
											categoryId !== '' ?
												<Item rules={[{ required: true, message: '' }]} name='duration'>
													<InputNumber placeholder='Duration' className='w-full bg-transparent border border-muted text-muted' />
												</Item> : null
										}
										{
											categoryId === '' ?
												<Item name='stack'>
													<Select className='py-1 bg-transparent border border-muted text-muted' bordered={false} placeholder="Select Stack">
														{
															stacks?.map(item => <Option className="bg-transparent" key={item?._id} value={item?._id}>{item?.name}</Option>)
														}
													</Select>
												</Item>
												: null
										} */}
									</div>
									<div className='w-full'>
										<div className='h-60'>
											{
												imageLoading ?
													<Loader /> :
													imageUrl ?
														<div className='space-y-4'>
															{
																get_url_extension(imageUrl) == "mp4" ||
																	get_url_extension(imageUrl) == "MP4" ||
																	get_url_extension(imageUrl) == "mov" ||
																	get_url_extension(imageUrl) == "MOV" ? (
																	<div>
																		<video className='w-full pr-6 bg-cover h-60' autoPlay={true} controls={true} src={imageUrl}></video>
																	</div>
																) : (
																	<img src={imageUrl} className='w-full pr-6 bg-cover h-60' alt="contest" style={{ objectFit: 'contain' }} />
																)
															}
															<p onClick={() => setImageUrl('')} className='inline-flex items-baseline justify-start w-full pr-6 text-lg text-muted cursor-pointer'>
																Remove Image
																<DeleteOutlined className='px-1' style={{ color: '#fff', fontSize: '16px' }} />
															</p>
														</div> :
														<Dragger {...props}>
															<p className="ant-upload-drag-icon"><InboxOutlined style={{ fontSize: '80px', color: '#666' }} /></p>
															<p className="px-2 text-lg text-muted">Click or drag file to this area to upload</p>
														</Dragger>
											}
										</div>
									</div>
								</div>
								<div className='flex items-center w-1/2 pl-2 pr-4 space-x-4'>
									<button htmltype='submit' className='w-full py-2 btn-border border-muted'>Update</button>
									<button onClick={() => history.push('/contest')} className='w-full py-2 btn-border text-danger border-danger'>Cancel</button>
								</div>
							</Form>
						</div>
					</>
			}
		</div>
	)
}

export default EditContest
