import { useEffect, useState } from 'react';
import { fetchLevels } from '../../services/api'
import { useHistory } from 'react-router-dom'
import DataTableBase from "../../components/common/dataTable";
import { BiEdit } from "react-icons/bi";

import Message from '../../components/common/Message';
import DeleteModal from '../../components/common/DeleteModal';

function Level() {
	const history = useHistory()
	const [levels, setLevels] = useState([])
	const [totalRows, setTotalRows] = useState(0);
	const [loading, setLoading] = useState(false)
	const [limit, setLimit] = useState(10);
	const [currPage, setCurrPage] = useState(1);


	useEffect(() => {
		const getLevels = async () => {
			try {
				setLoading(true)
				const { data: { data, status } } = await fetchLevels(currPage, limit)
				status && setLevels(data.levels)
				status && setTotalRows(data.totalLevels)
				setLoading(false)
			} catch (error) {
				setLoading(false)
				console.log(error)
			}
		}
		getLevels()
	}, [currPage, limit])

	const columns = [
		{
			name: "Level Name",
			selector: (row) => row.name,
			sortable: true,

		},
		{
			name: "XPs",
			selector: (row) => row.xp,
			sortable: true,

		},
		{
			name: "Fans",
			selector: (row) => row.fans,
			sortable: true,

		},
		{
			name: "Actions",
			center: true,
			cell: (row) => (
				<a onClick={() => history.push("/level/edit/" + row?._id)}>
					<div className="flex items-center underline">
						<BiEdit /> &nbsp;Edit
					</div>
				</a>
			),
		},
	];

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			<div className="flex flex-col space-y-2">
				<div className="flex items-center justify-between pr-20">
					<div>
						<p className="pb-1 text-4xl text-white">Levels</p>
						<p className="text-muted">{totalRows} Levels</p>
					</div>
					<button onClick={() => history.push('/level/new')} className="btn-border">Add Level</button>
					{/* <button onClick={() => history.push('/level/new')} className="btn-border">Bulk Upload</button> */}
				</div>
			</div>

			<div className='flex-1 pb-4 space-y-4 overflow-y-auto text-white divide-y divide-gray-500 vertical-scroll'>
				{
					<DataTableBase
						columns={columns}
						data={levels}
						progressPending={loading}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={(newLimit, page) => {
							setLimit(newLimit);
							setCurrPage(page);
						}}
						onChangePage={(page) => setCurrPage(page)}
						paginationComponentOptions={{
							rowsPerPageText: "Items per Page : ",
							rangeSeparatorText: "-",
							selectAllRowsItem: true,
							selectAllRowsItemText: "All",
						}}
					/>
				}
			</div>
		</div >
	)
}

export default Level
