import { useContext } from 'react'
import { Switch } from 'react-router-dom';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../context/Auth';

function PrivateNestedRoute({ children, ...rest }) {
  const auth = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.token || localStorage.getItem('token') ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default PrivateNestedRoute;