import { BsSortDown } from "react-icons/bs";
import DataTable, { createTheme } from "react-data-table-component";
const sortIcon = <BsSortDown />;

function DataTableBase(props) {
	createTheme(
		"solarized",
		{
			text: {
				primary: "#fff",
				secondary: "#fff",
			},
			background: {
				default: "transparent",
			},
			context: {
				background: "#cb4b16",
				text: "#FFFFFF",
			},
			divider: {
				default: "#acacac6a",
			},
			action: {
				button: "rgba(0,0,0,.54)",
				hover: "rgba(0,0,0,.08)",
				disabled: "rgba(0,0,0,.12)",
			},
		},
		"dark"
	);

	const customStyles = {
		rows: {
			style: {
				minHeight: "72px", // override the row height
			},
		},
		headCells: {
			style: {
				paddingLeft: "12px", // override the cell padding for head cells
				paddingRight: "12px",
			},
		},
		cells: {
			style: {
				paddingLeft: "12px", // override the cell padding for data cells
				paddingRight: "12px",
			},
		},
	};

	return (
		<DataTable
			theme="solarized"
			customStyles={customStyles}
			sortIcon={null}
			paginationComponentOptions={{
				rowsPerPageText: "Items per Page : ",
				rangeSeparatorText: "-",
				selectAllRowsItem: true,
				selectAllRowsItemText: "All",
			}}
			{...props}
		/>
	);
}

export default DataTableBase;
