import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Input, Select, Upload, InputNumber } from 'antd';
import { updateLevelRange, fetchLevelRangeById } from '../../services/api';
import { DownOutlined } from '@ant-design/icons';

import Loader from '../../components/common/Loader';
import Message from '../../components/common/Message';

const { Item } = Form
const { Option } = Select


function EditStack() {
	let { id } = useParams()
	const history = useHistory()
	const [form] = Form.useForm()
	const [loading, setLoading] = useState(false)

	const onFinish = async (values) => {
		try {
			setLoading(true)

			values.entryFeeUSD = values.entryFeeUSD.$numberDecimal;

			const { status } = await updateLevelRange(values, id)
			status ? Message('Level updated successfully', true) : Message('Unable to updated Level', false)
			setLoading(false)
			history.push('/level-range')
		} catch (error) {
			Message('Unable to updated Level', false)
			setLoading(false)
			console.log(error)
		}
	}

	useEffect(() => {
		const getLevelById = async () => {
			try {
				const { data: { data, status } } = await fetchLevelRangeById(id)
				if (status) {
					data.entryFeeUSD = data?.entryFeeUSD?.$numberDecimal ? data?.entryFeeUSD.$numberDecimal : data?.entryFeeUSD;
					form.setFieldsValue({ levelName: data?.levelName, levelLimit: data?.levelLimit, xpLimit: data?.xpLimit, category: data?.category, influencerLevel: data?.influencerLevel, entryFeeCoins: data?.entryFeeCoins, entryFeeUSD: data.entryFeeUSD })
				}
			} catch (error) {
				console.log(error)
			}
		}

		getLevelById()
	}, [])

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			{
				loading ?
					<div className="absolute top-0 right-0 z-50 flex items-center justify-center w-full h-full">
						<Loader />
					</div>
					:
					<>
						<div>
							<p className="pb-1 text-2xl text-white">Edit Level</p>
						</div>
						<div className='flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted'>
							<Form form={form} onFinish={async (val) => await onFinish(val)}>
								<div className='flex items-start justify-between space-x-8'>
									<div className=' w-1/2 px-2 text-lg'>
										{/* <Item name='levelName'>
											<Input placeholder='Level Name' className='bg-transparent border border-muted text-muted' />
										</Item>
										<Item name='levelLimit'>
											<Input type='number' min={0} placeholder='Level Limit' className='bg-transparent border border-muted text-muted' />
										</Item>
										<Item name='xpLimit'>
											<InputNumber type='number' min={0} placeholder='XP Limit' className='w-full bg-transparent border border-muted text-muted' />
										</Item>
										<Item name='influencerLevel'>
											<InputNumber type='number' min={0} placeholder='Influencer Level' className='w-full bg-transparent border border-muted text-muted' />
										</Item>
										<Item name='category'>
											<Select className='py-1 bg-transparent border border-muted text-muted' bordered={false} placeholder="Select Category">
												{
													<Option className="bg-transparent" value="Talents">Talents</Option>
												}
											</Select>
										</Item> */}
										<Item
											rules={[{ required: true, message: "Level Name is required" }]}
											name="levelName"
										>
											<Input
												placeholder="Level Name"
												className="w-full py-1 bg-transparent border border-muted text-muted"
											/>
										</Item>
										<Item
											rules={[{ required: true, message: "Level Limit is required" }]}
											name="levelLimit"
										>
											<Input
												type='number'
												min={0}
												placeholder="Level Limit"
												className="w-full py-1 bg-transparent border border-muted text-muted"
											/>
										</Item>
										<Item
											rules={[{ required: true, message: "XP Limit is required" }]}
											name="xpLimit"
										>
											<Input
												type='number'
												min={0}
												placeholder="XP Limit"
												className="w-full py-1 bg-transparent border border-muted text-muted"
											/>
										</Item>
										<Item
											rules={[{ required: true, message: "Category is required" }]}
											name="category"
										>
											<Select className='py-1 bg-transparent border border-muted text-muted' bordered={false} placeholder="Select Category">
												{
													<Option className="bg-transparent" value="Talents">Talents</Option>
												}
											</Select>
										</Item>
										<Item
											rules={[{ required: true, message: "Influencer Level is required" }]}
											name="influencerLevel"
										>
											<Input
												type='number'
												min={0}
												placeholder="Influencer Level"
												className="w-full py-1 bg-transparent border border-muted text-muted"
											/>
										</Item>
										<Item
											rules={[{ required: true, message: "Entry Fee Coins is required" }]}
											name="entryFeeCoins"
										>
											<Input
												type="number"
												min={-1}
												placeholder="Entry Fee Coins"
												className="bg-transparent border border-muted text-muted"
											/>
										</Item>

										<Item
											rules={[{ required: true, message: "Entry Fee USD is Required" }]}
											name="entryFeeUSD"
										>
											<Input
												type="number"
												min={-1}
												step="0.01"
												placeholder="Entry Fee USD"
												className="w-full py-1 bg-transparent border border-muted text-muted"
											/>
										</Item>
									</div>
								</div>
								<div className='flex items-center w-1/2 pl-2 pr-4 space-x-4'>
									<button htmltype='submit' className='w-full py-2 btn-border border-muted'>Update</button>
									<button onClick={() => history.push('/level-range')} className='w-full py-2 btn-border text-danger border-danger'>Cancel</button>
								</div>
							</Form>
						</div>
					</>
			}
		</div>
	)
}

export default EditStack
