import AppRoutes from './routes'
import ProvideAuth from './context/Auth'
// Main App
function App() {
  return (
    <div id="scroller" className="h-screen overflow-y-auto vertical-scroll">
      <ProvideAuth>
        <AppRoutes />
      </ProvideAuth>
    </div>
  );
}

export default App;
