import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';

const Comp_404 = () => {
  const history = useHistory();

  return <Result
    status="404"
    title="404"
    subTitle="Sorry, the page does not exist."
    extra={<Button onClick={() => history.goBack()} className='border-0 outline-none bg-dark-body' type="primary">Go Back</Button>}
  />

}


export default Comp_404;