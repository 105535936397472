import { useHistory } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";

function AppHeader() {
	const history = useHistory();

	const goToPreviousPath = (e) => {
		e.preventDefault();
		history.goBack();
	};

	return (
		<div className="flex justify-between">
			{history.location.pathname == "/" ? (
				<div className="flex items-center justify-center text-white"></div>
			) : (
				<div
					className="flex items-center justify-center text-white"
					onClick={goToPreviousPath}
					style={{ fontSize: "14px", cursor: "pointer" }}
				>
					<MdArrowBack />
					<p>&nbsp; Go back</p>
				</div>
			)}
			<div className="flex items-center justify-between text-white">
				<div className="flex justify-end w-full space-x-4">
					<div
						onClick={() => history.push("/notification-message/broadcast")}
						className="flex items-center p-2 cursor-pointer justify-center rounded-lg bg-[#111111] w-12 h-10"
					>
						<img src="/assets/bell.svg" className="w-10 h-10" alt="bell" />
					</div>
					<div className="flex items-center p-2 justify-center rounded-lg bg-[#111111] w-12 h-10">
						<img src="/assets/w.svg" className="w-10 h-10" alt="w" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default AppHeader;
