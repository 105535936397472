import { useState } from "react";
import { Form, Input, Upload, Select, InputNumber } from "antd";
import { createLevelRange } from "../../services/api";
import { DownOutlined } from '@ant-design/icons';



import Message from "../../components/common/Message";
import Loader from "../../components/common/Loader";
import { useHistory } from "react-router-dom";

const { Item } = Form;
const { Option } = Select
const { Dragger } = Upload;

function NewStack() {
	const history = useHistory();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const onFinish = async (values) => {
		try {
			setLoading(true);
			const { status } = await createLevelRange(values);
			status
				? Message("Level Range created successfully", true)
				: Message("Level Range Already Exists", false);
			form.resetFields();
			setLoading(false);
		} catch (error) {
			Message("Level Range Already Exists", false);
			setLoading(false);
			console.log(error);
		}
	};

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			{loading ? (
				<div className="absolute top-0 right-0 z-50 flex items-center justify-center w-full h-full">
					<Loader />
				</div>
			) : (
				<>
					<div>
						<p className="pb-1 text-2xl text-white">Add Level</p>
					</div>
					<div className="flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted">
						<Form
							form={form}
							layout="horizontal"
							onFinish={async (val) => await onFinish(val)}
							initialValues={{
								levelName: undefined,
								xpLimit: undefined,
								levelLimit: undefined,
								category: undefined,
								influencerLevel: undefined,
								entryFeeCoins: undefined,
								entryFeeUSD: undefined
							}}
						>
							<div className="flex items-start justify-between space-x-8 w-1/2">
								<div className="w-full px-2 text-lg">
									<Item
										rules={[{ required: true, message: "Level Name is required" }]}
										name="levelName"
									>
										<Input
											placeholder="Level Name"
											className="w-full py-1 bg-transparent border border-muted text-muted"
										/>
									</Item>
									<Item
										rules={[{ required: true, message: "Level Limit is required" }]}
										name="levelLimit"
									>
										<Input
											type='number'
											min={0}
											placeholder="Level Limit"
											className="w-full py-1 bg-transparent border border-muted text-muted"
										/>
									</Item>
									<Item
										rules={[{ required: true, message: "XP Limit is required" }]}
										name="xpLimit"
									>
										<Input
											type='number'
											min={0}
											placeholder="XP Limit"
											className="w-full py-1 bg-transparent border border-muted text-muted"
										/>
									</Item>
									<Item
										rules={[{ required: true, message: "Category is required" }]}
										name="category"
									>
										<Select className='py-1 bg-transparent border border-muted text-muted' bordered={false} placeholder="Select Category">
											{
												<Option className="bg-transparent" value="Talents">Talents</Option>
											}
										</Select>
									</Item>
									<Item
										rules={[{ required: true, message: "Influencer Level is required" }]}
										name="influencerLevel"
									>
										<Input
											type='number'
											min={0}
											placeholder="Influencer Level"
											className="w-full py-1 bg-transparent border border-muted text-muted"
										/>
									</Item>
									<Item
										rules={[{ required: true, message: "Entry Fee Coins is required" }]}
										name="entryFeeCoins"
									>
										<Input
											type="number"
											min={-1}
											placeholder="Entry Fee Coins"
											className="bg-transparent border border-muted text-muted"
										/>
									</Item>

									<Item
										rules={[{ required: true, message: "Entry Fee USD is Required" }]}
										name="entryFeeUSD"
									>
										<Input
											type="number"
											min={-1}
											step="0.01"
											placeholder="Entry Fee USD"
											className="w-full py-1 bg-transparent border border-muted text-muted"
										/>
									</Item>

								</div>
							</div>
							<div className="flex items-center w-1/2 pl-2 pr-4 space-x-4">
								<button
									htmltype="submit"
									className="w-full py-2 btn-border border-muted"
								>
									Create
								</button>
								<button
									onClick={() => history.push("/level-range")}
									className="w-full py-2 btn-border text-danger border-danger"
								>
									Cancel
								</button>
							</div>
						</Form>
					</div>
				</>
			)}
		</div>
	);
}

export default NewStack;
