/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { fetchTalentCategories, deleteTalentCategory, deleteTalentCategories } from '../../services/api'
import { useHistory } from 'react-router-dom'
import DataTableBase from "../../components/common/dataTable";
import { BiEdit, BiTrash } from "react-icons/bi";
import ModalImage from "react-modal-image";
import { Modal } from "antd";


import Message from '../../components/common/Message';
import DeleteModal from '../../components/common/DeleteModal';
import { BsFillCameraVideoFill } from 'react-icons/bs';

function TalentCategory() {
	const history = useHistory()
	const [talentCategories, setTalentCategories] = useState([])
	const [totalRows, setTotalRows] = useState(0);
	const [loading, setLoading] = useState(false)
	const [limit, setLimit] = useState(10);
	const [currPage, setCurrPage] = useState(1);
	const [visibleDelete, setVisibleDelete] = useState(false)
	const [deletedRecordId, setDeletedRecordId] = useState(null)
	const [videoURL, setVideoURL] = useState("");



	useEffect(() => {
		const getCategoreis = async () => {
			try {
				setLoading(true)
				const { data: { data, status } } = await fetchTalentCategories()
				status && setTalentCategories(data)
				status && setTotalRows(data.length)
				setLoading(false)
			} catch (error) {
				setLoading(false)
				console.log(error)
			}
		}
		getCategoreis()
	}, [currPage, limit, visibleDelete])

	const get_url_extension = (url) => {
		if (url !== undefined) {
			return url.split(/[#?]/)[0].split(".").pop().trim();
		}
		return;
	};

	const columns = [
		{
			name: "Category Name",
			selector: (row) => row.title,
			sortable: true,

		},
		{
			name: "Category Name",
			selector: (row) => row.orderNumber,
			sortable: true,

		},
		{
			name: "Media",
			cell: (row) =>
				get_url_extension(row.image) == "mp4" ||
					get_url_extension(row.image) == "MP4" ||
					get_url_extension(row.image) == "mov" ||
					get_url_extension(row.image) == "MOV" ? (
					<div>
						<BsFillCameraVideoFill
							style={{ height: "25px", width: "25px", margin: "auto" }}
							onClick={() => {
								setVideoURL(row.image);
							}}
						/>
					</div>
				) : (
					<ModalImage
						small={row.image}
						large={row.image}
						className="w-8 h-8 p-1 rounded-md bg-white/10"
						alt=""
					/>
				),
		},
		{
			name: "Actions",
			center: true,
			selector: (row) => row._id,

			cell: (row) => (
				<div className='flex'>
					<a onClick={() => history.push("/talent-categories/edit/" + row?._id)}>
						<div className="flex items-center underline">
							<BiEdit /> &nbsp;Edit
						</div>
					</a>
					<a className="underline mx-1" onClick={() => deleteRecord(row?._id)}>
						<div className="flex items-center">
							<BiTrash /> &nbsp;Delete
						</div>
					</a>
				</div>
			),
		},
	];

	const confirmDelete = async () => {
		try {
			const { status } = await deleteTalentCategories(deletedRecordId)
			status ? Message('Deleted successfully', true) : Message('Unable to delete', false)
			setVisibleDelete(false)
		} catch (error) {
			Message('Unable to delete', false)
			console.log(error)
		}
	}

	const deleteRecord = (id) => {
		setDeletedRecordId(id)
		setVisibleDelete(true)
	}

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			<DeleteModal
				title='Contest'
				setVisible={setVisibleDelete}
				confirmDelete={confirmDelete}
				visible={visibleDelete} />
			<div className="flex flex-col space-y-2">
				<div className="flex items-center justify-between pr-20">
					<div>
						<p className="pb-1 text-4xl text-white">Talent Categories</p>
						<p className="text-muted">{totalRows} Categories</p>
					</div>
					<button onClick={() => history.push('/talent-categories/new')} className="btn-border">Add Category</button>
				</div>
			</div>

			<div className='flex-1 pb-4 space-y-4 overflow-y-auto text-white divide-y divide-gray-500 vertical-scroll'>
				{
					<DataTableBase
						columns={columns}
						data={talentCategories}
						progressPending={loading}
						pagination
					// paginationServer
					// paginationTotalRows={totalRows}
					// onChangeRowsPerPage={(newLimit, page) => {
					// 	setLimit(newLimit);
					// 	setCurrPage(page);
					// }}
					// onChangePage={(page) => setCurrPage(page)}
					// paginationComponentOptions={{
					// 	rowsPerPageText: "Items per Page : ",
					// 	rangeSeparatorText: "-",
					// 	selectAllRowsItem: true,
					// 	selectAllRowsItemText: "All",
					// }}
					/>
				}
			</div>
			<Modal
				style={{ top: 20 }}
				centered
				visible={videoURL !== ""}
				onCancel={() => setVideoURL("")}
				footer={null}
			>
				<video autoPlay={true} controls={true} src={videoURL}></video>
			</Modal>
		</div >
	)
}

export default TalentCategory
