import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { sendEmailApi, verifyOtpApi } from '../../services/api';
import DeleteModal from './modal';

function DeleteAccount() {
	const history = useHistory();
	const [email, setEmail] = useState('');
	const [otp, setOtp] = useState('');
	const [isOtpPopupOpen, setIsOtpPopupOpen] = useState(false);
	const [message, setMessage] = useState('');

	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	};

	const handleOtpChange = (e) => {
		setOtp(e.target.value);
	};

	const handleEmailSubmit = async (e) => {
		e.preventDefault();
		try {
			const {
				data: { data, status },
			} = await sendEmailApi({ email });

			if (status) {
				setIsOtpPopupOpen(true);
				setMessage('OTP sent to your email.');
			}
		} catch (error) {
			setMessage('Failed to send OTP. Please try again.');
		}
	};

	const handleOtpSubmit = async (e) => {
		e.preventDefault();
		try {
			console.log('email :>> ', email);
			const {
				data: { data, status },
			} = await verifyOtpApi({ email, otp });
			if (status) {
				setMessage('Account Deleted Successfully.');
				setIsOtpPopupOpen(false);
				setEmail("");
				setOtp("");
			}
		} catch (error) {
			console.log('error :>> ', error);
			setMessage('Invalid OTP. Please try again.');
		}
	};

	return (
		<div className="flex items-center justify-center h-screen bg-black">
			<div className="bg-dark p-8 rounded shadow-lg w-full max-w-md">
				<img src="/assets/logo.svg" alt="wooo" style={{ height: '70px', marginBottom: '1rem', margin: '20px auto', padding: '10px' }} />
				<p className="pb-4 text-2xl text-center text-white">Delete Account</p>

				<form onSubmit={handleEmailSubmit} className="space-y-4">
					<div>
						<label className="block text-white">Email</label>
						<input
							type="email"
							value={email}
							onChange={handleEmailChange}
							className="w-full px-4 py-2 text-white border border-gray-300 rounded"
							required
						/>
					</div>
					<button type="submit" className="w-full px-4 py-2 bg-blue-500 text-white rounded">Send OTP</button>
				</form>

				{message && (
					<p className="mt-4 text-center text-yellow-500">{message}</p>
				)}

				{isOtpPopupOpen && (
					<DeleteModal isOpen={isOtpPopupOpen}>
						<form onSubmit={handleOtpSubmit} className="space-y-4">
							<div>
								<label className="block text-black">OTP</label>
								<input
									type="text"
									value={otp}
									onChange={handleOtpChange}
									className="w-full px-4 py-2 text-black border border-gray-300 rounded"
									required
								/>
							</div>
							<button type="submit" className="w-full px-4 py-2 bg-blue-500 text-white rounded">Verify OTP</button>
							<button onClick={() => setIsOtpPopupOpen(false)} className="w-full mt-2 px-4 py-2 bg-red-500 text-white rounded">Close</button>
						</form>
					</DeleteModal>
				)}
			</div>
		</div>
	);
}

export default DeleteAccount;
