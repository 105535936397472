import { Switch, Route } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import PrivateNestedRoute from "./PrivateNestedRoute";
import AppLayout from "../layout";

import Login from "../pages/Login";
import Home from "../pages/Home";

import Stack from "../pages/Stack";
import NewStack from "../pages/Stack/new";
import EditStack from "../pages/Stack/edit";
import StackDetails from "../pages/Stack/details";

import Contest from "../pages/Contest";
import NewContest from "../pages/Contest/new";
import EditContest from "../pages/Contest/edit";
import EditSuperContest from "../pages/Contest/superEdit";
import ContestDetails from "../pages/Contest/details";

import TalentCategories from '../pages/Talent Categroies/index'
import TalentCategoriesEdit from '../pages/Talent Categroies/edit'
import TalentCategoriesNew from '../pages/Talent Categroies/new'

import TalentContests from "../pages/Contest/talent-contests/";


import SuperContestChild from "../pages/Contest/Child";
import NewSuperContestChild from "../pages/Contest/Child/new";
import EditSuperContestChild from "../pages/Contest/Child/edit";
import DetailsSuperContestChild from "../pages/Contest/Child/details";

import User from "../pages/User";
import EditUser from "../pages/User/edit";
import UserDetails from "../pages/User/details";
import UserPost from "../pages/User/post";
import UserReports from "../pages/User/User Reports/index"

import Reward from "../pages/Reward";
import EditReward from "../pages/Reward/edit";
import NewReward from "../pages/Reward/new";

import Level from "../pages/Level";
import EditLevel from "../pages/Level/edit";
import NewLevel from "../pages/Level/new";

import Notification from "../pages/Notification";
import EditNotification from "../pages/Notification/edit";
import NewNotification from "../pages/Notification/new";
import BroadcastNotification from "../pages/Notification/broadcast";


import ScheduleNotification from "../pages/Schedule Notification";
import EditScheduleNotification from "../pages/Schedule Notification/edit";
import NewScheduleNotification from "../pages/Schedule Notification/new";

import Report from "../pages/Report";
import ReportPost from "../pages/Report/post";
import ReportUser from "../pages/Report/user";

import PostDetails from "../pages/Post/details";

import Winner from "../pages/Winners/index";

import ActionAnalytics from "../pages/Graphs/action-analytics";
import LevelProgression from "../pages/Graphs/level-progression";
import UsersMap from "../pages/Graphs/users-cluster-map";
import InfluencerTable from "../pages/User/influencers/influencer";

import LevelRange from "../pages/Level-range/index";
import NewLevelRange from "../pages/Level-range/new";
import EditLevelRange from "../pages/Level-range/edit";

import WithdrawRequest from "../pages/Withdraw Request/index";
import UserReferralReport from "../pages/User/User Referral/index"
// import NewLevelRange from "../pages/Level-range/new";
// import EditLevelRange from "../pages/Level-range/edit";



import Comp404 from "../components/404";
import ContestActivity from "../pages/Contest/contest-activity";
import DeleteAccount from "../pages/Delete-User";

export default function AppRoutes() {

	return (
		<Switch>

			{/* App layout pages */}
			<PrivateRoute exact path="/">
				<AppLayout component={<Home />} />
			</PrivateRoute>

			<Route path="/stack"
				render={({ match: { url } }) => (
					<Switch>
						<PrivateNestedRoute exact path={`${url}/`}>
							<AppLayout component={<Stack />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/new`}>
							<AppLayout component={<NewStack />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/:id`}>
							<AppLayout component={<StackDetails />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/edit/:id`}>
							<AppLayout component={<EditStack />} />
						</PrivateNestedRoute>
					</Switch>
				)}
			/>

			<Route path="/contest"
				render={({ match: { url } }) => (
					<Switch>
						<PrivateNestedRoute exact path={`${url}/`}>
							<AppLayout component={<Contest />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/new`}>
							<AppLayout component={<NewContest />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/:id`}>
							<AppLayout component={<ContestDetails />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/edit/:id`}>
							<AppLayout component={<EditContest />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/superedit/:id`}>
							<AppLayout component={<EditSuperContest />} />
						</PrivateNestedRoute>
					</Switch>
				)}
			/>
			<Route path="/talent-categories"
				render={({ match: { url } }) => (
					<Switch>
						<PrivateNestedRoute exact path={`${url}/`}>
							<AppLayout component={<TalentCategories />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/new`}>
							<AppLayout component={<TalentCategoriesNew />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/edit/:id`}>
							<AppLayout component={<TalentCategoriesEdit />} />
						</PrivateNestedRoute>
					</Switch>
				)}
			/>

			<Route path="/talent-contests"
				render={({ match: { url } }) => (
					<Switch>
						<PrivateNestedRoute exact path={`${url}/`}>
							<AppLayout component={<TalentContests />} />
						</PrivateNestedRoute>
					</Switch>
				)}
			/>


			<Route path="/child"
				render={({ match: { url } }) => (
					<Switch>
						<PrivateNestedRoute exact path={`${url}/:id`}>
							<AppLayout component={<SuperContestChild />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/new/:id`}>
							<AppLayout component={<NewSuperContestChild />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/edit/:id`}>
							<AppLayout component={<EditSuperContestChild />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/contest/:id`}>
							<AppLayout component={<DetailsSuperContestChild />} />
						</PrivateNestedRoute>
					</Switch>
				)}
			/>
			<Route path="/notification-message"
				render={({ match: { url } }) => (
					<Switch>
						<PrivateNestedRoute exact path={`${url}`}>
							<AppLayout component={<Notification />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/new`}>
							<AppLayout component={<NewNotification />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/edit/:id`}>
							<AppLayout component={<EditNotification />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/broadcast`}>
							<AppLayout component={<BroadcastNotification />} />
						</PrivateNestedRoute>
					</Switch>
				)}
			/>

			<Route path="/schedule-notification"
				render={({ match: { url } }) => (
					<Switch>
						<PrivateNestedRoute exact path={`${url}`}>
							<AppLayout component={<ScheduleNotification />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/new`}>
							<AppLayout component={<NewScheduleNotification />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/edit/:id`}>
							<AppLayout component={<EditScheduleNotification />} />
						</PrivateNestedRoute>
					</Switch>
				)}
			/>


			<Route path="/user"
				render={({ match: { url } }) => (
					<Switch>
						<PrivateNestedRoute exact path={`${url}/`}>
							<AppLayout component={<User />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/:id`}>
							<AppLayout component={<UserDetails />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/edit/:id`}>
							<AppLayout component={<EditUser />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/post/:id`}>
							<AppLayout component={<UserPost />} />
						</PrivateNestedRoute>
					</Switch>
				)}
			/>

			<Route path="/reward"
				render={({ match: { url } }) => (
					<Switch>
						<PrivateNestedRoute exact path={`${url}/`}>
							<AppLayout component={<Reward />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/new`}>
							<AppLayout component={<NewReward />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/edit/:id`}>
							<AppLayout component={<EditReward />} />
						</PrivateNestedRoute>
					</Switch>
				)}
			/>

			<Route path="/level"
				render={({ match: { url } }) => (
					<Switch>
						<PrivateNestedRoute exact path={`${url}/`}>
							<AppLayout component={<Level />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/new`}>
							<AppLayout component={<NewLevel />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/edit/:id`}>
							<AppLayout component={<EditLevel />} />
						</PrivateNestedRoute>
					</Switch>
				)}
			/>

			<Route path="/winner"
				render={({ match: { url } }) => (
					<Switch>
						<PrivateNestedRoute exact path={`${url}/`}>
							<AppLayout component={<Winner />} />
						</PrivateNestedRoute>
					</Switch>
				)}
			/>

			<Route path="/report"
				render={({ match: { url } }) => (
					<Switch>
						<PrivateNestedRoute exact path={`${url}`}>
							<AppLayout component={<Report />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/post`}>
							<AppLayout component={<ReportPost />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/user`}>
							<AppLayout component={<ReportUser />} />
						</PrivateNestedRoute>
					</Switch>
				)}
			/>

			<Route path="/level-range"
				render={({ match: { url } }) => (
					<Switch>
						<PrivateNestedRoute exact path={`${url}/`}>
							<AppLayout component={<LevelRange />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/new`}>
							<AppLayout component={<NewLevelRange />} />
						</PrivateNestedRoute>
						<PrivateNestedRoute exact path={`${url}/edit/:id`}>
							<AppLayout component={<EditLevelRange />} />
						</PrivateNestedRoute>
					</Switch>
				)}
			/>

			<Route path="/post"
				render={({ match: { url } }) => (
					<Switch>
						<PrivateNestedRoute exact path={`${url}/:id`}>
							<AppLayout component={<PostDetails />} />
						</PrivateNestedRoute>
					</Switch>
				)}
			/>

			<Route path="/user-referral"
				render={({ match: { url } }) => (
					<Switch>
						<PrivateNestedRoute exact path={`${url}/`}>
							<AppLayout component={<UserReferralReport />} />
						</PrivateNestedRoute>
					</Switch>
				)}
			/>

			<Route path="/contest-activity"
				render={({ match: { url } }) => (
					<Switch>
						<PrivateNestedRoute exact path={`${url}/`}>
							<AppLayout component={<ContestActivity />} />
						</PrivateNestedRoute>
					</Switch>
				)}
			/>


			<Route path="/action-analytics"
				render={({ match: { url } }) => (
					<Switch>
						<PrivateNestedRoute exact path={`${url}/`}>
							<AppLayout component={<ActionAnalytics />} />
						</PrivateNestedRoute>
					</Switch>
				)}
			/>


			<Route path="/level-progression"
				render={({ match: { url } }) => (
					<Switch>
						<PrivateNestedRoute exact path={`${url}/`}>
							<AppLayout component={<LevelProgression />} />
						</PrivateNestedRoute>
					</Switch>
				)}
			/>

			<Route path="/users-location"
				render={({ match: { url } }) => (
					<Switch>
						<PrivateNestedRoute exact path={`${url}/`}>
							<AppLayout component={<UsersMap />} />
						</PrivateNestedRoute>
					</Switch>
				)}
			/>


			<Route path="/influencer-users"
				render={({ match: { url } }) => (
					<Switch>
						<PrivateNestedRoute exact path={`${url}/`}>
							<AppLayout component={<InfluencerTable />} />
						</PrivateNestedRoute>
					</Switch>
				)}
			/>

			<Route path="/user-reports"
				render={({ match: { url } }) => (
					<Switch>
						<PrivateNestedRoute exact path={`${url}/`}>
							<AppLayout component={<UserReports />} />
						</PrivateNestedRoute>
					</Switch>
				)}
			/>

			<Route path="/contest-activity"
				render={({ match: { url } }) => (
					<Switch>
						<PrivateNestedRoute exact path={`${url}/`}>
							<AppLayout component={<ContestActivity />} />
						</PrivateNestedRoute>
					</Switch>
				)}
			/>

			<Route path="/withdraw-request"
				render={({ match: { url } }) => (
					<Switch>
						<PrivateNestedRoute exact path={`${url}/`}>
							<AppLayout component={<WithdrawRequest />} />
						</PrivateNestedRoute>
					</Switch>
				)}
			/>

			{/* Without layout pages */}
			<PublicRoute exact path="/login">
				<Login />
			</PublicRoute>
			<PublicRoute exact path="/delete-user">
				<DeleteAccount />
			</PublicRoute>
			<PublicRoute path="*">
				<Comp404 />
			</PublicRoute>
		</Switch>
	);
}
