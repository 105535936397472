import { Layout } from 'antd'

import AppMenu from './AppMenu'
import AppHeader from './AppHeader'

const { Content, Sider } = Layout

export default function AppLayout({ component }) {

  return (
    <Layout className="min-h-full">
      <Sider
        width={230}
        collapsible
        trigger={null}
        collapsedWidth={60}
        className="bg-[#2d3238]">
        <AppMenu />
      </Sider>
      <Layout className="main-bg py-6 px-8">
        <AppHeader />
        <Content>
          <div className=''>{component}</div>
        </Content>
      </Layout>
    </Layout>
  )
}
