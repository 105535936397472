import { useEffect, useState } from "react";
import CanvasJSReact from '../../components/libs/canvasjs.react';
import { fetchuserActionChart } from "../../services/api";
import { Select } from "antd";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function ActionAnalytics() {
	const [graphData, setGraphData] = useState([]);
	const [actionsList, setActionsList] = useState([]);
	const [keys, setKeys] = useState([]);

	useEffect(() => {
		const getChartData = async () => {
			const {
				data: { data, status },
			} = await fetchuserActionChart({ actions: keys, view: "analytics-page" });
			if (status) {
				setGraphData(data.actionData);
				if (actionsList.length < 1) setActionsList(data.key);
			}
		};
		getChartData();
	}, [keys]);

	CanvasJS.addColorSet("white",
		[
			"#ffffff",
			"#fff344",
			"#fffdfg",
			"#fffdfg",
			"#fffdfg",
		]);

	const options = {
		backgroundColor: "rgba(255, 255, 255, 0)",
		theme: "dark1",
		colorSet: "white",
		animationEnabled: true,
		axisY: {
			title: "Number Of Users",
			// padding: 30
		},
		axisX: {
			title: "Action Type",
		},
		toolTip: {
			shared: true
		},
		data: [
			{
				type: "spline",
				name: "First Action",
				showInLegend: true,
				dataPoints: graphData.length > 0 ? graphData[0] : []
			},
			{
				type: "spline",
				name: "Second Action",
				showInLegend: true,
				dataPoints: graphData.length > 0 ? graphData[1] : []
			}
		]
	}

	return (

		<div className="overflow-auto pt-8 flex flex-col">
			<div className="flex flex-col space-y-2">
				<div className="flex items-center justify-between pr-20">
					<div>
						<p className="pb-1 text-2xl text-white">User Actions Analytics</p>
						<Select
							className="my-4"
							mode="multiple"
							allowClear
							style={{
								width: "100%",
							}}
							placeholder="Select minimum 3 actions"
							onChange={(val) => setKeys(val)}
							options={actionsList}
						/>
					</div>
				</div>
			</div>

			<div className="flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted ">
				<CanvasJSChart options={options} containerProps={{ height: "400px", width: '100%' }} />
			</div>
		</div>
	);
}

export default ActionAnalytics;
