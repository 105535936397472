/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { RiSearch2Line } from "react-icons/ri";
import { BiEdit, BiTrash } from "react-icons/bi";
import { scheduleNotification, scheduleNotificationUpdate, scheduleNotificationDelete } from "../../services/api";
import DataTableBase from "../../components/common/dataTable";
import { BsCheck, BsX } from "react-icons/bs";
import { Switch } from "antd";


import DeleteModal from '../../components/common/DeleteModal';
import Message from "../../components/common/Message";

function ScheduleNotification() {
	const history = useHistory();
	const [search, setSearch] = useState("");
	const [notifications, setNotification] = useState([]);
	const [totalNotifications, setTotalNotifications] = useState(0);
	const [isActive, setIsActive] = useState(false);
	const [loading, setLoading] = useState(false);
	const [deletedRecordId, setDeletedRecordId] = useState(null)
	const [visibleDelete, setVisibleDelete] = useState(false)

	const filteredNotification = notifications.filter((notification) => {
		return notification?.message.toLowerCase().includes(search.toLowerCase());
	});

	useEffect(() => {
		const getScheduleNotification = async () => {
			try {
				const {
					data: { data, status },
				} = await scheduleNotification();
				status && setNotification(data.notifications);
				status && setTotalNotifications(data.totalRecords);
			} catch (error) {
				console.log(error);
			}
		};
		getScheduleNotification();
	}, [isActive, visibleDelete]);


	const handleEnable = async (id, active, data) => {
		try {
			const { status } = await scheduleNotificationUpdate({ isActive: !active, scheduleTime: data.scheduleTime }, id);
			setIsActive(!active);
			status ? Message("Schedule Notification updated successfully", true) : Message("Unable to updated Schedule Notification", false);
		} catch (error) {
			Message("Unable to updated User", false);
			console.log(error);
		}
	};

	const confirmDelete = async () => {
		try {
			const { status } = await scheduleNotificationDelete(deletedRecordId);
			status
				? Message("Deleted successfully", true)
				: Message("Unable to delete", false);
			setVisibleDelete(false);
		} catch (error) {
			Message("Unable to delete", false);
			console.log(error);
		}
	};

	const deleteRecord = (id) => {
		setDeletedRecordId(id)
		setVisibleDelete(true)
	}


	const columns = [
		{
			name: "Title",
			selector: (row) => row?.title || "N/A",
			sortable: true,

			wrap: true,
		},
		{
			name: "Message",
			selector: (row) => row.message,
			sortable: true,

			wrap: true,
		},
		{
			name: "Time",
			selector: (row) => row.scheduleTime,
			sortable: true,

		},
		{
			name: "Active",
			cell: (row) => (
				<Switch
					style={{ border: "1px solid gray" }}
					onChange={() => handleEnable(row?._id, row?.isActive, row)}
					defaultChecked={row?.isActive}
					checkedChildren={<BsCheck className="-ml-1 text-xl" />}
					unCheckedChildren={<BsX className="-mr-1 text-xl" />}
				/>
			),

		},
		{
			name: "Actions",
			center: true,
			cell: (row) => (
				<div className="flex ">
					<a onClick={() => history.push("/schedule-notification/edit/" + row._id)}>
						<div className="flex items-center underline">
							<BiEdit /> &nbsp;Edit
						</div>
					</a>
					<a className="mx-1" onClick={() => deleteRecord(row?._id)}>
						<div className="flex items-center underline">
							<BiTrash /> &nbsp;Delete
						</div>
					</a>
				</div>
			),
		},
	];

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			<div className="flex flex-col space-y-2">
				<div className="flex items-center justify-between pr-20">
					<div>
						<p className="pb-1 text-2xl text-white">Schedule Notification</p>
						<p className="text-muted">{totalNotifications} Scheduled Notifications</p>
					</div>
					<button onClick={() => history.push('schedule-notification/new/')} className="btn-border">Add Schdeule Notification</button>
				</div>
				<div className="flex w-80 items-center bg-[#111111] rounded-full">
					<RiSearch2Line className="w-5 h-5 ml-2 mr-1 text-gray-400" />
					<input
						type="text"
						className="rounded-full text-gray-400 w-96 focus:outline-none focus:border-none focus:ring-0 bg-[#111111]/30  border-none"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						placeholder="Search"
					/>
				</div>
			</div>
			{
				(
					<div className="flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted">
						{

							< DataTableBase
								progressPending={loading}
								columns={columns}
								data={filteredNotification}
								defaultSortFieldId={1}
								pagination
							/>
						}
					</div>
				)}
			<DeleteModal
				title="Contest"
				setVisible={setVisibleDelete}
				confirmDelete={confirmDelete}
				visible={visibleDelete}
			/>
		</div>
	);
}

export default ScheduleNotification;
