import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { editNotification, fetchNotifications } from '../../services/api'
import DataTableBase from "../../components/common/dataTable";


import Message from '../../components/common/Message';
import Loader from '../../components/common/Loader';

function Notification() {
	const history = useHistory()
	const [notifications, setNotifications] = useState([]);
	const [loading, setLoading] = useState(false)

	// const filteredNotifications = notifications.filter(notification => {
	// 	return (
	// 		notification?.type?.toLowerCase()
	// 	)
	// })

	useEffect(() => {
		const getNotifications = async () => {
			try {
				setLoading(true)
				const { data: { data, status } } = await fetchNotifications()
				status && setNotifications(data.messages)
				setLoading(false)
			} catch (error) {
				setLoading(false)
				console.log(error)
			}
		}
		getNotifications()
	}, [])

	const columns = [
		{
			name: "Type",
			selector: (row) => row.type,
			sortable: true,

		},
		{
			name: "Message",
			selector: (row) => row.msg,
			sortable: true,

		},
		{
			name: "Actions",
			center: true,
			cell: (row) => (
				<a onClick={() => history.push('/notification-message/edit/' + row._id)}>
					Edit
				</a>
			),
		},
	];
	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			<div className="flex flex-col space-y-2">
				<div className="flex items-center justify-between pr-20">
					<div>
						<p className="pb-1 text-2xl text-white">Notification Messages</p>
					</div>
				</div>
			</div>
			{
				<div className='flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted'>
					{

						< DataTableBase
							progressPending={loading}
							columns={columns}
							data={notifications}
							defaultSortFieldId={1}
							pagination
						/>
					}
				</div>
			}
		</div>
	)
}

export default Notification
