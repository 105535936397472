/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { fetchContestsActivity } from "../../../services/api";
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { Button } from "antd";
import csvDownload from 'json-to-csv-export'


function ContestActivity() {

	let yesterday = new Date();
	yesterday = yesterday.setMonth(yesterday.getMonth() - 1);
	const [contest, setContest] = useState([]);
	const [timeArray, setTimeArray] = useState([new Date(yesterday), new Date()]);
	const [dataToConvert, setDataToConvert] = useState({});

	useEffect(() => {
		const getContest = async () => {
			const {
				data: { data, status },
			} = await fetchContestsActivity(new Date(timeArray[0]), new Date(timeArray[1]));
			status && setContest(data);
			status && setDataToConvert({
				data: contest,
				filename: 'contest-monthly-report',
				delimiter: ',',
				headers: Object.keys(contest[0] || [])
			})
		};
		getContest();
	}, [timeArray]);

	return (
		<div className="vertical-scroll pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			<div className="flex flex-col space-y-2">
				<div className="flex items-center justify-between pr-20">
					<div>
						<p className="pb-1 text-2xl text-white">Contest Activity</p>
					</div>
				</div>
				<div className="flex-1 py-6 pr-2 space-y-4 divide-y divide-muted">
					<div>
						<p className="text-lg capitalize line-clamp-1 overflow-clip text-white pr-3">
							Select Time:
						</p>
						<div >
							<DateTimeRangePicker onChange={setTimeArray} value={timeArray} />
							<Button
								onClick={() => csvDownload(dataToConvert)}
								className="m-3 text-white">Download CSV
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div >
	);
}

export default ContestActivity;
