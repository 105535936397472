import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BsChevronDown } from 'react-icons/bs';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { InboxOutlined, DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { Form, Input, Select, Upload, InputNumber } from 'antd';
import { updateStack, fileUpload, fetchStacks, fetchStackById } from '../../services/api';

import Loader from '../../components/common/Loader';
import Message from '../../components/common/Message';

const { Item } = Form
const { Option } = Select
const { Dragger } = Upload

function EditStack() {
	let { id } = useParams()
	const history = useHistory()
	const [form] = Form.useForm()
	const [stacks, setStacks] = useState([])
	const [loading, setLoading] = useState(false)

	const onFinish = async (values) => {
		try {
			setLoading(true)
			const { status } = await updateStack(values, id)
			status ? Message('Stack updated successfully', true) : Message('Unable to updated Stack', false)
			setLoading(false)
			history.push('/stack')
		} catch (error) {
			Message('Unable to updated Stack', false)
			setLoading(false)
			console.log(error)
		}
	}

	useEffect(() => {
		const getStacks = async () => {
			try {
				const { data: { data, status } } = await fetchStacks()
				status && setStacks(data)
			} catch (error) {
				console.log(error)
			}
		}

		const getContestById = async () => {
			try {
				const { data: { data, status } } = await fetchStackById(id)
				if (status) {
					form.setFieldsValue({
						name: data?.name, numberOfCompitations: data?.numberOfCompitations, orderNumber: data?.orderNumber, duration: data?.duration
					})
				}
			} catch (error) {
				console.log(error)
			}
		}

		getStacks()
		getContestById()
	}, [])

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			{
				loading ?
					<div className="absolute top-0 right-0 z-50 flex items-center justify-center w-full h-full">
						<Loader />
					</div>
					:
					<>
						<div>
							<p className="pb-1 text-2xl text-white">Edit Stack</p>
						</div>
						<div className='flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted'>
							<Form
								form={form}
								onFinish={async (val) => await onFinish(val)}>
								<div className='flex items-start justify-between space-x-8'>
									<div className='w-full px-2 text-lg'>
										<Item name='name'>
											<Input placeholder='Name' className='bg-transparent border border-muted text-muted' />
										</Item>
										<Item name='orderNumber'>
											<InputNumber placeholder='Order Number' className='w-full bg-transparent border border-muted text-muted' />
										</Item>
										<Item name='numberOfCompitations'>
											<InputNumber placeholder='Number of Compitations' className='w-full bg-transparent border border-muted text-muted' />
										</Item>
										<Item name='duration'>
											<InputNumber placeholder='Duration' className='w-full bg-transparent border border-muted text-muted' />
										</Item>

										{/* <Item name='stack'>
                      <Select
                        
                        className='py-1 bg-transparent border border-muted text-muted'
                        placeholder="please select stack"
                        bordered={false}>
                        {
                          stacks?.map(item => <Option className="bg-transparent" key={item?._id} value={item?._id}>{item?.name}</Option>)
                        }
                      </Select>
                    </Item> */}
									</div>
									<div className='w-full'>
										{/* <div className='h-60'>
											{
												imageLoading ?
													<Loader /> :
													imageUrl ?
														<div className='space-y-4'>
															<img src={imageUrl} className='w-full pr-6 bg-cover h-60' alt="contest" />
															<p className='inline-flex items-baseline justify-between w-full pr-6 text-lg text-muted'>
																Remove Image
																<DeleteOutlined onClick={() => setImageUrl('')} style={{ color: '#E74646', fontSize: '16px' }} />
															</p>
														</div> :
														<Dragger {...props}>
															<p className="ant-upload-drag-icon"><InboxOutlined style={{ fontSize: '80px', color: '#666' }} /></p>
															<p className="px-2 text-lg text-muted">Click or drag file to this area to upload</p>
														</Dragger>
											}
										</div> */}
									</div>
								</div>
								<div className='flex items-center w-1/2 pl-2 pr-4 space-x-4'>
									<button htmltype='submit' className='w-full py-2 btn-border border-muted'>Update</button>
									<button onClick={() => history.push('/stack')} className='w-full py-2 btn-border text-danger border-danger'>Cancel</button>
								</div>
							</Form>
						</div>
					</>
			}
		</div>
	)
}

export default EditStack
