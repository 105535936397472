/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { RiSearch2Line } from "react-icons/ri";
import { fetchUsersByReferralCode } from "../../../services/api";
import DataTableBase from "../../../components/common/dataTable";
import { Avatar, Card, } from "antd";
import Meta from "antd/lib/card/Meta";

function UserReferralReport() {
	const history = useHistory();
	const [query, setQuery] = useState("");
	const [data, setData] = useState([]);
	const [referralUser, setReferralUser] = useState({})
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0)

	useEffect(() => {
		const getUsers = async () => {
			setLoading(true);
			const {
				data: { data, status },
			} = await fetchUsersByReferralCode(query);
			status && setData(data);
			status && setReferralUser(data[0].referralUser)
			status && setTotalRows(data.length)
			setLoading(false);
		};
		getUsers();
	}, [query]);

	const columns = [
		{
			name: "Username",
			selector: (row) => row?.user?.userName || "N/A",
			sortable: true,
			cell: (row) => (
				<a onClick={() => history.push("/user/" + row?._id)}>
					{`${row?.user?.userName}`}
				</a>
			),
		},
		{
			name: "Email",
			selector: (row) => row?.user?.email || "N/A",
			sortable: true,
		},
		{
			name: "Wins",
			selector: (row) => row?.user?.wins || "N/A",
			sortable: true,
		},
		{
			name: "Joined Date",
			selector: (row) => new Date(row?.user?.createdAt).toLocaleDateString() || "N/A",
			sortable: true,
		},
	];

	return (
		<div className="pt-8 flex flex-col h-[calc(100vh_-_96px)]">
			<div className="flex items-center justify-between">
				<div className="w-50 flex flex-col pr-20 space-y-2">
					<div>
						<p className="pb-1 text-2xl text-white">Referral Code</p>
						<p className="text-muted">{totalRows} Users </p>
					</div>
					<div className="flex w-80 items-center bg-[#111111] rounded-full">
						<RiSearch2Line className="w-5 h-5 ml-2 mr-1 text-gray-400" />
						<input
							type="text"
							className="rounded-full text-gray-400 w-96 focus:outline-none focus:border-none focus:ring-0 bg-[#111111]/30  border-none"
							value={query}
							onChange={(e) => { setQuery(e.target.value); setReferralUser({}) }}
							placeholder="Please Enter Referral Code"
						/>
					</div>
				</div>
				<div className="rounded-full text-gray-400 w-96 focus:outline-none focus:border-none focus:ring-0 bg-[#111111]/30  border-none">

				</div>
			</div>
			{
				<div className="flex-1 py-6 pr-2 space-y-4 overflow-y-auto text-white divide-y vertical-scroll divide-muted">
					{
						referralUser.userName !== undefined && query.length > 4 ?
							<div className="text-white">
								<Card style={{ width: 300, margin: 16, height: 150, backgroundColor: 'white', borderRadius: '20px', borderColor: 'rgba(1,1,1,.2)' }} loading={loading}>
									<Meta
										style={{ marginBottom: '10px ' }}
										avatar={<Avatar src={referralUser.image} />}
										title={referralUser.userName}
									/>
									Total User Referred: {totalRows}
									<br />
									Email: {referralUser.email}
								</Card>

								<DataTableBase
									columns={columns}
									data={data}
									progressPending={loading}
									pagination
								/>
							</div>
							:
							<div className="flex justify-center w-full h-full">
								{
									query.length > 5 && data.length > 0 ?
										< p className="pb-1 text-l text-white"></p>
										: < p className="pb-1 text-l text-white">No records Found!</p>
								}
							</div>
					}
				</div>
			}
		</div >
	);
}

export default UserReferralReport;
